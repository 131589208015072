import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { stringify } from 'subtitle';

//for latest media menu
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Helmet } from 'react-helmet';

import AppViewer from '../../../components/AppViewer/AppViewer';
import { setSelectedApp } from '../../../actions/appsActions';
import {
	clearBreadCrumbLocationArray,
	updateScreenName,
	updateBreadCrumbLocationArray,
} from '../../../actions/breadCrumbActions';
import { setIsMountedSubtitleUpdated } from '../../../actions/speechToTextActions';
import { showNotification } from '../../../actions/notificationActions';
import { showMessage } from '../../../actions/globalActions';
import {
	sides,
	showLeftPart,
	setLastRoute,
	showRightZone,
	checkForZones,
	setAccessDetails,
	handleMenuChange,
	getNewWindowUrl,
	defaultVideoWidth,
	defaultVideoHeight,
	handleStayOnScreen,
	handleSaveAndRoute,
	checkForRouteParam,
	handleCancelAndRoute,
	fetchAndSetMediaDetails,
	updateStateAsMobileViewOnResize,
	isRequestFail,
} from '../../../services/inspectScreenHelperService';
import {
	getMediaQueryDetails,
	deleteRestrictionRule,
	sendMediaScreenChanged,
	saveInspectScreenDetails,
	sendMediaScreenUnChanged,
	createNewRestrictionRule,
	shareThePostOnSocialMedia,
	applyRestrictionRuleOnMedia,
	deleteRestrictionRuleOnMedia,
	changeRouteAfterInspectScreen,
	updateExistingRestrictionRule,
	fetchPlayerEmbedConfiguration,
	setReplaceMediaContent,
} from '../../../actions/publishActions';
import { updateCurentComponentKey } from '../../../actions/navActions';
import {
	clearInspectScreenStorage,
	updateMenuKeys,
	addMediaDetails,
	setSidePlayerConfigId,
} from '../../../actions/inspectScreenActions';
import { generateId } from '../../../services/stringHelperService';
import { getDetails } from './../../../services/mediaDisplayService';
import { checkForAccessToChannels } from './../../../services/componentReusableService';
import { mediaNames, messageTypes, mediaTypes, qbrickPlayerList, NOCATALOG } from '../../../constants/mediaConstants';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../../constants/routesPath';
import { KEYS } from '../../../layout/nav/navigationItems';
import MediaDetails from './InspectSections/MediaDetails';
import LivePlayer from '../../../components/LivePlayer';
import LiveManagerScreen from '../../PublishScreen/LiveManagerScreen/LiveManagerScreen';
import InteractEditorScreen from '../../InteractEditorScreen/InteractEditorScreen';
import SpeechToTextScreen from '../../PublishScreen/SpeechToTextScreen/SpeechToTextScreen';
import SocialSharing from '../../../reusable/SocialSharing/SocialSharing';
import { menuKeys, detailsMenuKeys } from './../../../constants/inspectScreenMenu';
import BreadCrumb from './../../../layout/header/breadCrumb';
import MediaAddSection from './InspectSections/MediaAddSection/MediaAddSection';
import ScreenParentContainer from '../../../reusable/ScreenParentContainer/ScreenParentContainer';
import LiveInteractHelper from '../../../components/LiveInteractHelper/LiveInteractHelper';
import FeaturesService from '../../../services/FeaturesService';
import { loadPlayerConfiguration } from '../../../actions/playerConfigurationActions';

import menu_setting from '../../../assets/images/menu_settings.svg';
import { appPlacementTypes } from '../../../constants/appPlacementTypes';
import widget_creator from '../../../assets/images/widget_creator.svg';
import live_manager from '../../../assets/images/live_manager.svg';
import texttospeech from '../../../assets/images/texttospeech.svg';
import analytics from '../../../assets/images/analytics_grey.svg';
import { appsHelperService } from '../../../services/appsHelperService';
import { getAccountSettings } from '../../../actions/profileActions';

import EmbedVideo from './InspectSections/EmbedVideo/EmbedVideo';
import InspectPlayerPreviewArea from './InspectSections/InspectPlayerPreviewArea';
import AccessRestriction from './InspectSections/AccessRestriction/AccessRestriction';
import ThumbnailManager from './InspectSections/ThumbnailManager/ThumbnailManager';
import PlayerIframe from './InspectSections/PlayerIframe/PlayerIframe';
import Files from './InspectSections/Files/Files';
import InformationSection from './InspectSections/InformationSection/InformationSection';
import CopyAndPreview from './InspectSections/CopyAndPreview/CopyAndPreview';
import { InspectScreenExitConfirmationDialog } from './InspectExitConfirmationDialog';
import AnalyticsVideoPerformanceByMediaIdScreen from './../../AnalyticsScreen/AnalyticsVideoPerformanceByMediaIdScreen';
import GoBackHeader from '../../../layout/header/GoBackHeader';

import './InspectScreen.scss';
import { setShowUploadMediasDialog } from '../../../actions/uploadingActions';
import { defaultPaginationRoutePath } from '../../../constants/paginationConstant';
import { buildRoutePath } from '../../../utils/commonUtil';
import { localStorageKeys } from '../../../constants/localStorageKeys';

class InspectMediaScreen extends Component {
	constructor(props) {
		super(props);
		this.mediaDetailsSection = null;
		this.accessDetailsSection = null;
		this.defaultMenuKey = menuKeys.details;
		this.defaultDetailsMenuKey = detailsMenuKeys.mediaDetails;
		this.state = {
			mobileView: false,
			mediaDetails: null,
			routeAfterSave: '',
			selectedResourceId: '',
			isSaveInProgress: false,
			mediaName: mediaNames.medias,
			selectedMenu: this.defaultMenuKey,
			listDetails: this.getViewListOfDetailsMenu(),
			currentView: this.defaultDetailsMenuKey,
			expandadItem: this.defaultMenuKey,
			mobileViewZone: sides.left,
			liveMediaChannelId: '',
			lastRoute: '',
			videoWidth: defaultVideoWidth,
			videoHeight: defaultVideoHeight,
			accessToLive: false,
			accessToInteract: false,
			accessToSTT: false,
			playerConfigurationId: '',
			liveManagerLoaded: false,
			interactiveManagerLoaded: false,
			startPlayerMuted: false,
			updaterValue: 'default',
			showPlayerSettingOptions: true,
			embedVersion: '1',
			openReplaceContentDialog: false,
			isTrashedMedia: this.props.mediaType === mediaTypes.trashedMedias,
			defaultEmails: [],
			liveManagerModeratorMode: false,
		};
		this.inspectedItemId =
			this.props.match && this.props.match.params && this.props.match.params.mediaId
				? this.props.match.params.mediaId
				: this.props.mediaId;
		this.mediaDetailWidget = 'mediaDetail-' + generateId();
		this.mediaDetailContainer = 'mediaDetailContainer-' + generateId();
		this.liveManagerTopPanel = null;
		this.interactTopPanel = null;
		this.thumbnailManager = null;
		this.interactEditorRef = null;
		this.speechToTextRef = null;
		this.liveInteractHelperRef = null;
		// As the design change, we need Delete dialog modal ref to trigger delete func. Need to change when refactor media inspect page
		this.deleteMediaDialogRef = null;
		this.mediaPreviewDialogRef = null;
	}

	_getAppMenu = (type) => {
		const { apps } = this.props;
		return appsHelperService.getApps(apps, type);
	};

	UNSAFE_componentWillMount() {
		setLastRoute(this);
		checkForRouteParam(this);
		fetchAndSetMediaDetails(this.inspectedItemId, this);

		window.addEventListener('resize', () => updateStateAsMobileViewOnResize(this));
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.defaultAccountId !== this.props.defaultAccountId) {
			this.props.history.push(
				buildRoutePath(
					screenRoutes.PUBLISH_MEDIA_LIBRARY,
					ACCOUNT_ID_ROUTE_PLACEHOLDER,
					nextProps.defaultAccountId
				)
			);
		}
		if (nextProps.location && nextProps.location.pathname != this.props.location.pathname) {
			checkForRouteParam(this, nextProps.location.pathname);
			this.isLiveManagerLoaded();
			this.isInteractiveManagerLoaded();
		}
		if (nextProps.routeAfterSave && nextProps.routeAfterSave !== this.props.routeAfterSave) {
			this.setState({
				routeAfterSave: nextProps.routeAfterSave,
			});
			let isMediaDetailsSectionModified = false;
			if (
				this.mediaDetailsSection &&
				this.mediaDetailsSection.state &&
				this.mediaDetailsSection.state.mediaDetailsModified
			) {
				isMediaDetailsSectionModified = true;
			}
			if (
				isMediaDetailsSectionModified ||
				this.props.isInteractScreenModified ||
				this.props.isMountedSubtitleUpdated
			) {
				this.setState({
					inspectScrenExitConfirmationStatus: true,
				});
			}
		}
		if (nextProps.userPermissions && nextProps.userPermissions != this.props.userPermissions) {
			this.setUserAccessToLive(nextProps.userPermissions);
			this.setUserAccessToInteract(nextProps.userPermissions);
			this.setUserAccessToSTT(nextProps.userPermissions);
		}
		if (nextProps.playerConfigId !== this.props.playerConfigId) {
			this.setState({ playerConfigurationId: nextProps.playerConfigId });
		}

		if (nextProps.videoPlayerMuted !== this.props.videoPlayerMuted) {
			this.setState({ startPlayerMuted: nextProps.videoPlayerMuted });
		}
		if (nextProps.mediaType !== this.props.mediaType) {
			this.setState({ isTrashedMedia: this.props.mediaType === mediaTypes.trashedMedias });
		}
	}

	componentWillUnmount() {
		const { dialogMode } = this.props;
		if (!dialogMode) {
			this.props.clearBreadCrumbLocationArray();
		}
		this.props.sendMediaScreenUnChanged();
		this.props.clearInspectScreenStorage();
		window.removeEventListener('resize', () => updateStateAsMobileViewOnResize(this));
	}

	componentDidMount() {
		this.setUserAccessToLive(this.props.userPermissions);
		this.setUserAccessToInteract(this.props.userPermissions);
		this.setUserAccessToSTT(this.props.userPermissions);
		updateStateAsMobileViewOnResize(this);
		this.props.updateCurentComponentKey('INSPECT_MEDIA_SCREEN');
		this.isLiveManagerLoaded();
		this.isInteractiveManagerLoaded();
		this.loadEmbedSetting();
		this.detectLiveManagerModeratorMode();
	}

	detectLiveManagerModeratorMode = () => {
		if (window.location.href.match('/liveManager/moderator')) {
			this.setState({
				liveManagerModeratorMode: true,
			});
		}
	};

	loadEmbedSetting = () => {
		const { defaultAccountId, getAccountSettings } = this.props;
		getAccountSettings(defaultAccountId).then((settings) => {
			if (!(settings && Object.keys(settings).length > 0)) {
				return;
			}

			let embed = { version: '1' };
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				embed = settings['qbrickPlayer'].find((v) => !!v.enabled);
			}

			let defaultEmails = [];

			if (
				settings.hasOwnProperty('defaultEmails') &&
				settings['defaultEmails'] &&
				settings['defaultEmails'].length > 0
			) {
				defaultEmails = settings['defaultEmails'];
			}
			this.setState((prevState) => {
				const newEmbed =
					embed && prevState.embedVersion !== embed.version ? { embedVersion: embed.version } : null;

				return newEmbed
					? {
							defaultEmails,
							...newEmbed,
					  }
					: { defaultEmails };
			});
		});
	};

	isLiveManagerLoaded = () => {
		if (window.location.href.match('liveManager')) {
			this.setState({
				liveManagerLoaded: true,
			});
		}
	};

	isInteractiveManagerLoaded = () => {
		if (window.location.href.match('interactiveManager')) {
			this.setState({
				interactiveManagerLoaded: true,
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.selectedMenu !== prevState.selectedMenu && this.state.selectedMenu === menuKeys.details) {
			updateStateAsMobileViewOnResize(this);
		}

		if (
			this.props.location &&
			this.props.location.pathname &&
			this.props.location.pathname !== prevProps.location.pathname
		) {
			if (
				!(
					this.props.location.pathname.match('/detail/details') &&
					prevProps.location.pathname.match('/detail/details')
				)
			) {
				this.reloadBreadCrumb();
			}
		}
		if (prevState.mediaDetails !== this.state.mediaDetails) {
			let mediaDetails = this.state.mediaDetails;
			if (mediaDetails && mediaDetails.type === mediaTypes.video) {
				let newListDetails = JSON.parse(JSON.stringify(this.state.listDetails));

				this.setState({
					listDetails: newListDetails,
				});
			}
		}

		if (this.props.isInteractScreenModified !== prevProps.isInteractScreenModified) {
			let isMediaDetailsSectionModified = false;
			if (
				this.mediaDetailsSection &&
				this.mediaDetailsSection.state &&
				this.mediaDetailsSection.state.mediaDetailsModified
			) {
				isMediaDetailsSectionModified = true;
			}
			if (
				!this.props.isInteractScreenModified &&
				!this.props.isMountedSubtitleUpdated &&
				!isMediaDetailsSectionModified
			) {
				this.props.sendMediaScreenUnChanged();
			}
		}
		if (prevProps.videoPlayerMuted !== this.props.videoPlayerMuted) {
			this.setState({ updaterValue: generateId() });
		}

		if (prevProps.playerConfigId !== this.props.playerConfigId) {
			this.setState({ updaterValue: generateId() }, () => {
				updateStateAsMobileViewOnResize(this);
			});
		}
	}

	setUserAccessToLive = (userPermissions) => {
		if (userPermissions && checkForAccessToChannels(userPermissions)) {
			this.setState({
				accessToLive: true,
			});
		} else {
			this.setState({
				accessToLive: false,
			});
		}
	};

	setUserAccessToInteract = (userPermissions) => {
		if (FeaturesService.IsEnabled(userPermissions, 'InteractiveEditor')) {
			this.setState({
				accessToInteract: true,
			});
		} else {
			this.setState({
				accessToInteract: false,
			});
		}
	};

	setUserAccessToSTT = (userPermissions) => {
		if (FeaturesService.IsEnabled(userPermissions, 'Speech2Text')) {
			this.setState({
				accessToSTT: true,
			});
		} else {
			this.setState({
				accessToSTT: false,
			});
		}
	};

	getViewListOfDetailsMenu = (_) => {
		const { t, mediaType } = this.props;

		if (mediaType === mediaTypes.trashedMedias) {
			return [
				{
					key: 'details',
					order: 0,
					icon: menu_setting,
					param: {},
					name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_DETAILS'),
					children: [
						{
							id: detailsMenuKeys.mediaDetails,
							text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_DETAILS'),
						},
						{
							id: detailsMenuKeys.thumbnailManager,
							text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_THUMBNAIL_MANAGER'),
						},
						{
							id: detailsMenuKeys.accessRestrictions,
							text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_ACCESS_RESTRICTIONS'),
						},
						{
							id: detailsMenuKeys.files,
							text: t('COMMON_FILES'),
						},
					],
				},
			];
		}

		return [
			{
				key: 'details',
				order: 0,
				icon: menu_setting,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_DETAILS'),
				children: [
					{
						id: detailsMenuKeys.mediaDetails,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_DETAILS'),
					},
					{
						id: detailsMenuKeys.embedVideo,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_EMBED'),
					},
					{
						id: detailsMenuKeys.thumbnailManager,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_THUMBNAIL_MANAGER'),
					},
					{
						id: detailsMenuKeys.accessRestrictions,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_ACCESS_RESTRICTIONS'),
					},
					{
						id: detailsMenuKeys.files,
						text: t('COMMON_FILES'),
					},
				],
			},
			{
				key: 'interactiveManager',
				order: 0,
				icon: widget_creator,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_INTERACTIVE_MANAGER'),
				children: [],
			},
			{
				key: 'liveManager',
				order: 0,
				icon: live_manager,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_LIVE_MANAGER'),
				children: [],
			},
			{
				key: 'speechToText',
				order: 0,
				icon: texttospeech,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_S2T'),
				children: [],
			},
			{
				key: 'mediaanalytics',
				order: 0,
				icon: analytics,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_ANALYTICS'),
				children: [],
			},
		];
	};

	handleHidePreviewAndShareDialogBox = (_) => this.setState({ previewAndShareDialogBoxShowingStatus: false });

	updateScreenTitle = (title) => {
		const { dialogMode } = this.props;
		if (!dialogMode) {
			this.props.updateScreenName(title);
		}
	};

	updateBreadCrumb = (mediaDetails) => {
		const { dialogMode } = this.props;
		const { listDetails } = this.state;
		if (dialogMode) {
			return;
		}
		let appMenu = this._getAppMenu(appPlacementTypes.LIBRARY_MEDIAS_MEDIA_DETAIL, 0);
		let listDetailsConcat = listDetails.concat(appMenu);

		const appNameFormatting = (appName) => {
			listDetailsConcat.map((item) =>
				item.key == appName
					? (appName = item.name)
					: item.key == 'apps_' + appName
					? (appName = item.text)
					: appName
			);
			return appName;
		};

		this.props.clearBreadCrumbLocationArray();
		let mediaHref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/';
		let breadCrumbLocationArray = [];
		if (mediaDetails) {
			// this.props.updateScreenName(mediaDetails.title)
			breadCrumbLocationArray.push({
				href: mediaHref + mediaDetails.id + '/' + 'detail',
				text: mediaDetails.title,
			});
			if (
				this.props.match.params.menuKey &&
				(typeof this.props.match.params.subMenuKey === 'undefined' ||
					this.props.match.params.subMenuKey.length < 2)
			) {
				breadCrumbLocationArray.push({
					href: mediaHref + mediaDetails.id + '/' + 'detail' + '/' + this.props.match.params.menuKey,
					text: appNameFormatting(this.props.match.params.menuKey),
				});
			}
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails.parentId) {
				let customhref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/catalogs/';
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray);
			}
		}
	};

	reloadBreadCrumb = () => {
		const { mediaName, mediaDetails } = this.state;
		const { defaultAccountId, t, mediaType } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaName, mediaType).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
					messageTypes.error
				);
				return;
			}
			let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
			this.setState({ mediaDetails: customMediaInfo });
			this.updateBreadCrumb(customMediaInfo);
		});
	};

	getDetailsForBreadCrumb = (itemId, customhref, breadCrumbLocationArray) => {
		if (itemId === NOCATALOG) {
			return;
		}

		this.getCatalogDetails(itemId, (mediaDetails) => {
			if (typeof mediaDetails == 'string') {
				this.showMessage(mediaDetails + 'for breadcrumb', messageTypes.error);
				return;
			}
			breadCrumbLocationArray.unshift({
				href: `${customhref}${itemId}${defaultPaginationRoutePath}`,
				text: mediaDetails.title,
			});
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails && mediaDetails.parentId) {
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray);
			}
		});
	};

	getCatalogDetails = (itemId, callback) => {
		const { defaultAccountId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, itemId, mediaNames.catalogs).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(t('BREADCRUMB_FAILED_FETCH_CATALOG_DETAILS'), messageTypes.error);
				return;
			} else {
				callback(getDetails(media));
			}
		});
	};

	showMessage = (message, type) => {
		this.props.showMessage(message, type);
	};

	onMediaDetailsSectionLoaded = (mediaDetailsSection) => {
		const { mobileView } = this.state;
		this.mediaDetailsSection = mediaDetailsSection;
		checkForZones(this);
		if (mobileView) {
			this.setState({
				currentView: '',
			});
		}
	};

	onAccessComponentLoaded = (accessDetailsSection) => (this.accessDetailsSection = accessDetailsSection);

	setAccessDetails = (type, value) => setAccessDetails(type, value, this);

	showInspectScrenExitConfirmationStatus = (_) => {
		this.setState({ inspectScrenExitConfirmationStatus: true });
	};

	hideInspectScrenExitConfirmationStatus = (_) => this.setState({ inspectScrenExitConfirmationStatus: false });

	renderExitConfirmationDialog = (_) => {
		const { inspectScrenExitConfirmationStatus, routeAfterSave } = this.state;
		const { isInteractScreenModified, isMountedSubtitleUpdated } = this.props;
		const { t } = this.props;

		let isMediaDetailsSectionModified = false;
		if (
			this.mediaDetailsSection &&
			this.mediaDetailsSection.state &&
			this.mediaDetailsSection.state.mediaDetailsModified
		) {
			isMediaDetailsSectionModified = true;
		}

		if (
			inspectScrenExitConfirmationStatus &&
			(isMediaDetailsSectionModified || isInteractScreenModified || isMountedSubtitleUpdated) &&
			routeAfterSave !== this.props.location.pathname
		) {
			return (
				<InspectScreenExitConfirmationDialog
					t={t}
					handleSaveAndRoute={this.handleSaveAndRoute}
					inspectScrenExitConfirmationStatus={inspectScrenExitConfirmationStatus}
					handleCancelAndRoute={this.handleCancelAndRoute}
					handleStayOnScreen={this.handleStayOnScreen}
				/>
			);
		} else {
			return null;
		}
	};

	handleSave = (_) => {
		const { isInteractScreenModified, isMountedSubtitleUpdated } = this.props;
		// handleSave(this)
		if (this.mediaDetailsSection && this.mediaDetailsSection.state.mediaDetailsModified) {
			this.mediaDetailsSection.handleSaveForMediaDetails();
		}
		if (isInteractScreenModified) {
			if (
				this.interactEditorRef &&
				this.interactEditorRef.interactSaveRef &&
				this.interactEditorRef.interactSaveRef.handleSaveProject
			) {
				this.interactEditorRef.interactSaveRef.handleSaveProject();
			}
		}

		if (isMountedSubtitleUpdated) {
			if (
				this.speechToTextRef &&
				this.speechToTextRef.state &&
				this.speechToTextRef.state.focusedLanguage &&
				this.speechToTextRef.state.mountedSubtitleArray &&
				this.speechToTextRef.uploadSrt
			) {
				this.speechToTextRef.uploadSrt(
					this.speechToTextRef.state.focusedLanguage,
					stringify(this.speechToTextRef.state.mountedSubtitleArray)
				);
				this.props.setIsMountedSubtitleUpdated(false);
			}
		}
		this.hideBottomActionButtons();
	};

	handleCancel = (_) => {
		const { isInteractScreenModified, isMountedSubtitleUpdated } = this.props;
		//handleCancel(this)
		if (this.mediaDetailsSection) {
			this.mediaDetailsSection.handleCancelForMediaDetails();
		}

		if (isInteractScreenModified && this.interactEditorRef && this.interactEditorRef.revertChanges) {
			this.interactEditorRef.revertChanges();
		}

		if (isMountedSubtitleUpdated) {
			if (this.speechToTextRef) {
				this.props.setIsMountedSubtitleUpdated(false);
			}
		}

		this.hideBottomActionButtons();
	};

	handleSaveAndRoute = (_) => handleSaveAndRoute(this);

	handleCancelAndRoute = (_) => handleCancelAndRoute(this);

	handleStayOnScreen = (_) => handleStayOnScreen(this);

	onDetailsChange = (_) => {
		this.displayBottomActionButtons();
	};

	displayBottomActionButtons = (_) => {
		const { isInspectScreenModified } = this.props;
		if (!isInspectScreenModified) {
			this.props.sendMediaScreenChanged();
		}
	};

	hideBottomActionButtons = (_) => {
		const { isInspectScreenModified } = this.props;
		if (isInspectScreenModified) {
			this.props.sendMediaScreenUnChanged();
		}
	};

	onFilesComponentLoaded = (_) => this.onCheckForTabViews();

	onCheckForTabViews = (_) => {};

	onCheckForTabViewsAfterResize = (_) => {};

	renderGeneralDetails = (_) => {
		const { t, history, mediaType } = this.props;
		const { mediaDetails, mediaName, lastRoute, openReplaceContentDialog } = this.state;
		return (
			<>
				<MediaDetails
					t={t}
					mediaType={mediaType}
					mediaName={mediaName}
					mediaDetails={mediaDetails} // Update
					getDeleteMediaDialogRef={(ref) => {
						this.deleteMediaDialogRef = ref;
					}}
					handleSave={this.handleSave}
					showMessage={this.showMessage}
					handleCancel={this.handleCancel}
					onDetailsChange={this.onDetailsChange}
					updateScreenTitle={this.updateScreenTitle}
					onMediaDetailsSectionLoaded={this.onMediaDetailsSectionLoaded}
					history={history}
					lastRoute={lastRoute}
					openReplaceContentDialog={openReplaceContentDialog}
					handleMediaReplacermentUserChoose={this.handleMediaReplacermentUserChoose}
				/>
			</>
		);
	};

	renderEmbedVideo = (_) => {
		const { mediaDetails } = this.state;
		return <EmbedVideo id={this.inspectedItemId} mediaDetails={mediaDetails} placementType={'medias'} />;
	};

	onThumbnailManagerLoaded = (thumbnail) => {
		this.thumbnailManager = thumbnail;
	};

	renderThumbnailManager = (_) => {
		const { t, mediaType } = this.props;
		const { mediaDetails, mediaName, accessToLive } = this.state;
		return (
			<ThumbnailManager
				t={t}
				mediaType={mediaType}
				mediaName={mediaName}
				mediaDetails={mediaDetails}
				mediaId={this.inspectedItemId}
				accessToLive={accessToLive}
				onThumbnailManagerLoaded={this.onThumbnailManagerLoaded}
			/>
		);
	};

	renderSocialSharing = (_) => {
		const { t } = this.props;
		const { mediaDetails } = this.state;
		return (
			<>
				<SocialSharing t={t} state={this.state} mediaDetails={mediaDetails} mediaId={this.inspectedItemId} />
			</>
		);
	};

	renderAccessDetails = (_) => {
		const { t, mediaType } = this.props;
		const { mediaDetails } = this.state;
		return (
			<>
				<AccessRestriction
					t={t}
					mediaType={mediaType}
					parentState={this.state}
					mediaDetails={mediaDetails}
					showMessage={this.showMessage}
					onDetailsChange={this.onDetailsChange}
					setAccessDetails={this.setAccessDetails}
					onAccessComponentLoaded={this.onAccessComponentLoaded}
					mediaNames={mediaNames.medias}
				/>
			</>
		);
	};

	renderFilesDetails = (_) => {
		const { t, mediaType } = this.props;
		const { mediaDetails, mediaName } = this.state;
		return (
			<>
				<Files
					t={t}
					onFilesComponentLoaded={this.onFilesComponentLoaded}
					mediaDetails={mediaDetails}
					showMessage={this.showMessage}
					mediaName={mediaName}
					mediaType={mediaType}
				/>
			</>
		);
	};

	renderInformationSection = (_) => {
		const { t } = this.props;
		const { mediaDetails, liveMediaChannelId, videoWidth } = this.state;
		return (
			<>
				<InformationSection
					t={t}
					mediaDetails={mediaDetails}
					liveMediaChannelId={liveMediaChannelId}
					width={videoWidth}
				/>
			</>
		);
	};

	renderCopyAndPreview = (videoWidth) => {
		const { t } = this.props;
		const { mediaDetails } = this.state;

		return <CopyAndPreview t={t} mediaDetails={mediaDetails} videoWidth={videoWidth} />;
	};

	renderLivePlayer = (_) => {
		const { defaultAccountId, t, mediaId } = this.props;
		const {
			mediaDetails,
			videoWidth,
			videoHeight,
			selectedMenu,
			playerConfigurationId,
			startPlayerMuted,
			updaterValue,
		} = this.state;

		if (selectedMenu !== menuKeys.details) {
			return null;
		}

		return (
			<div id="inspectViewLivePlayer">
				{selectedMenu === menuKeys.details &&
					playerConfigurationId &&
					playerConfigurationId !== qbrickPlayerList.shoppingPlayer &&
					playerConfigurationId !== qbrickPlayerList.qbrickPlayer &&
					playerConfigurationId !== qbrickPlayerList.shoppingCompanion &&
					!playerConfigurationId.includes('-qp') &&
					!playerConfigurationId.includes('-sp') &&
					!playerConfigurationId.includes('-sc') && (
						<LivePlayer
							isLive={false}
							widgetPrefix={this.mediaDetailWidget}
							entityId={mediaDetails.id}
							entityType={mediaNames.medias}
							container={this.mediaDetailContainer}
							defaultAccountId={defaultAccountId}
							width={videoWidth}
							height={videoHeight}
							pause={selectedMenu != menuKeys.details}
							configurationId={playerConfigurationId}
							key={updaterValue}
							muted={startPlayerMuted}
							play={false}
							mediaDetails={mediaDetails}
						/>
					)}
				{(playerConfigurationId === qbrickPlayerList.shoppingPlayer ||
					playerConfigurationId === qbrickPlayerList.qbrickPlayer ||
					playerConfigurationId === qbrickPlayerList.shoppingCompanion ||
					playerConfigurationId.includes('-qp') ||
					playerConfigurationId.includes('-sp') ||
					playerConfigurationId.includes('-sc')) && (
					<PlayerIframe
						t={t}
						mediaDetails={mediaDetails}
						mediaId={mediaId}
						playerConfigurationId={playerConfigurationId}
					/>
				)}

				{this.renderCopyAndPreview(videoWidth)}
				{this.renderInformationSection()}
			</div>
		);
	};

	renderNewLivePlayer = (_) => {
		const {
			mediaDetails,
			videoWidth,
			videoHeight,
			selectedMenu,
			playerConfigurationId,
			updaterValue,
			liveMediaChannelId,
		} = this.state;

		return (
			<InspectPlayerPreviewArea
				mediaId={mediaDetails.id}
				playerConfigurationId={playerConfigurationId}
				selectedMenu={selectedMenu}
				updaterValue={updaterValue}
				videoHeight={videoHeight}
				videoWidth={videoWidth}
				mediaDetails={mediaDetails}
				liveMediaChannelId={liveMediaChannelId}
				placementType={'medias'}
				getMediaPreviewDialogRef={(ref) => {
					this.mediaPreviewDialogRef = ref;
				}}
			/>
		);
	};

	showMobileBackNav = (text) => {
		const { mobileView } = this.state;
		if (mobileView) {
			return (
				<div
					className="backToList"
					onClick={() => {
						showLeftPart();
						this.setState({
							mobileViewZone: sides.left,
						});
					}}
				>
					<label>{text} </label>
					{this.getLeftArrow()}
				</div>
			);
		} else {
			return null;
		}
	};

	getMediaAddSection = () => {
		const { mediaDetails, mobileView } = this.state;
		const { t, isAllowReplaceMediaContent } = this.props;
		return (
			<MediaAddSection
				t={t}
				mediaDetails={mediaDetails}
				mobileView={mobileView}
				showMessage={this.showMessage}
				reloadBreadCrumb={this.reloadBreadCrumb}
				isAllowReplaceMediaContent={isAllowReplaceMediaContent}
				handleReplaceContentButtonClick={this.handleReplaceContentButtonClick}
				deleteMediaDialogRef={this.deleteMediaDialogRef}
				mediaPreviewDialogRef={this.mediaPreviewDialogRef}
			/>
		);
	};

	renderAllDetails = (_) => {
		const { currentView, mobileView, mediaDetails, embedVersion, isTrashedMedia } = this.state;
		const { t } = this.props;
		return (
			<>
				<div id="middleZone">
					<div
						id={detailsMenuKeys.mediaDetails}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.mediaDetails ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_GENERAL_DETAILS'))}
						{!mobileView && !isTrashedMedia ? (
							<>
								{' '}
								<h3>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_GENERAL_DETAILS')}{' '}
									{!isTrashedMedia && this.getMediaAddSection()}{' '}
								</h3>
							</>
						) : null}
						{mobileView ? (
							<>
								<div className="mediaAddSection">
									<h4>
										{' '}
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_GENERAL_DETAILS')}{' '}
										{!isTrashedMedia && this.getMediaAddSection()}{' '}
									</h4>
								</div>
							</>
						) : null}

						{this.renderGeneralDetails()}
					</div>
					{!isTrashedMedia && embedVersion === '1' && (
						<div
							id={detailsMenuKeys.embedVideo}
							className="inspectViewMenuListItems"
							style={{ display: currentView === detailsMenuKeys.embedVideo ? 'block' : 'none' }}
						>
							{this.showMobileBackNav(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_EMBED'))}
							{!mobileView ? <h3>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_EMBED')}</h3> : null}
							{this.renderEmbedVideo()}
						</div>
					)}
					<div
						id={detailsMenuKeys.thumbnailManager}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.thumbnailManager ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_THUMBNAIL_MANAGER'))}
						{!mobileView ? (
							<h3>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_THUMBNAIL_MANAGER')}</h3>
						) : null}
						{this.renderThumbnailManager()}
					</div>
					{
						//Disabling social sharing, as it will not work in beta-video
						<div
							id={detailsMenuKeys.socialSharing}
							className="inspectViewMenuListItems"
							style={{
								display:
									currentView === detailsMenuKeys.socialSharing &&
									mediaDetails.type === mediaTypes.video
										? 'block'
										: 'none',
							}}
						>
							{this.showMobileBackNav(t('SHARE_TO_SOCIALS'))}
							{!mobileView ? <h3>{t('SHARE_TO_SOCIALS')}</h3> : null}
							{this.renderSocialSharing()}
						</div>
					}
					<div
						id={detailsMenuKeys.accessRestrictions}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.accessRestrictions ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_ACCESS_RESTRICTIONS'))}
						{!mobileView ? (
							<h3>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_ACCESS_RESTRICTIONS')}</h3>
						) : null}
						{this.renderAccessDetails()}
					</div>
					<div
						id={detailsMenuKeys.files}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.files ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('COMMON_FILES'))}
						{!mobileView ? <h3>{t('COMMON_FILES')}</h3> : null}
						{this.renderFilesDetails()}
					</div>
				</div>
			</>
		);
	};

	getLeftArrow = (_) => {
		return (
			<svg
				viewBox="0 0 21.86 15.51"
				onClick={() => {
					showLeftPart();
					this.setState({
						mobileViewZone: sides.left,
					});
				}}
			>
				<defs />
				<title>Arrow left</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M20.36,6.25H5L8.56,2.54A1.5,1.5,0,0,0,8.51.42a1.48,1.48,0,0,0-1-.42A1.54,1.54,0,0,0,6.39.46l-6,6.26a1.49,1.49,0,0,0,0,2.07l6,6.25A1.5,1.5,0,1,0,8.56,13L5,9.25H20.36a1.5,1.5,0,0,0,0-3Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	getRightArrow = (_) => {
		return (
			<svg viewBox="0 0 5.8 10.18">
				<defs />
				<title>Right</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M.5,10.18A.47.47,0,0,1,.15,10a.48.48,0,0,1,0-.7L4.38,5.09.15.85a.48.48,0,0,1,0-.7.48.48,0,0,1,.7,0l5,4.94L.85,10A.47.47,0,0,1,.5,10.18Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	onMainNavClick = (evt, item, selected) => {
		const { defaultAccountId } = this.props;
		if (!selected) {
			evt.preventDefault();
			handleMenuChange(this, item.key, item.param, defaultAccountId);
		}
	};

	onSubNavClick = (evt, item, param, selected) => {
		const { defaultAccountId } = this.props;
		if (!selected) {
			evt.preventDefault();
			showRightZone(item, this, undefined, undefined, defaultAccountId);
		}
	};

	isChildSelected = (currentView, childArray) => {
		let isSubMenu = false;
		for (let index = 0; index < childArray.length; ++index) {
			let subMenu = childArray[index];
			if (subMenu.id === currentView) {
				isSubMenu = true;
				break;
			}
		}
		return isSubMenu;
	};

	renderItemNameCase = (item) => {
		const { currentView, expandadItem, mediaDetails, accessToLive, accessToInteract, accessToSTT } = this.state;

		switch (item.key) {
			case 'liveManager':
				if (
					(mediaDetails.type === mediaTypes.livevideo || mediaDetails.type === mediaTypes.liveaudio) &&
					accessToLive &&
					this.props.liveChannel &&
					!this.props.liveChannel?.id?.includes('legacy')
				) {
					return (
						<div className="mediaMenuRow">
							<ListItem
								key={item.key}
								selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
								classes={{
									root: 'listItem',
									selected: 'selectedMainMenu',
								}}
								onClick={
									item.children.length > 0
										? (e) => {
												this.onSubNavClick(
													e,
													item.children[0],
													item.children[0].param,
													currentView === item.children[0].key
												);
										  }
										: (e) => this.onMainNavClick(e, item, currentView === item.key)
								}
							>
								{item.name ? (
									<ListItemText primary={item.name} />
								) : (
									<ListItemText primary={item.text} />
								)}
							</ListItem>
							<div
								className={
									currentView === item.key || this.isChildSelected(currentView, item.children)
										? 'mediaIconWrapper selectedMainMenu'
										: 'mediaIconWrapper'
								}
								onClick={
									item.children.length > 0
										? () => {
												this.onExpandSubMenu(item.key);
										  }
										: () => this.openInNewWindow(item)
								}
							>
								{item.children.length > 0 ? (
									expandadItem === item.key || expandadItem === menuKeys.details ? (
										<div className={'mainNavIcon'}>
											<ExpandMore />
										</div>
									) : (
										<ChevronRight />
									)
								) : (
									<OpenInNewIcon />
								)}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
					);
				} else {
					return null;
				}

			case 'interactiveManager':
				//if (mediaDetails.type !== mediaTypes.livevideo && mediaDetails.type !== mediaTypes.liveaudio && accessToInteract) {
				if (accessToInteract) {
					return (
						<div className="mediaMenuRow">
							<ListItem
								key={item.key}
								selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
								classes={{
									root: 'listItem',
									selected: 'selectedMainMenu',
								}}
								onClick={
									item.children.length > 0
										? (e) => {
												this.onSubNavClick(
													e,
													item.children[0],
													item.children[0].param,
													currentView === item.children[0].key
												);
										  }
										: (e) => this.onMainNavClick(e, item, currentView === item.key)
								}
							>
								{item.name ? (
									<ListItemText primary={item.name} />
								) : (
									<ListItemText primary={item.text} />
								)}
							</ListItem>
							<div
								className={
									currentView === item.key || this.isChildSelected(currentView, item.children)
										? 'mediaIconWrapper selectedMainMenu'
										: 'mediaIconWrapper'
								}
								onClick={
									item.children.length > 0
										? () => {
												this.onExpandSubMenu(item.key);
										  }
										: () => this.openInNewWindow(item)
								}
							>
								{item.children.length > 0 ? (
									expandadItem === item.key || expandadItem === menuKeys.details ? (
										<div className={'mainNavIcon'}>
											<ExpandMore />
										</div>
									) : (
										<ChevronRight />
									)
								) : (
									<OpenInNewIcon />
								)}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
					);
				} else {
					return null;
				}
			case 'speechToText':
				if (
					mediaDetails.type !== mediaTypes.livevideo &&
					mediaDetails.type !== mediaTypes.liveaudio &&
					accessToSTT
				) {
					return (
						<div className="mediaMenuRow">
							<ListItem
								key={item.key}
								selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
								classes={{
									root: 'listItem',
									selected: 'selectedMainMenu',
								}}
								onClick={
									item.children.length > 0
										? (e) => {
												this.onSubNavClick(
													e,
													item.children[0],
													item.children[0].param,
													currentView === item.children[0].key
												);
										  }
										: (e) => this.onMainNavClick(e, item, currentView === item.key)
								}
							>
								{item.name ? (
									<ListItemText primary={item.name} />
								) : (
									<ListItemText primary={item.text} />
								)}
							</ListItem>
							<div
								className={
									currentView === item.key || this.isChildSelected(currentView, item.children)
										? 'mediaIconWrapper selectedMainMenu'
										: 'mediaIconWrapper'
								}
								onClick={
									item.children.length > 0
										? () => {
												this.onExpandSubMenu(item.key);
										  }
										: () => this.openInNewWindow(item)
								}
							>
								{item.children.length > 0 ? (
									expandadItem === item.key || expandadItem === menuKeys.details ? (
										<div className={'mainNavIcon'}>
											<ExpandMore />
										</div>
									) : (
										<ChevronRight />
									)
								) : (
									<OpenInNewIcon />
								)}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
					);
				} else {
					return null;
				}
			default:
				return (
					<div className="mediaMenuRow">
						<ListItem
							key={item.key}
							selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
							classes={{
								root: 'listItem',
								selected: 'selectedMainMenu',
							}}
							onClick={
								item.children.length > 0
									? (e) => {
											this.onSubNavClick(
												e,
												item.children[0],
												item.children[0].param,
												currentView === item.children[0].key
											);
									  }
									: (e) => this.onMainNavClick(e, item, currentView === item.key)
							}
						>
							{item.name ? <ListItemText primary={item.name} /> : <ListItemText primary={item.text} />}
						</ListItem>
						<div
							className={
								currentView === item.key || this.isChildSelected(currentView, item.children)
									? 'mediaIconWrapper selectedMainMenu'
									: 'mediaIconWrapper'
							}
							onClick={
								item.children.length > 0
									? () => {
											this.onExpandSubMenu(item.key);
									  }
									: () => this.openInNewWindow(item)
							}
						>
							{item.children.length > 0 ? (
								expandadItem === item.key || expandadItem === menuKeys.details ? (
									<div className={'mainNavIcon'}>
										<ExpandMore />
									</div>
								) : (
									<ChevronRight />
								)
							) : (
								<OpenInNewIcon />
							)}
						</div>
						<div style={{ clear: 'both' }}></div>
					</div>
				);
		}
	};

	onExpandSubMenu = (expand_id) => {
		if (this.state.expandadItem === expand_id) {
			this.setState({
				expandadItem: 0,
			});
		} else {
			this.setState({
				expandadItem: expand_id,
			});
		}
	};

	openInNewWindow = (item) => {
		let deviceWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
		//let windowWidth = deviceWidth - 400;
		let windowWidth = 1300;
		let windowHeight = 600;
		let leftMargin = (deviceWidth - windowWidth) / 2;
		let params =
			'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=' +
			windowWidth +
			',height=' +
			windowHeight +
			',left=' +
			leftMargin +
			',top=100,rel=noreferrer';
		let newPath = getNewWindowUrl(this, item.key, item.param);
		let protocol = window.location.protocol;
		let domain = window.location.hostname;
		let port = window.location.port;
		let baseUrl = protocol + '//' + domain + (port ? ':' + port : '') + '/#';
		let completeUrl = baseUrl + newPath + '?selectedMenu=' + item.key;

		window.open(completeUrl, '_blank', params);
	};

	renderMediaDetails = (_) => {
		const { embedVersion, isTrashedMedia } = this.state;
		return (
			<>
				{!isTrashedMedia && (
					<div id="rightZone">
						{embedVersion === '1' && this.renderLivePlayer()}
						{embedVersion === '2' && this.renderNewLivePlayer()}
					</div>
				)}
				<div id="leftZone">{this.renderMediaMenu()}</div>
				{this.renderAllDetails()}
			</>
		);
	};

	renderMediaMenu() {
		const { listDetails, currentView, expandadItem, mediaName } = this.state;
		const { t } = this.props;

		let otherMenu = [
			{
				key: 'details',
				order: 0,
				icon: menu_setting,
				param: {},
				name: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_DETAILS'),
				children: [
					{
						id: detailsMenuKeys.mediaDetails,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_GENERAL_DETAILS'),
					},
					{
						id: detailsMenuKeys.embedVideo,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_EMBED'),
					},
					{
						id: detailsMenuKeys.thumbnailManager,
						text: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_THUMBNAIL_MANAGER'),
					},
					/* {
                        id: detailsMenuKeys.accessRestrictions,
                        text: t('ACCESS_RESTRICTIONS')
                    }, */
					{
						id: detailsMenuKeys.files,
						text: t('COMMON_FILES'),
					},
				],
			},
		];
		let appMenu = this._getAppMenu(appPlacementTypes.LIBRARY_MEDIAS_MEDIA_DETAIL, 0);
		let listDetailsConcat = this.state.isTrashedMedia ? listDetails : listDetails.concat(appMenu);
		let mediaMenuSorted = listDetailsConcat.sort((a, b) => {
			return a.order - b.order;
		});

		return (
			<div className="mediaMenuWrapper">
				{mediaName === mediaNames.medias &&
					mediaMenuSorted.map((item) => (
						<>
							{this.renderItemNameCase(item)}
							{this.listNestedItems(item)}
						</>
					))}
				{(mediaName === mediaNames.catalogs || mediaName === mediaNames.playlists) &&
					otherMenu.map((item, i) => (
						<div key={i} className="mediaMenuRow">
							<ListItem
								key={item.key}
								selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
								classes={{
									root: 'listItem',
									selected: 'selectedMainMenu',
								}}
								onClick={
									item.children.length > 0
										? (e) => {
												this.onSubNavClick(
													e,
													item.children[0],
													item.children[0].param,
													currentView === item.children[0].key
												);
										  }
										: (e) => this.onMainNavClick(e, item, currentView === item.key)
								}
							>
								<ListItemText primary={item.name} />
							</ListItem>
							<div
								className={
									currentView === item.key || this.isChildSelected(currentView, item.children)
										? 'mediaIconWrapper selectedMainMenu'
										: 'mediaIconWrapper'
								}
								onClick={
									item.children.length > 0
										? () => {
												this.onExpandSubMenu(item.key);
										  }
										: () => this.openInNewWindow(item)
								}
							>
								{item.children.length > 0 ? (
									expandadItem === item.key || expandadItem === menuKeys.details ? (
										<div className={'mainNavIcon'}>
											<ExpandMore />
										</div>
									) : (
										<ChevronRight />
									)
								) : (
									<OpenInNewIcon />
								)}
							</div>
							<div style={{ clear: 'both' }}></div>
						</div>
					))}
			</div>
		);
	}

	listNestedItems(listItem) {
		const { open } = this.props;
		const { currentView, expandadItem, embedVersion } = this.state;
		if ((expandadItem === listItem.key || expandadItem === menuKeys.details) && listItem.children.length > 0) {
			return (
				<div in={open} timeout="auto" className="mediaSubMenuWrapper" id={'sub' + listItem.id}>
					{listItem.children.map((item, index) => {
						if (embedVersion === '2' && item.id === detailsMenuKeys.embedVideo) {
							return null;
						}
						return (
							<List component="div" className={'mediaSubMenuElement'} disablePadding key={item.id}>
								<ListItem
									button
									key={item.id + index}
									selected={currentView === item.id}
									classes={{
										root: 'subMenuDiv',
										selected: 'selectedSubMenu',
									}}
									onClick={(e) => {
										this.onSubNavClick(e, item, item.param, currentView === item.id);
									}}
								>
									<ListItemText primary={item.text} />
								</ListItem>
							</List>
						);
					})}
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	redirectToLocation = (path) => this.props.history.push(`/${path}`);

	onliveManagerTopPanelLoaded = (liveManagerTopPanel) => {
		this.liveManagerTopPanel = liveManagerTopPanel;
	};

	onInteractTopPanelLoaded = (interactTopPanel) => {
		this.interactTopPanel = interactTopPanel;
	};

	isMenuKeyIsDeclared = (key) => {
		let menuKeysArray = Object.keys(menuKeys);
		if (menuKeysArray.length > 0) {
			if (menuKeysArray.indexOf(key) !== -1) {
				return true;
			}
		}
		return false;
	};

	checkIfSTTisOn = (selectedMenu) => {
		if (selectedMenu === menuKeys.speechToText) {
			return true;
		} else {
			return false;
		}
	};

	onGoBackClick = () => {
		const { history, isInspectScreenModified, mediaId, changeRouteAfterInspectScreen, defaultAccountId } =
			this.props;
		if (isInspectScreenModified) {
			changeRouteAfterInspectScreen(`${screenRoutes.PUBLISH_MEDIA_LIBRARY}/medias/${mediaId}/detail`);
		} else {
			if (history.length > 2) {
				history.goBack();
			} else {
				const newRoute = buildRoutePath(
					`${screenRoutes.PUBLISH_MEDIA_LIBRARY}/medias/${mediaId}/detail`,
					ACCOUNT_ID_ROUTE_PLACEHOLDER,
					defaultAccountId
				);
				history.push(newRoute);
			}
		}
	};

	shouldShowGoBackHeader = () => {
		const currentLocation = window.location.hash === '' ? window.location.pathname : window.location.hash;
		return (
			currentLocation.match('liveManager') ||
			currentLocation.match('interactiveManager') ||
			currentLocation.match('speechToText')
		);
	};

	handleReplaceContentButtonClick = () => {
		const { openReplaceContentDialog } = this.state;
		this.setState({ openReplaceContentDialog: !openReplaceContentDialog });
	};

	handleMediaReplacermentUserChoose = ({ ok: confirmation = false }) => {
		if (this.props.mediaType === mediaTypes.trashedMedias) {
			return;
		}

		this.handleReplaceContentButtonClick();

		if (!confirmation) {
			return;
		}

		const { setShowUploadMediasDialog, setReplaceMediaContent } = this.props;
		const { mediaDetails } = this.state;

		setReplaceMediaContent({ mediaId: mediaDetails.id, assetId: mediaDetails.asset.id });
		setShowUploadMediasDialog(true);
	};

	render() {
		const { history, dialogMode, match, t } = this.props;
		const showGoBackHeader = this.shouldShowGoBackHeader();

		const {
			mediaName,
			selectedMenu,
			mediaDetails,
			liveMediaChannelId,
			accessToLive,
			liveManagerLoaded,
			interactiveManagerLoaded,
			isTrashedMedia,
			liveManagerModeratorMode,
		} = this.state;

		return (
			<>
				<Helmet>
					<title>
						{mediaDetails && mediaDetails.title
							? `${t('COMMON_INSPECT')} - ${mediaDetails.title}`
							: t('COMMON_QBRICK_PAGE_TITLE')}
					</title>
				</Helmet>
				{showGoBackHeader && !liveManagerModeratorMode && <GoBackHeader onGoBackClick={this.onGoBackClick} />}
				{!showGoBackHeader && !liveManagerModeratorMode && <BreadCrumb hasBorder history={history} />}

				<ScreenParentContainer>
					<div id="inspectView" className={isTrashedMedia ? 'mediaFullDetails--trashed' : ''}>
						{mediaDetails && (
							<>
								{selectedMenu !== menuKeys.liveManager &&
									selectedMenu !== menuKeys.interactiveManager && (
										<div id="breadCrumbMobileView">
											<BreadCrumb history={history} isMobile={true} />
										</div>
									)}
								<div className={selectedMenu === menuKeys.details ? 'mediaFullDetails' : 'hide'}>
									{this.renderMediaDetails()}
								</div>
								{
									<LiveInteractHelper
										mediaId={this.inspectedItemId}
										t={t}
										showMessage={this.showMessage}
										mediaDetails={mediaDetails}
										onRef={(ref) => (this.liveInteractHelperRef = ref)}
										isOnLiveMode={mediaDetails.type === mediaTypes.livevideo}
									/>
								}

								{!dialogMode &&
									(mediaDetails.type !== mediaTypes.catalog ||
										mediaDetails.type !== mediaTypes.playlists) && (
										<div
											className={
												selectedMenu === menuKeys.interactiveManager
													? 'show interact-editor'
													: 'hide'
											}
										>
											<InteractEditorScreen
												t={t}
												mediaDetails={mediaDetails}
												mediaId={this.inspectedItemId}
												onInteractTopPanelLoaded={this.onInteractTopPanelLoaded}
												showMessage={this.showMessage}
												onRef={(ref) => (this.interactEditorRef = ref)}
												isOnLiveMode={mediaDetails.type === mediaTypes.livevideo ? true : false}
												interactiveManagerLoaded={interactiveManagerLoaded}
												channelId={liveMediaChannelId}
												history={this.props.history}
												isVisible={selectedMenu === menuKeys.interactiveManager}
												liveInteractHelperRef={this.liveInteractHelperRef}
												showPlayer={selectedMenu === menuKeys.interactiveManager}
											/>
										</div>
									)}
								{!dialogMode && (
									<>
										{(mediaDetails.type === mediaTypes.livevideo ||
											mediaDetails.type === mediaTypes.liveaudio) &&
											accessToLive &&
											liveMediaChannelId && (
												<div
													className={
														selectedMenu === menuKeys.liveManager
															? 'show live-manager'
															: 'hide'
													}
												>
													<LiveManagerScreen
														mediaId={this.inspectedItemId}
														mediaDetails={mediaDetails}
														channelId={liveMediaChannelId}
														onliveManagerTopPanelLoaded={this.onliveManagerTopPanelLoaded}
														isVisible={selectedMenu === menuKeys.liveManager}
														showMessage={this.showMessage}
														t={t}
														liveManagerLoaded={liveManagerLoaded}
														showPlayer={selectedMenu === menuKeys.liveManager}
														reloadData={() =>
															fetchAndSetMediaDetails(this.inspectedItemId, this)
														}
														defaultEmails={this.state.defaultEmails}
														moderatorMode={liveManagerModeratorMode}
													/>
												</div>
											)}

										<div
											className={
												selectedMenu === menuKeys.mediaanalytics
													? 'show fullWidthBlock'
													: 'hide'
											}
										>
											<AnalyticsVideoPerformanceByMediaIdScreen mediaView={true} />
										</div>
										<div
											className={
												selectedMenu === menuKeys.speechToText ? 'show fullWidthBlock' : 'hide'
											}
										>
											<SpeechToTextScreen
												t={t}
												mediaId={this.inspectedItemId}
												mediaDetails={mediaDetails}
												mediaNames={mediaNames}
												showMessage={this.showMessage}
												mediaName={mediaName}
												menuKeys={menuKeys}
												speechToTextIsOn={this.checkIfSTTisOn(selectedMenu)}
												onRef={(ref) => (this.speechToTextRef = ref)}
												showPlayer={selectedMenu === menuKeys.speechToText}
											/>
										</div>

										<div
											className={
												!this.isMenuKeyIsDeclared(selectedMenu)
													? 'show apps fullWidthBlock'
													: 'hide'
											}
										>
											<AppViewer
												appId={match.params.menuKey}
												mediaId={this.inspectedItemId}
											></AppViewer>
										</div>
										<div className={selectedMenu === menuKeys.close ? 'show-back' : 'hide'}></div>
									</>
								)}
								{this.renderExitConfirmationDialog()}
							</>
						)}
					</div>
				</ScreenParentContainer>
			</>
		);
	}
}

InspectMediaScreen.propTypes = {
	mediaId: PropTypes.string,
	dialogMode: PropTypes.bool,
};

const mapStateToProps = ({
	apps,
	session,
	publish,
	breadCrumbReducer,
	navReducer,
	inspectScreenReducer,
	interactTimelineReducer,
	interactElementsReducer,
	speechToText,
	liveManager,
}) => ({
	apps: apps.apps,
	open: navReducer.open,
	loading: publish.loading,
	interactLoading: interactElementsReducer.loading,
	username: session.username,
	accounts: session.accounts,
	isLoggedIn: session.isLoggedIn,
	routeAfterSave: publish.routeAfterSave,
	defaultAccountId: localStorage.getItem(localStorageKeys.DEFAULT_ACCOUNT_ID),
	locationArray: breadCrumbReducer.locationArray,
	isInspectScreenModified: publish.isInspectScreenModified,
	userPermissions: session.userPermissions,
	playerConfigId: inspectScreenReducer.playerConfigId,
	videoPlayerMuted: inspectScreenReducer.videoPlayerMuted,
	isInteractScreenModified: interactTimelineReducer.isInteractScreenModified,
	isMountedSubtitleUpdated: speechToText.isMountedSubtitleUpdated,
	currentComponentKey: navReducer.currentComponentKey,
	liveChannel: liveManager.liveChannel,
	isAllowReplaceMediaContent: publish.qbrickStandardFlows.length > 0,
});

const mapDispatchToProps = (dispatch) => ({
	updateScreenName: (name) => dispatch(updateScreenName(name)),
	sendMediaScreenChanged: (_) => dispatch(sendMediaScreenChanged()),
	showNotification: (message) => dispatch(showNotification(message)),
	sendMediaScreenUnChanged: (_) => dispatch(sendMediaScreenUnChanged()),
	clearBreadCrumbLocationArray: (_) => dispatch(clearBreadCrumbLocationArray()),
	changeRouteAfterInspectScreen: (route) => dispatch(changeRouteAfterInspectScreen(route)),
	updateBreadCrumbLocationArray: (key, location) => dispatch(updateBreadCrumbLocationArray(key, location)),
	deleteRestrictionRule: (accountId, restrictionId) => dispatch(deleteRestrictionRule(accountId, restrictionId)),
	getMediaQueryDetails: (accId, mediaId, mediaName, mediaType) =>
		dispatch(getMediaQueryDetails(accId, mediaId, mediaName, mediaType)),
	shareThePostOnSocialMedia: (accId, mediaId, post) => dispatch(shareThePostOnSocialMedia(accId, mediaId, post)),
	createNewRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(createNewRestrictionRule(accountId, restrictionId, body)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	updateExistingRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(updateExistingRestrictionRule(accountId, restrictionId, body)),
	applyRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(applyRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	deleteRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(deleteRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	setSelectedApp: (app) => dispatch(setSelectedApp(app)),
	clearInspectScreenStorage: () => dispatch(clearInspectScreenStorage()),
	updateMenuKeys: (key) => dispatch(updateMenuKeys(key)),
	addMediaDetails: (details) => dispatch(addMediaDetails(details)),
	setIsMountedSubtitleUpdated: (mountedSubUpdate) => dispatch(setIsMountedSubtitleUpdated(mountedSubUpdate)),
	updateCurentComponentKey: (key) => dispatch(updateCurentComponentKey(key)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	fetchPlayerEmbedConfiguration: (accId) => dispatch(fetchPlayerEmbedConfiguration(accId)),
	setSidePlayerConfigId: (value) => dispatch(setSidePlayerConfigId(value)),
	loadPlayerConfiguration: (accountId) => dispatch(loadPlayerConfiguration(accountId)),
	getAccountSettings: (accountId) => dispatch(getAccountSettings(accountId)),
	setShowUploadMediasDialog: (showUploadMedia) => dispatch(setShowUploadMediasDialog(showUploadMedia)),
	setReplaceMediaContent: (media) => dispatch(setReplaceMediaContent(media)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InspectMediaScreen);
