import React, { useCallback, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import copy_text from '../../../../assets/images/copy_text.svg';
import { mediaTypes, renditionTypes } from '../../../../constants/mediaConstants';
import { showNotification } from '../../../../actions/notificationActions';
import { getFormattedDate } from '../../../../services/timeStampService';
import { joinClassNames } from '../../../../services/elementHelperService';

import './MediaInformationSection.scss';

interface MediaInformationSectionProps {
	mediaDetails?: any;
	liveMediaChannelId?: string;
	channels?: any[];
	useFlatInformationArea?: boolean;
}

const MediaInformationSection = ({
	mediaDetails,
	liveMediaChannelId,
	channels,
	useFlatInformationArea,
}: MediaInformationSectionProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const getSortedData = useCallback((list: any[], property: string) => {
		let sortedArray = list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
		return sortedArray;
	}, []);

	const { codec, format, resolution } = useMemo(() => {
		let videos: any[] = [];
		mediaDetails &&
			mediaDetails.asset &&
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.map((resource: any) => {
				if (resource && resource.renditions) {
					resource.renditions.map((rendition: any) => {
						const link = rendition.links ? rendition.links[0].href : '';
						const arr = link ? link.split('/') : rendition.path ? rendition.path.split('/') : [];
						const lastName = arr.length > 0 ? arr[arr.length - 1] : '';
						const defaultName = '...' + (link ? link.slice(-17) : '');
						const name = lastName || defaultName;
						const genericObj = {
							resourceId: resource.id,
							renditionId: rendition.id,
							name: name,
							link: link,
						};
						switch (rendition.type) {
							case renditionTypes.VIDEO: {
								let tempVideoObj = {};
								if (rendition.videos && rendition.videos.length > 0) {
									tempVideoObj = { ...rendition.videos[0] };
								}
								const videoObj = {
									...genericObj,
									...rendition,
									...tempVideoObj,
								};
								videos.push(videoObj);
								break;
							}

							default:
								break;
						}
					});
				}
			});
		videos = getSortedData(videos, 'bitrate');
		const video = videos && videos.length > 0 ? videos[0] : undefined;
		const codec = video?.codec;
		const format = video?.mimeType;
		const resolution = video ? `${video.width} x ${video.height}` : undefined;

		return {
			codec,
			format,
			resolution,
		};
	}, [getSortedData, mediaDetails]);

	const channelName = useMemo(() => {
		if (!liveMediaChannelId || liveMediaChannelId.includes('legacy')) {
			return '';
		}
		let channel = channels != undefined ? channels.find((item) => item.id === liveMediaChannelId) : '';

		return channel?.metadata ? channel.metadata.title : '';
	}, [channels, liveMediaChannelId]);

	return mediaDetails ? (
		<div
			className={joinClassNames(
				'media-information-container',
				useFlatInformationArea ? 'media-information-container--flat-container' : ''
			)}
		>
			<div className="media-information-container__section">
				<div style={{ display: 'inline-flex', alignItems: 'center', marginBottom: '5px' }}>
					<label className="media-information-container__section-title" style={{ margin: '0' }}>
						{t(mediaDetails.type === mediaTypes.catalog ? 'COMMON_CATALOG_ID' : 'COMMON_MEDIA_ID')}
					</label>
					{(resolution || codec || format) && (
						<CopyToClipboard
							text={mediaDetails.id}
							onCopy={() =>
								dispatch(showNotification(`${t('COMMON_MEDIA_ID')} ${t('COMMON_IS_COPIED')}!`))
							}
						>
							<button title={t('COMMON_CLICK_TO_COPY')} className="media-information-container__copy-btn">
								<img src={copy_text} />
							</button>
						</CopyToClipboard>
					)}
				</div>
				<label>{mediaDetails.id} </label>
			</div>

			{liveMediaChannelId && (
				<div className="media-information-container__section">
					<label className="media-information-container__section-title">
						{!liveMediaChannelId.includes('legacy') ? t('COMMON_CHANNEL') : ''}
					</label>
					<label>{channelName}</label>
				</div>
			)}

			{resolution && (
				<div className="media-information-container__section">
					<label className="media-information-container__section-title">
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_VIDEO_DIMENSION')}
					</label>
					<label>{resolution}</label>
				</div>
			)}

			{codec && (
				<div className="media-information-container__section">
					<label className="media-information-container__section-title">{t('COMMON_CODEC')}</label>
					<label>{codec.toUpperCase()}</label>
				</div>
			)}

			<div className="media-information-container__section">
				<label className="media-information-container__section-title">{t('COMMON_DATE_CREATED')}</label>
				<label>{getFormattedDate(mediaDetails.created)}</label>
			</div>

			<div className="media-information-container__section">
				<label className="media-information-container__section-title">{t('COMMON_DATE_UPDATED')}</label>
				<label>{getFormattedDate(mediaDetails.updated)}</label>
			</div>

			{format && (
				<div className="media-information-container__section">
					<label className="media-information-container__section-title">
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_AUDIO_FORMAT')}
					</label>
					<label>{format}</label>
				</div>
			)}
		</div>
	) : null;
};

export default MediaInformationSection;
