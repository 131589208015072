import { defaultPaginationRoutePath } from '../../../constants/paginationConstant';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../../constants/routesPath';
import { buildRoutePath } from '../../../utils/commonUtil';

export type HandlerType = () => void;
export type ViewType = 'grid' | 'list' | 'calendar';

export const resetMediaLibraryPagination = ({ history, defaultAccountId }: any) => {
	const redirectRoute = buildRoutePath(
		`${screenRoutes.PUBLISH_ALL_MEDIA}${defaultPaginationRoutePath}`,
		ACCOUNT_ID_ROUTE_PLACEHOLDER,
		defaultAccountId
	);
	history.push(redirectRoute);
};
