import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, IconButton } from '@material-ui/core';
import { Close, OpenInNewOutlined } from '@material-ui/icons';
import { useLocation } from 'react-router';

import MediaContent from './inspectMediaMiniContent/MediaContent';
import useFetchMediaDetails from '../InspectScreen/useFetchMedia';
import { mediaNames, mediaTypes } from '../../../constants/mediaConstants';
import { setInspectingMediaData } from '../../../actions/inspectScreenActions';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../../constants/routesPath';
import { MediaDetailUtils } from './utils';
import { buildRoutePath } from '../../../utils/commonUtil';

import './InspectMedia.scss';
import PortalContent from './inspectMediaMiniContent/PortalContent';

interface InspectMediaMiniProps {}
const baseClassName = 'inspect-media-mini';

const InspectMediaMini = ({}: InspectMediaMiniProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const location = useLocation();
	const { defaultAccountId } = useSelector((state: any) => state.session);
	const { inspectingMediaData } = useSelector((state: any) => state.inspectScreenReducer);

	const actionContainerHTMLRef = useRef<HTMLDivElement | null>(null);
	const playerContainerHTMLRef = useRef<HTMLDivElement | null>(null);
	const headerHTMLRef = useRef<HTMLDivElement | null>(null);
	const contentContainerHTMLRef = useRef<HTMLDivElement | null>(null);

	const mediaDetailRef = useRef<any>();

	const [refreshData, setRefreshData] = useState<boolean>(true);
	const [isMediaModified, setIsMediaModified] = useState<boolean>(false);
	const [selectedTab, setSelectedTab] = useState<string>(MediaDetailUtils.MenuItems[0].value);

	const { mediaDetails } = useFetchMediaDetails({
		mediaId: inspectingMediaData?.id,
		mediaName: mediaNames.medias,
		mediaType: inspectingMediaData?.type,
		refreshData,
		resetRefreshData: () => setRefreshData(false),
		errorMessage: t('COMMON_MEDIA_NOT_FOUND_OR_NO_PERMISSION'),
	});

	const handleOnDataChanges = () => {
		setIsMediaModified(true);
	};

	const handleSaveChanges = (_event: React.MouseEvent) => {
		mediaDetailRef.current?.onSaveChanges(() => setIsMediaModified(false));
	};

	const handleCloseMiniMediaInspectView = useCallback(() => {
		(window as any).QplayerLoader.killInteractivePlayer();
		setIsMediaModified(false);
		setSelectedTab(MediaDetailUtils.MenuItems[0].value);
		dispatch(setInspectingMediaData({}));
	}, [dispatch]);

	const handleExpandButtonClick = useCallback(() => {
		if (!mediaDetails?.id) {
			return;
		}
		const fullPath = `${window.location.origin}#${screenRoutes.PUBLISH_MEDIA_LIBRARY}/medias/${mediaDetails?.id}/expand`;
		window.open(buildRoutePath(fullPath, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId), '_blank');
	}, [mediaDetails?.id, defaultAccountId]);

	const handleResizeScreen = useCallback(() => {
		document.body.style.overflow = 'hidden';
		const containerHeight = window.innerHeight;

		const contentHeight =
			containerHeight -
			8 -
			(headerHTMLRef.current?.getBoundingClientRect().height ?? 0) -
			(playerContainerHTMLRef.current?.getBoundingClientRect().height ?? 0) -
			(actionContainerHTMLRef.current?.getBoundingClientRect().height ?? 0);

		if (contentContainerHTMLRef?.current) {
			contentContainerHTMLRef.current.style.height = contentHeight + 'px';
		}
	}, []);

	useEffect(() => {
		setRefreshData(true);
		(window as any).QplayerLoader.killInteractivePlayer();
		const bodyOverFlow = inspectingMediaData?.id ? 'hidden' : '';
		document.body.style.overflow = bodyOverFlow;
	}, [inspectingMediaData]);

	useEffect(() => {
		if (!mediaDetails?.id) {
			return;
		}

		if (
			(window as any).QplayerLoader.initiateInteractivePlayer &&
			typeof (window as any).QplayerLoader.initiateInteractivePlayer === 'function'
		) {
			(window as any).QplayerLoader.initiateInteractivePlayer();
			handleResizeScreen();
		}

		return () => (window as any).QplayerLoader.killInteractivePlayer();
	}, [handleResizeScreen, mediaDetails]);

	useEffect(() => {
		handleResizeScreen();
		window.addEventListener('resize', handleResizeScreen);
		return () => window.removeEventListener('resize', handleResizeScreen);
	}, [handleResizeScreen]);

	useEffect(() => {
		handleCloseMiniMediaInspectView();
	}, [location]);

	return mediaDetails?.id ? (
		<div className={`${baseClassName}__container`}>
			<div className={`${baseClassName}__header`} ref={(r) => (headerHTMLRef.current = r)}>
				<div style={{ flex: '1' }}>Inspect Media</div>
				<IconButton onClick={handleExpandButtonClick}>
					<OpenInNewOutlined htmlColor="#000000" />
				</IconButton>
				<IconButton onClick={handleCloseMiniMediaInspectView} style={{ marginRight: '-12px' }}>
					<Close htmlColor="#000000" />
				</IconButton>
			</div>

			{inspectingMediaData?.type === mediaTypes.portal ? (
				<PortalContent
					baseClassName={baseClassName}
					portalDetail={mediaDetails}
					handleOnDataChanges={handleOnDataChanges}
					ref={mediaDetailRef}
				/>
			) : (
				<>
					<div
						className={`${baseClassName}__player-container`}
						ref={(r) => (playerContainerHTMLRef.current = r)}
					>
						<div style={{ position: 'relative', paddingBottom: '56.25%' }}>
							<div
								style={{ position: 'absolute', top: '0', left: '0' }}
								title={mediaDetails?.title}
								data-qplayer-account-id={defaultAccountId}
								data-qplayer-media-id={mediaDetails?.id}
								data-qplayer-preset-id="Interactive"
							></div>
						</div>
					</div>
					<MediaContent
						baseClassName={baseClassName}
						mediaDetails={mediaDetails}
						contentContainerHTMLRef={contentContainerHTMLRef}
						mediaDetailRef={mediaDetailRef}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						handleOnDataChanges={handleOnDataChanges}
					/>
				</>
			)}

			<div className={`${baseClassName}__actions`} ref={(r) => (actionContainerHTMLRef.current = r)}>
				<ButtonGroup>
					<Button variant="contained" color="primary" disabled={!isMediaModified} onClick={handleSaveChanges}>
						{t('COMMON_SAVE_CHANGES')}
					</Button>
				</ButtonGroup>
			</div>
		</div>
	) : null;
};

export default InspectMediaMini;
