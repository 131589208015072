import React, { createContext } from 'react';
import { ShareOutlined, VideoLibraryOutlined } from '@material-ui/icons';

export namespace MediaDetailUtils {
	export const MenuItems = [
		{
			value: 'detail',
			icon: <VideoLibraryOutlined htmlColor="#000000" />,
			activeIcon: <VideoLibraryOutlined htmlColor="#126cfc" />,
			label: 'Details',
		},
		{
			value: 'share',
			icon: <ShareOutlined htmlColor="#000000" />,
			activeIcon: <ShareOutlined htmlColor="#126cfc" />,
			label: 'Share',
		},
	];

	export const ExpandMenuItems = [
		{
			value: 'share',
			icon: <ShareOutlined htmlColor="#000000" />,
			activeIcon: <ShareOutlined htmlColor="#126cfc" />,
			label: 'Share',
		},
	];

	interface MediaDetailHeaderProps {
		isMediaModified?: boolean;
		setIsMediaModified: (_value: boolean) => void;
	}

	export const MediaDetailHeaderContext = createContext<MediaDetailHeaderProps>({
		setIsMediaModified: (_value) => {},
	});
}
