import React, { useCallback, useEffect, useMemo } from 'react';
import * as ReactRouter from 'react-router';
import MainHeader from './MainHeader';
import MediaDetailHeader from './MediaDetailHeader';
import { setPublishScreenType } from '../../actions/publishActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckLogin } from './useCheckLogin';
import { customScreenSizes, customScreenType } from '../../constants/mediaConstants';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, routePathSplitter, screenRoutes } from '../../constants/routesPath';
import { closeDrawer, updateSelectedKey, updateSubSelectedKey } from '../../actions/navActions';
import { PUBLISH_SUBKEY } from '../nav/navigationItems';
import { KEYS } from './constants';
import { localStorageKeys } from '../../constants/localStorageKeys';

type HeaderProps = ReactRouter.RouteComponentProps & {};

const Header = (props: HeaderProps) => {
	const dispatch = useDispatch();
	const { pathname } = ReactRouter.useLocation();

	const { history } = props;

	const { defaultAccountId, isLoggedIn } = useSelector((state) => (state as any).session);

	const handleTheTopHeaderMobileResponsiveness = () => {
		let newScreenType = customScreenType.medium;
		if (window.innerWidth > customScreenSizes.medium) {
			newScreenType = customScreenType.large;
		} else if (window.innerWidth <= customScreenSizes.medium && window.innerWidth > customScreenSizes.tablet) {
			newScreenType = customScreenType.medium;
		} else if (window.innerWidth <= customScreenSizes.tablet && window.innerWidth > customScreenSizes.small) {
			newScreenType = customScreenType.tablet;
		} else if (window.innerWidth <= customScreenSizes.small && window.innerWidth > customScreenSizes.mobile) {
			newScreenType = customScreenType.small;
		} else if (window.innerWidth <= customScreenSizes.mobile) {
			newScreenType = customScreenType.mobile;
		}
		dispatch(setPublishScreenType(newScreenType));

		const bodyElems = document.getElementsByTagName('body');
		if (!bodyElems || bodyElems.length === 0 || !bodyElems[0]) {
			return;
		}
		bodyElems[0].setAttribute('screen-type', newScreenType);
	};

	const updateNavKeysBasedOnLocation = useCallback((location: any) => {
		let currentLocation = location.hash === '' ? location.pathname : location.hash;
		// convert location to matching with route
		currentLocation = currentLocation
			.replace(localStorage.getItem('defaultAccountId'), ACCOUNT_ID_ROUTE_PLACEHOLDER)
			.replace(/\/page.*/, '');
		const startingPoint = localStorage.getItem('startingPoint');
		if (currentLocation) {
			if (currentLocation.match(screenRoutes.DASHBOARD)) {
				dispatch(updateSelectedKey(KEYS.dashboard));
				dispatch(closeDrawer());
			} else if (currentLocation.match(screenRoutes.ANALYTICS)) {
				dispatch(updateSelectedKey(KEYS.analytics));
				dispatch(closeDrawer());
			} else if (
				(currentLocation.match(screenRoutes.PUBLISH_ALL_MEDIA) ||
					currentLocation.match(/\/medias\/.*\/detail/i)) &&
				startingPoint !== 'EDIT'
			) {
				//case all media or media detail
				dispatch(updateSelectedKey(KEYS.publish));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.allMedia));
			} else if (currentLocation.match(screenRoutes.PUBLISH_SCENARIOS)) {
				dispatch(updateSelectedKey(KEYS.interact));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.interact));
			} else if (currentLocation.match(screenRoutes.PUBLISH_PLAYLISTS)) {
				dispatch(updateSelectedKey(KEYS.publish));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.playlists));
			} else if (currentLocation.match(screenRoutes.PUBLISH_CATALOGS)) {
				dispatch(updateSelectedKey(KEYS.publish));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.catalogs));
			} else if (currentLocation.match(screenRoutes.PUBLISH_SMART_FILTER)) {
				dispatch(updateSelectedKey(KEYS.publish));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.smartFilters));
			} else if (currentLocation.match(screenRoutes.CREATE)) {
				dispatch(updateSelectedKey(KEYS.create));
			} else if (currentLocation.match(screenRoutes.PUBLISH_LIVE_MEDIA)) {
				dispatch(updateSelectedKey(KEYS.live));
			} else if (currentLocation.match(screenRoutes.APPS_EDITOR)) {
				dispatch(updateSelectedKey(KEYS.editorApp));
				if (currentLocation.indexOf('exports') > 0) {
					dispatch(updateSubSelectedKey(KEYS.editorApp, 'exports'));
				} else if (currentLocation.indexOf('projects') > 0) {
					dispatch(updateSubSelectedKey(KEYS.editorApp, 'projects'));
				} else {
					dispatch(updateSubSelectedKey(KEYS.editorApp, 'editor'));
				}
			} else if (currentLocation.match(screenRoutes.APPS)) {
				dispatch(updateSelectedKey(KEYS.apps));
			} else if (currentLocation.match(screenRoutes.PUBLISH_EDITED_MEDIA) || startingPoint === 'EDIT') {
				dispatch(updateSelectedKey(KEYS.edit));
				dispatch(updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.edit));
			}
		}
	}, []);

	const storeStartingPointInLocalStorage = useCallback((location: any) => {
		let currentLocation = location.hash === '' ? location.pathname : location.hash;
		// convert location to matching with route
		currentLocation = currentLocation
			.replace(localStorage.getItem('defaultAccountId'), ACCOUNT_ID_ROUTE_PLACEHOLDER)
			.replace(/\/page.*/, '');
		if (
			currentLocation.includes(screenRoutes.PUBLISH_ALL_MEDIA) ||
			(currentLocation.includes(screenRoutes.PUBLISH_MEDIAS) &&
				(currentLocation.includes('/expand') || currentLocation.includes('/detail')))
		) {
			localStorage.setItem('startingPoint', 'ALL_MEDIA');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_PLAYLISTS)) {
			localStorage.setItem('startingPoint', 'PLAYLISTS');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_SCENARIOS)) {
			localStorage.setItem('startingPoint', 'INTERACT');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_EDITED_MEDIA)) {
			localStorage.setItem('startingPoint', 'EDIT');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_RECYCLE_BIN)) {
			localStorage.setItem('startingPoint', 'RECYCLE_BIN');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_CATALOGS)) {
			localStorage.setItem('startingPoint', 'CATALOGS');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_LIVE_MEDIA)) {
			localStorage.setItem('startingPoint', 'LIVE');
		} else if (currentLocation.includes(screenRoutes.PUBLISH_PORTALS)) {
			localStorage.setItem('startingPoint', 'PORTALS');
		} else {
			localStorage.setItem('startingPoint', 'ALL_MEDIA');
		}
	}, []);

	const storeLocationInLocalStorage = useCallback((currentLocation: any) => {
		const location = currentLocation.hash ? currentLocation : currentLocation.location;
		if (!location) {
			return;
		}

		const localRouteString = localStorage.getItem('routeLocation');
		let routeArray: any[] = [];
		if (localRouteString) {
			routeArray = JSON.parse(localRouteString);
		}

		if (routeArray.length > 5) {
			routeArray.splice(0, 1);
		}

		const locationToStore = location.hash.split(routePathSplitter + '/');
		if (locationToStore.length > 1) {
			(routeArray as any).push(locationToStore[1]);
		}
		localStorage.setItem('routeLocation', JSON.stringify(routeArray));
	}, []);

	const checkAppThemeMode = (location: any) => {
		const bodyElems = document.getElementsByTagName('body');
		const currentLocation = location.hash === '' ? location.pathname : location.hash;

		if (!bodyElems || bodyElems.length === 0 || !bodyElems[0]) {
			return;
		}
		const body = bodyElems[0];
		if (currentLocation.match('liveManager') || currentLocation.match('interactiveManager')) {
			body.setAttribute('dark-mode', 'true');
		} else {
			body.removeAttribute('dark-mode');
		}
	};

	useCheckLogin(defaultAccountId, () => {
		localStorage.setItem(localStorageKeys.USER_ACCESS_LINK_BEFORE_REDIRECT, window.location.hash.replace('#', ''));
		history.push(screenRoutes.LOGIN);
	});

	useEffect(() => {
		updateNavKeysBasedOnLocation(window.location);
		handleTheTopHeaderMobileResponsiveness();
		checkAppThemeMode(window.location);
		storeStartingPointInLocalStorage(window.location);

		const historyListener = history.listen((location: any) => {
			storeLocationInLocalStorage(location);
			storeStartingPointInLocalStorage(location);
			checkAppThemeMode(location);
			updateNavKeysBasedOnLocation(location);
		});
		window.addEventListener('resize', handleTheTopHeaderMobileResponsiveness, true);

		return () => {
			window.removeEventListener('resize', handleTheTopHeaderMobileResponsiveness, true);
			historyListener();
		};
	}, []);

	const isMediaExpandScreen = useMemo(() => {
		return pathname.includes('/expand') && pathname.includes('/medialibrary/medias/');
	}, [pathname]);

	if (!isLoggedIn) {
		return null;
	}

	return isMediaExpandScreen ? <MediaDetailHeader {...props} /> : <MainHeader {...props} />;
};

export default Header;
