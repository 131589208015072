import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Typography,
} from '@material-ui/core';
import { CloseOutlined, InfoOutlined } from '@material-ui/icons';

interface ErrorDialogProps {
	open: boolean;
	onClose: () => void;
}

const ErrorDialog = ({ open, onClose }: ErrorDialogProps) => {
	const history = useHistory();

	const handleContactSupport = useCallback(() => window.open('mailto:support@qbrick.com'), []);
	const handleRefreshPage = useCallback(() => {
		onClose?.();
		history.go(0);
	}, [history, onClose]);

	return (
		<Dialog open={!!open}>
			<DialogTitle className="dialogTitle" disableTypography>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
					<InfoOutlined htmlColor="#FD5E6B" style={{ marginRight: '5px' }} />
					<Typography variant="h6" style={{ margin: '0' }}>
						Error!
					</Typography>
				</div>
				<IconButton onClick={onClose}>
					<CloseOutlined />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText color="textPrimary">
					<b>Unable to save latest changes.</b>
				</DialogContentText>
				<DialogContentText color="textPrimary">
					We seem to have encountered an issue while trying to save the latest changes to your project. Please
					try refreshing the page and try again. If the problem persists, please don’t hesitate to contact our
					support team for further assistance.
				</DialogContentText>
			</DialogContent>

			<DialogActions style={{ borderTop: '1px solid #e5e9ec' }}>
				<Button onClick={handleContactSupport} color="primary" variant="outlined">
					Contact support
				</Button>
				<Button onClick={handleRefreshPage} color="primary" variant="contained">
					Refresh page
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorDialog;
