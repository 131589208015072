import { getDetails } from './../services/mediaDisplayService';
import { mediaNames, messageTypes, mediaTypes } from '../constants/mediaConstants';
import { menuKeys, detailsMenuKeys } from './../constants/inspectScreenMenu';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from './../constants/routesPath';
import { isIE } from './../services/browserDetectionService';
import { LocalStorageService } from './localStorageService';
import { buildRoutePath } from '../utils/commonUtil';

export const defaultVideoWidth = '256';
export const defaultVideoHeight = '144';

export const defaultMobileVideoWidth = '320';
export const defaultMobileVideoHeight = '180';

export const isNumberBetween = (start, end, number) => {
	if (number >= start && number <= end) {
		return true;
	}
	return false;
};

export const setVideoWidth = (component, width) => {
	const { mobileView } = component.state;
	let widthToSet = defaultVideoWidth;
	let heightToSet = defaultVideoHeight;

	if (mobileView) {
		widthToSet = defaultMobileVideoWidth;
		heightToSet = defaultMobileVideoHeight;
	}
	let screenSize = 98; // for mobile screen to occupy 100% width
	if (width > 1024) {
		// for desktop screen which  is set to 30 percent
		screenSize = 34;
	}
	widthToSet = (screenSize / 100) * width;
	heightToSet = (widthToSet * 9) / 16;
	component.setState({
		videoWidth: widthToSet,
		videoHeight: heightToSet,
	});
	let mediaTags = document.getElementById(component.mediaDetailWidget + '-od');
	let playerIframeTag = document.getElementById('playerIframe');
	if (mediaTags) {
		mediaTags.style.width = widthToSet + 'px';
		mediaTags.style.height = heightToSet + 'px';
	}
	if (playerIframeTag) {
		playerIframeTag.width = widthToSet;
		playerIframeTag.height = heightToSet;
	}
};

export const sides = {
	left: 'LEFT',
	right: 'RIGHT',
};

export const setLastRoute = (component) => {
	if (localStorage.getItem('routeLocation')) {
		let routeLocationArray = JSON.parse(localStorage.getItem('routeLocation'));
		if (routeLocationArray.length > 1) {
			let lastRoute = routeLocationArray[routeLocationArray.length - 2];
			if (isIE) {
				lastRoute = routeLocationArray[routeLocationArray.length - 1];
			}
			component.setState({
				lastRoute: lastRoute,
			});
		}
	}
};

export const updateStateAsMobileViewOnResize = (component) => {
	let width = '';
	let $inspectView = document.getElementById('inspectView');
	width = window.innerWidth;
	if (width <= 1024 && $inspectView) {
		$inspectView.classList.add('mobileView');
		component.setState({ mobileView: true }, () => {
			checkForZones(component, 'resize');
			resizeVideo(component, width);
		});
	} else if ($inspectView) {
		$inspectView.classList.remove('mobileView');
		component.setState({ mobileView: false }, () => {
			checkForZones(component, 'resize');
			resizeVideo(component, width);
		});
	}
};
export const updatePlayerSizeOfPlayerConfiguration = (component) => {
	const $inspectView = document.getElementById('screenParentContainer');
	const width = window.innerWidth;

	if (width <= 1024 && $inspectView) {
		$inspectView.classList.add('mobileView');
		setVideoWidth(component, width);
	} else if ($inspectView) {
		const wrappers = document.getElementsByClassName('LivePlayerWrapper');
		const previewWrapper = wrappers.length > 0 ? wrappers[0] : null;
		const videoWidth = previewWrapper ? previewWrapper.getBoundingClientRect().width : width;

		$inspectView.classList.remove('mobileView');
		setVideoWidth(component, videoWidth);
	}
};

const resizeVideo = (component, width) => {
	const { mediaName } = component.state;
	if (mediaName === mediaNames.playlists || mediaName === mediaNames.medias || mediaName === mediaNames.scenarios) {
		setVideoWidth(component, width);
	}
};

export const showLeftPart = () => {
	let leftZone = document.getElementById('leftZone');
	let rightZone = document.getElementById('rightZone');
	let middleZone = document.getElementById('middleZone');
	if (leftZone && leftZone.style) {
		leftZone.style.display = 'block';
	}
	if (rightZone && rightZone.style) {
		rightZone.style.display = 'block';
	}
	if (middleZone && middleZone.style) {
		middleZone.style.display = 'none';
	}
};

export const showAllPart = () => {
	let leftZone = document.getElementById('leftZone');
	let rightZone = document.getElementById('rightZone');
	let middleZone = document.getElementById('middleZone');
	if (leftZone && leftZone.style) {
		leftZone.style.display = 'block';
	}
	if (rightZone && rightZone.style) {
		rightZone.style.display = 'block';
	}
	if (middleZone && middleZone.style) {
		middleZone.style.display = 'block';
	}
};

export const showRightPart = () => {
	let leftZone = document.getElementById('leftZone');
	let rightZone = document.getElementById('rightZone');
	let middleZone = document.getElementById('middleZone');
	if (leftZone && leftZone.style) {
		leftZone.style.display = 'none';
	}
	if (rightZone && rightZone.style) {
		rightZone.style.display = 'none';
	}
	if (middleZone && middleZone.style) {
		middleZone.style.display = 'block';
	}
	let root = document.getElementById('screenParentContainer');
	if (root) {
		root.scrollTop = 0;
	}
};

export const showRightZone = (item, component, newView, callback, defaultAccountId) => {
	const { mobileView, mediaName } = component.state;
	const { mediaType } = component.props;

	/* let baseUrl = mediaName === mediaNames.playlists ? screenRoutes.PUBLISH_PLAYLISTS : screenRoutes.PUBLISH_ALL_MEDIA; */
	let baseUrl =
		mediaName === mediaNames.playlists
			? screenRoutes.PUBLISH_PLAYLISTS
			: mediaType === mediaTypes.trashedMedias && mediaName === mediaNames.medias
			? screenRoutes.PUBLISH_RECYCLE_BIN
			: screenRoutes.PUBLISH_MEDIA_LIBRARY;

	baseUrl = buildRoutePath(baseUrl, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId);

	/* let baseUrl = screenRoutes.PUBLISH_MEDIA_LIBRARY; */
	let nameOfMedia = '';
	if (mediaName !== mediaNames.playlists && mediaType !== mediaTypes.trashedMedias) {
		if (mediaName === mediaNames.scenarios) {
			nameOfMedia = 'interact/';
		} else {
			nameOfMedia = mediaName + '/';
		}
	}
	let newPath =
		baseUrl +
		'/' +
		nameOfMedia +
		component.inspectedItemId +
		'/' +
		'detail' +
		'/' +
		menuKeys.details +
		'/' +
		item.id;

	if (
		(component.mediaDetailsSection && component.mediaDetailsSection.state.mediaDetailsModified) ||
		component.props.isInteractScreenModified ||
		component.props.isMountedSubtitleUpdated
	) {
		component.showInspectScrenExitConfirmationStatus(); //tobe checked
		component.props.changeRouteAfterInspectScreen(newPath);
		if (callback) {
			callback();
		}
		return;
	}
	component.setState({
		currentView: newView ? newView : item.id,
		mobileViewZone: sides.right,
	});
	if (item.id === detailsMenuKeys.thumbnailManager) {
		component.thumbnailManager.fetchAndSetMediaDetails();
	}
	component.props.history && component.props.history.push(newPath);
	if (mobileView) {
		showRightPart();
	}
	if (callback) {
		callback();
	}
};

export const handleMenuChange = (component, id, param, defaultAccountId) => {
	const { mediaName, lastRoute } = component.state;
	const { isInspectScreenModified, isInteractScreenModified, isMountedSubtitleUpdated, mediaType } = component.props;

	let baseUrl =
		mediaName === mediaNames.playlists
			? screenRoutes.PUBLISH_PLAYLISTS
			: mediaType === mediaTypes.trashedMedias && mediaName === mediaNames.medias
			? screenRoutes.PUBLISH_RECYCLE_BIN
			: screenRoutes.PUBLISH_MEDIA_LIBRARY;

	baseUrl = buildRoutePath(baseUrl, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId);

	if (id !== menuKeys.close) {
		let nameOfMedia = '';
		if (mediaName !== mediaNames.playlists && mediaType !== mediaTypes.trashedMedias) {
			nameOfMedia = mediaName + '/';
		}
		let newPath =
			baseUrl +
			'/' +
			nameOfMedia +
			component.inspectedItemId +
			'/' +
			'detail' +
			'/' +
			(param && param.appId ? param.appId + '/' + param.configId : id);
		if (param && param.appId) {
			component.props.setSelectedApp(param);
		}
		if (isInspectScreenModified || isMountedSubtitleUpdated) {
			if (
				(component.mediaDetailsSection &&
					component.mediaDetailsSection.state &&
					component.mediaDetailsSection.state.mediaDetailsModified) ||
				isInteractScreenModified ||
				isMountedSubtitleUpdated
			) {
				component.showInspectScrenExitConfirmationStatus();
				component.props.changeRouteAfterInspectScreen(newPath);
				return;
			} else {
				component.props.sendMediaScreenUnChanged();
			}
		}

		component.props.history.push({ pathname: newPath });
		component.props.updateMenuKeys(id);
		component.setState({ selectedMenu: id });
	}
	if (id === menuKeys.close) {
		if ((isInspectScreenModified && lastRoute && !lastRoute.match('/detail')) || isMountedSubtitleUpdated) {
			// todo to check this logic
			component.showInspectScrenExitConfirmationStatus();
			component.props.changeRouteAfterInspectScreen(`/${lastRoute}`);
		} else if (lastRoute && !lastRoute.match('/detail')) {
			component.props.updateCurentComponentKey('INSPECT_SCREEN_HELPER');
			component.props.history.push('/' + lastRoute);
		} else {
			component.props.history.push(baseUrl);
		}
	}
};

export const getNewWindowUrl = (component, id, param) => {
	const { mediaName } = component.state;

	let baseUrl = screenRoutes.PUBLISH_MEDIA_LIBRARY;
	let nameOfMedia = '';
	if (mediaName !== mediaNames.playlists) {
		nameOfMedia = mediaName + '/';
	}
	let newPath =
		baseUrl +
		'/' +
		nameOfMedia +
		component.inspectedItemId +
		'/' +
		'detail' +
		'/' +
		(param && param.appId ? param.appId + '/' + param.configId : id);
	return newPath;
};

export const checkForRouteParam = (component, pathname) => {
	let menuKey = '';
	let subMenuKey = '';
	if (!pathname && component.props.match && component.props.match.params && component.props.match.params.menuKey) {
		menuKey = component.props.match.params.menuKey;
	} else if (pathname) {
		menuKey = pathname.split('/detail/');
		if (menuKey.length > 1) {
			menuKey = menuKey[1];
			if (menuKey && menuKey.match('/')) {
				menuKey = menuKey.split('/');
				menuKey = menuKey[0];
			}
		} else {
			menuKey = component.defaultMenuKey;
		}
	}

	if (!pathname && component.props.match && component.props.match.params && component.props.match.params.subMenuKey) {
		subMenuKey = component.props.match.params.subMenuKey;
	} else if (pathname) {
		subMenuKey = pathname.split('/' + menuKeys.details + '/');
		if (subMenuKey.length > 1) {
			subMenuKey = subMenuKey[1];
		} else {
			if (menuKey === menuKeys.details) {
				subMenuKey = component.defaultDetailsMenuKey;
			}
		}
	}
	if (!menuKey) {
		menuKey = menuKeys.details;
	}
	component.setState(
		{
			selectedMenu: menuKey,
		},
		() => {
			if (menuKey == menuKeys.details && checkForValidDetailsKey(subMenuKey)) {
				component.setState({
					currentView: subMenuKey,
				});
			}
			if (menuKey === menuKeys.interactiveManager) {
				if (component.interactEditorRef) {
					component.interactEditorRef.onSelectedInteractTimeline();
				}
			}
		}
	);
};

const checkForValidDetailsKey = (subMenuKey) => {
	switch (subMenuKey) {
		case detailsMenuKeys.mediaDetails:
		case detailsMenuKeys.embedVideo:
		case detailsMenuKeys.socialSharing:
		case detailsMenuKeys.thumbnailManager:
		case detailsMenuKeys.accessRestrictions:
		case detailsMenuKeys.files:
		case detailsMenuKeys.playlistMediaDetails:
		case detailsMenuKeys.scenes:
			return true;
	}
	return false;
};

export const checkForZones = (component, resize) => {
	const { mobileView, mobileViewZone } = component.state;
	if (!mobileView) {
		showAllPart();
	} else {
		if (resize && mobileViewZone === sides.right) {
			showRightPart();
		} else {
			showLeftPart();
		}
	}
};

export const reverseArray = (arr) => {
	let newArray = [];
	if (arr && arr.length > 0) {
		for (let i = arr.length - 1; i >= 0; i--) {
			if (arr[i].renditions && arr[i].type === 'image') {
				newArray.push(arr[i]);
			}
		}
	}
	return newArray;
};

export const Dummy = (media, component) => {
	let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
	component.props.addMediaDetails?.(customMediaInfo); //added to retain original info in inspect media
	component.setState({ mediaDetails: customMediaInfo });

	component.props.setSelectedMedia?.(defaultAccountId, media);

	component.setState({ mediaDetails: {} });
	let latestChanges = {
		...component.state.latestChanges,
	};
	latestChanges.title = customMediaInfo.title;
	latestChanges.description = customMediaInfo.description;
	latestChanges.tags = customMediaInfo.tags || [];
	latestChanges.custom = customMediaInfo.custom || {};
	component.setState({ latestChanges: latestChanges });
	if (media && media.asset && media.asset.resources) {
		media.asset.resources.map((resource) => {
			resource &&
				resource.renditions &&
				resource.renditions.map((rendition) => {
					if (rendition.type === mediaTypes.livevideo || rendition.type === mediaTypes.liveaudio) {
						component.setState({
							liveMediaChannelId: rendition.channel ? rendition.channel.id : '',
						});
					}
				});
		});
	} else if (mediaDetails) {
		component.setState({ thumbImageResources: mediaDetails.thumbnail });
	}
	component.updateBreadCrumb(customMediaInfo);
};

export const fetchAndSetMediaDetails = (mediaId, component) => {
	const { defaultAccountId, t, mediaType } = component.props;
	const { mediaName, mediaDetails } = component.state;

	//TODO: Redux store

	component.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaName, mediaType).then((media) => {
		if (!LocalStorageService.getAccessToken()) {
			// incase not logging yet, just ignore
			return;
		}

		if (!media) {
			component.showMessage(t('COMMON_MEDIA_NOT_FOUND'), messageTypes.error);
			return;
		}
		if (typeof media == 'string') {
			component.showMessage(media, messageTypes.error);
			return;
		}
		let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
		component.props.addMediaDetails(customMediaInfo); //added to retain original info in inspect media
		component.setState({ mediaDetails: customMediaInfo });

		component.props.setSelectedMedia?.(defaultAccountId, media);

		//component.setState({ mediaDetails: {} });
		let latestChanges = {
			...component.state.latestChanges,
		};
		latestChanges.title = customMediaInfo.title;
		latestChanges.description = customMediaInfo.description;
		latestChanges.tags = customMediaInfo.tags || [];
		latestChanges.custom = customMediaInfo.custom || {};
		component.setState({ latestChanges: latestChanges });
		if (media && media.asset && media.asset.resources) {
			media.asset.resources.map((resource) => {
				resource &&
					resource.renditions &&
					resource.renditions.map((rendition) => {
						if (rendition.type === mediaTypes.livevideo || rendition.type === mediaTypes.liveaudio) {
							component.setState({
								liveMediaChannelId: rendition.channel ? rendition.channel.id : '',
							});
						}
					});
			});
		} else if (mediaDetails) {
			component.setState({
				thumbImageResources: mediaDetails.thumbnail,
			});
		}
		component.updateBreadCrumb(customMediaInfo);
	});
};

export const fetchAndSetCatalogDetails = (mediaId, component) => {
	const { defaultAccountId, t } = component.props;
	const { mediaName, mediaDetails } = component.state;

	component.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaName).then((media) => {
		if (!media) {
			component.props.showMessage(
				t('COMMON_MEDIA_NOT_FOUND' + ' ' + mediaId + ' ' + defaultAccountId),
				messageTypes.error
			);
			return;
		}
		if (typeof media == 'string') {
			component.showMessage(media, messageTypes.error);
			return;
		}
		let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
		component.props.addMediaDetails(customMediaInfo); //added to retain original info in inspect media
		component.setState({ mediaDetails: customMediaInfo });
		let latestChanges = {
			...component.state.latestChanges,
		};
		latestChanges.title = customMediaInfo.title;
		latestChanges.description = customMediaInfo.description;
		latestChanges.tags = customMediaInfo.tags || [];
		latestChanges.custom = customMediaInfo.custom || {};
		component.setState({ latestChanges: latestChanges });
		if (media && media.asset && media.asset.resources) {
			media.asset.resources.map((resource) => {
				resource &&
					resource.renditions &&
					resource.renditions.map((rendition) => {
						if (rendition.type === mediaTypes.livevideo || rendition.type === mediaTypes.liveaudio) {
							component.setState({
								liveMediaChannelId: rendition.channel ? rendition.channel.id : '',
							});
						}
					});
			});
		} else if (mediaDetails) {
			component.setState({
				thumbImageResources: mediaDetails.thumbnail,
			});
		}
		component.updateBreadCrumb(customMediaInfo);
	});
};

export const deleteAndAddNewRestrictionOnMedia = (component) => {
	const { defaultAccountId, t } = component.props;
	const { mediaName, latestChanges, mediaDetails } = component.state;
	component.props
		.deleteRestrictionRuleOnMedia(
			defaultAccountId,
			mediaName,
			mediaDetails.id,
			latestChanges.selectedRestrictionDetails.id
		)
		.then((data) => {
			if (isRequestFail(component, data)) {
				component.showMessage(t('FAILED_TO_REMOVE_EXISTNG_RULE'), messageTypes.error);
				return;
			}
			let newChanges = {
				...latestChanges,
			};
			newChanges.selectedRestrictionDetails = {};
			component.setState({
				latestChanges: newChanges,
			});
			const selectedRestrictionDetails = component.accessDetailsSection.state.selectedRestrictionDetails;
			let newBody = {
				id: selectedRestrictionDetails.id,
			};
			component.props
				.applyRestrictionRuleOnMedia(defaultAccountId, mediaName, mediaDetails.id, newBody)
				.then((data) => {
					if (isRequestFail(component, data)) {
						component.showMessage(t('FAILED_TO_ASSIGN_SELECTED_RULE'), messageTypes.error);
						return;
					}
					component.showMessage(t('ADDED_NEW_RESTRICTION_RULE'), messageTypes.info);
					if (latestChanges) {
						let newChanges = {
							...latestChanges,
						};
						newChanges.selectedRestrictionDetails = selectedRestrictionDetails;
						component.setState({
							latestChanges: newChanges,
						});
					}
				});
		});
};

export const deleteDateRestrictionRule = (component) => {
	const { defaultAccountId, t } = component.props;
	const { mediaName, latestChanges, mediaDetails } = component.state;
	component.props.deleteRestrictionRule(defaultAccountId, latestChanges.appliedDateRuleId).then((data) => {
		if (isRequestFail(component, data)) {
			component.showMessage(t('FAILED_TO_REMOVE_DATE_RULE'), messageTypes.error);
			return;
		}
		component.props
			.deleteRestrictionRuleOnMedia(defaultAccountId, mediaName, mediaDetails.id, latestChanges.appliedDateRuleId)
			.then((data) => {
				if (isRequestFail(component, data)) {
					component.showMessage(t('FAILED_TO_REMOVE_EXISTNG_RULE'), messageTypes.error);
					return;
				}
				component.showMessage(t('DELETED_DATE_RULE'), messageTypes.info);
				let newChanges = {
					...latestChanges,
				};
				newChanges.appliedDateRuleId = '';
				newChanges.fromDateRule = '';
				component.setState({
					latestChanges: newChanges,
				});
			});
	});
};

export const handleCancelAndRoute = (component) => {
	const { routeAfterSave, inspectScrenExitConfirmationStatus } = component.state;
	if (inspectScrenExitConfirmationStatus) {
		component.hideInspectScrenExitConfirmationStatus();
	}
	//handleCancel(component)
	component.handleCancel();
	if (routeAfterSave) {
		component.props.history.push(routeAfterSave);
		component.props.changeRouteAfterInspectScreen(''); //important to add
	}
};

export const handleStayOnScreen = (component) => {
	component.hideInspectScrenExitConfirmationStatus();
	component.props.changeRouteAfterInspectScreen(''); //important to add
};

export const handleSaveAndRoute = (component) => {
	const { routeAfterSave, inspectScrenExitConfirmationStatus } = component.state;
	if (inspectScrenExitConfirmationStatus) {
		component.hideInspectScrenExitConfirmationStatus();
	}
	if (
		routeAfterSave &&
		component.mediaDetailsSection &&
		component.mediaDetailsSection.state.mediaDetailsModified &&
		component.mediaDetailsSection.checkForErrorCases()
	) {
		return;
	}
	component.handleSave();
	if (routeAfterSave) {
		setTimeout(() => {
			component.props.history.push(routeAfterSave);
			component.props.changeRouteAfterInspectScreen(''); //important to add
		}, 2000);
	}
};

export const isRequestFail = (objectMethods, data) => {
	let requestFail = false;
	if (data && data.message) {
		requestFail = true;
		objectMethods.showMessage(data.message, messageTypes.error);
	}

	if (data && data.Message) {
		requestFail = true;
		objectMethods.showMessage(data.Message, messageTypes.error);
	}
	if (!data) {
		requestFail = true;
	}
	return requestFail;
};

export const setAccessDetails = (type, value, component) => {
	const { latestChanges } = component.state;
	let newChanges = {
		...latestChanges,
	};
	switch (type) {
		case 'fromDateRule':
			newChanges.fromDateRule = value;
			break;
		case 'toRuleDate':
			newChanges.toRuleDate = value;
			break;
		case 'selectedRestrictionDetails':
			newChanges.selectedRestrictionDetails = value;
			break;
		case 'appliedDateRuleId':
			newChanges.appliedDateRuleId = value;
			break;
		default:
			break;
	}
	component.setState({
		latestChanges: newChanges,
	});
};
