import moment from 'moment';
import { mediaTypes } from '../constants/mediaConstants';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../constants/routesPath';

export const generateUUID = (_) => {
	let d = new Date().getTime();
	if (Date.now) {
		d = Date.now();
	}
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const getExtention = (path) => (path.match(/(?:.+..+[^\/]+$)/gi) != null ? path.split('.').slice(-1) : 'null');

// Analytics utils
export const _options = (
	data,
	cType,
	xAxisType = 'datetime',
	xAxisText = `GMT ${moment(new Date())
		.format()
		.substr(moment(new Date()).format().length - 6)}`,
	yAxisText = 'Number of Viewers'
) => {
	return {
		time: {
			useUTC: false,
		},
		title: { text: undefined },
		credits: { enabled: false },
		exporting: { enabled: false },
		chart: { type: cType, zoomType: 'xy' },
		series: data,
		xAxis: {
			type: xAxisType,
			title: { text: xAxisText },
		},
		yAxis: [
			{
				title: { text: yAxisText },
			},
		],
		tooltip: {
			pointFormat: '<span style="color:{series.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
			shared: true,
		},
	};
};

export const _optionsWithByteFormat = (
	data,
	cType,
	xAxisType = 'datetime',
	xAxisText = `GMT ${moment(new Date())
		.format()
		.substr(moment(new Date()).format().length - 6)}`,
	yAxisText = 'Number of Viewers',
	formatAsMbps = 0
) => {
	return {
		time: {
			useUTC: false,
		},
		title: { text: undefined },
		credits: { enabled: false },
		exporting: { enabled: false },
		chart: { type: cType, zoomType: 'xy' },
		series: data,
		xAxis: {
			type: xAxisType,
			title: { text: xAxisText },
		},
		yAxis: [
			{
				title: { text: yAxisText },
				labels: {
					formatter: function () {
						return formatByte(this.value, formatAsMbps);
					},
				},
			},
		],
		tooltip: {
			formatter: function () {
				return (
					moment(this.point?.x).format('dddd, MMM DD, HH:mm') +
					'<br>' +
					'<span style="color:' +
					this.series?.color +
					'">●</span> ' +
					this.series?.name +
					': <b>' +
					formatByte(this.point?.y, formatAsMbps) +
					'</b><br>'
				);
			},
		},
	};
};

export const _optionsStorage = (
	data,
	cType,
	xAxisType = 'datetime',
	xAxisText = `GMT ${moment(new Date())
		.format()
		.substr(moment(new Date()).format().length - 6)}`,
	yAxisText = 'Number of Viewers',
	formatAsMbps = 0
) => {
	return {
		time: {
			useUTC: false,
		},
		title: { text: undefined },
		credits: { enabled: false },
		exporting: { enabled: false },
		chart: { type: cType, zoomType: 'xy' },
		series: data,
		xAxis: {
			type: xAxisType,
			title: { text: xAxisText },
		},
		yAxis: [
			{
				title: { text: yAxisText },
				labels: {
					formatter: function () {
						return formatByte(this.value, formatAsMbps);
					},
				},
			},
		],
		tooltip: {
			formatter: function () {
				return (
					moment(this.point?.x).format('dddd, MMM DD, HH:mm') +
					'<br>' +
					'<span style="color:' +
					this.series?.color +
					'">●</span> ' +
					this.series?.name +
					': <b>' +
					formatByte(this.point?.y, formatAsMbps) +
					'</b><br>'
				);
			},
		},
		plotOptions: {
			spline: {
				lineWidth: 1,
				softThreshold: false,
			},
			area: {
				stacking: 'normal',
				lineWidth: 1,
			},
			series: {
				stickyTracking: false,
			},
		},
	};
};

export const _optionsPIE = (data, cType) => {
	return {
		title: { text: undefined },
		credits: { enabled: false },
		exporting: { enabled: false },
		chart: { type: cType, zoomType: 'xy' },
		series: data,
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
		},
	};
};

export const _optionsMAP = (data) => {
	return {
		title: { text: undefined },
		credits: { enabled: false },
		exporting: { enabled: false },
		chart: { type: 'map', zoomType: 'xy' },
		legend: {
			layout: 'horizontal',
			borderWidth: 0,
			backgroundColor: 'rgba(255,255,255,0.85)',
			floating: false,
			verticalAlign: 'bottom',
			horizontalAlign: 'center',
		},
		colorAxis: {
			maxColor: '#7b9b00',
			minColor: '#d7e241',
			type: 'linear',
			allowDecimals: false,
			marker: {
				color: 'black',
			},
			minPadding: 0.005,
		},
		lang: {
			noData: 'No data for selected period',
		},
		mapNavigation: {
			enableButtons: true,
			enableMouseWheelZoom: true,
		},
		tooltip: {
			formatter: function () {
				return (
					'<b>' +
					this.point?.name +
					'</b><br>' +
					'Asset Hits: ' +
					this.point.options?.hits +
					'<br>' +
					'Total Volume: ' +
					formatByte(this.point.options?.value) +
					'<br>'
				);
			},
			style: {
				fontSize: '12px',
				fontFamily: 'Helvetica, Arial, sans-serif',
			},
		},
		series: data,
	};
};

export const formatByte = (bytes, formatAsMbps) => {
	if (bytes == null || bytes == undefined || bytes == 0) {
		return `0`;
	}

	if (formatAsMbps) {
		var Mbit = (bytes * 8) / 1048576;
		var Mbps = Mbit / (formatAsMbps / 1000);

		if (Mbps < 1000) {
			return Math.round(((bytes * 8) / 1048576 / (formatAsMbps / 1000)) * 100) / 100 + ' Mbps';
		} else if (Mbps >= 1000) {
			return Math.round(((bytes * 8) / 1073741824 / (formatAsMbps / 1000)) * 100) / 100 + ' Gbps';
		}
	} else {
		const maxMB = 1073741824;
		const maxKB = 1048576;

		if (maxKB > bytes) {
			return Math.round((bytes / 1000) * 100) / 100 + ' KB';
		} else if (maxMB > bytes) {
			return Math.round((bytes / 1000000) * 100) / 100 + ' MB';
		} else {
			return Math.round((bytes / 1000000000) * 100) / 100 + ' GB';
		}
	}
};

export const secondsToTime = (secs, formatAsExtendedTime) => {
	if (secs === undefined) {
		return '';
	}

	var hr = Math.floor(secs / 3600);
	var min = Math.floor((secs - hr * 3600) / 60);
	var sec = Math.floor(secs - hr * 3600 - min * 60);

	if (formatAsExtendedTime) {
		return `${pad(hr, 2)}:${pad(min, 2)}:${pad(sec, 2)}`;
	} else if (hr > 0) {
		return hr + 'h ' + min + 'm ' + sec + 's';
	} else if (min > 0) {
		return min + 'm ' + sec + 's';
	} else {
		return sec + 's';
	}

	function pad(num, len) {
		return (new Array(len + 1).join('0') + num).slice(-len);
	}
};

export const _getBucket = (from, to) => {
	var bucket = '';
	var numberOfDays = moment(to).diff(moment(from), 'days');
	if (numberOfDays === 0) {
		bucket = '&bucket=started+00:15:00';
	} else if (numberOfDays > 0 && numberOfDays <= 7) {
		bucket = '&bucket=started+01:00:00';
	}
	return bucket;
};

export const filterImageMedia = (medias) => {
	return medias.filter((media) => {
		const {
			asset: { resources },
		} = media;

		return resources && resources.some(({ type }) => type !== 'image');
	});
};

export const getCurrentRotation = (element) => {
	const st = window.getComputedStyle(element, null);
	const tm =
		st.getPropertyValue('-webkit-transform') ||
		st.getPropertyValue('-moz-transform') ||
		st.getPropertyValue('-ms-transform') ||
		st.getPropertyValue('-o-transform') ||
		st.getPropertyValue('transform') ||
		'none';
	if (tm != 'none') {
		const values = tm.split('(')[1].split(')')[0].split(',');

		const angle = Math.round(Math.atan2(values[1], values[0]) * (180 / Math.PI));
		return angle < 0 ? angle + 360 : angle;
	}
	return 0;
};

export const removeMeta = (obj, key) => {
	for (const prop in obj) {
		if (prop === key) {
			delete obj[prop];
		} else if (typeof obj[prop] === 'object') {
			removeMeta(obj[prop], key);
		}
	}

	return obj;
};

export const searchTree = (node, searchingId) => {
	if (!node) {
		return null;
	}

	if (node.id?.toString() === searchingId?.toString()) {
		return node;
	} else if (node.children) {
		let i;
		let result = null;
		for (i = 0; result === null && i < node.children.length; i++) {
			result = searchTree(node.children[i], searchingId);
		}
		return result;
	}
	return null;
};

export const searchTreePath = (tree = null, searchingId) => {
	if (!tree) {
		return;
	}
	const loop = (path, node) => {
		if (!node) {
			return;
		}
		return node?.id === searchingId
			? [path]
			: (node?.children ?? []).reduce((acc, child) => acc.concat(loop([...path, node], child)), []);
	};
	return loop([], tree);
};

export const getInspectLink = (mediaDetail, isContextMenu = false, mediaType, defaultAccountId) => {
	if (!mediaDetail || !mediaDetail.id) {
		return '';
	}

	const urlOrigin = window.location.origin + '/#';
	if (mediaType === mediaTypes.trashedMedias) {
		const newPath = buildRoutePath(
			`${urlOrigin}${screenRoutes.PUBLISH_RECYCLE_BIN}/${mediaDetail.id}/detail`,
			ACCOUNT_ID_ROUTE_PLACEHOLDER,
			defaultAccountId
		);
		return newPath;
	}

	switch (mediaDetail.type) {
		case mediaTypes.catalog:
			if (isContextMenu) {
				return buildRoutePath(
					`${urlOrigin}${screenRoutes.PUBLISH_CATALOGS}/${mediaDetail.id}/detail`,
					ACCOUNT_ID_ROUTE_PLACEHOLDER,
					defaultAccountId
				);
			}
			return buildRoutePath(
				`${urlOrigin}${screenRoutes.PUBLISH_CATALOGS}/${mediaDetail.id}`,
				ACCOUNT_ID_ROUTE_PLACEHOLDER,
				defaultAccountId
			);

		case mediaTypes.scenario:
			return buildRoutePath(
				`${urlOrigin}${screenRoutes.PUBLISH_SCENARIOS}/${mediaDetail.id}/detail`,
				ACCOUNT_ID_ROUTE_PLACEHOLDER,
				defaultAccountId
			);
		case mediaTypes.playlists:
			return buildRoutePath(
				`${urlOrigin}${screenRoutes.PUBLISH_PLAYLISTS}/${mediaDetail.id}/detail`,
				ACCOUNT_ID_ROUTE_PLACEHOLDER,
				defaultAccountId
			);
		case mediaTypes.video:
		default:
			return buildRoutePath(
				`${urlOrigin}${screenRoutes.PUBLISH_MEDIA_LIBRARY}/medias/${mediaDetail.id}/detail`,
				ACCOUNT_ID_ROUTE_PLACEHOLDER,
				defaultAccountId
			);
	}
};

export const getMediaTypeText = (mediaDetail, mediaType, t) => {
	if (!mediaDetail) {
		return mediaType;
	}

	let mediaTypeText = mediaDetail.type;
	if (mediaDetail.type === mediaTypes.video) {
		mediaTypeText = t('COMMON_MEDIA_TYPE_VIDEO');
	} else if (mediaDetail.type === mediaTypes.image) {
		mediaTypeText = t('COMMON_MEDIA_TYPE_IMAGE');
	} else if (mediaDetail.type === mediaTypes.liveaudio) {
		mediaTypeText = t('COMMON_MEDIA_LIVE_AUDIO');
	} else if (mediaDetail.type === mediaTypes.livevideo) {
		mediaTypeText = t('COMMON_MEDIA_LIVE_EVENT');
	} else if (mediaDetail.type === mediaTypes.scenario) {
		mediaTypeText = t('COMMON_MEDIA_TYPE_INTERACT');
	} else if (mediaType === mediaTypes.playlists) {
		mediaTypeText = t('COMMON_MEDIA_TYPE_PLAYLIST');
	}

	return mediaTypeText;
};

export const convertSecondsToHHMMSS = (seconds) => {
	const date = new Date(0);
	date.setSeconds(seconds);
	return date.toISOString().substring(11, 19);
};

export const isMediaProcessing = (accountRestrictions, mediaRestrictions) => {
	if ((mediaRestrictions ?? []).length === 0 || (accountRestrictions ?? []).length === 0) {
		return false;
	}

	let result = false;
	for (let i = 0; i < mediaRestrictions.length; i++) {
		const restrictionId = mediaRestrictions[i];
		const userRes = accountRestrictions?.find((r) => r.id === restrictionId);
		if (restrictionId && userRes?.rules?.find((rule) => rule.type === 'Publish')) {
			result = true;
			break;
		}
	}
	return result;
};

export const buildRoutePath = (path, placeHolder, value) => {
	return path.replace(placeHolder, value);
};
