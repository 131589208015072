import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControlLabel, TextField } from '@material-ui/core';

import { updateScreenName } from '../../../../actions/breadCrumbActions';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../../../actions/publishActions';
import { showMessage } from '../../../../actions/globalActions';
import { getDetails } from '../../../../services/mediaDisplayService';
import { mediaNames, messageTypes } from '../../../../constants/mediaConstants';
import { setInspectMediaModified, setShouldSaveChange } from '../../../../actions/inspectScreenActions';

import MediaInformationSection from './MediaInformationSection';

import './MediaDetail.scss';

interface MediaDetailProps {
	mediaDetails: any;
	mediaName?: string;
	isExpandedScreen?: boolean;
	onUpdatingData?: () => void;
}

const MediaDetail = forwardRef(
	({ mediaDetails, onUpdatingData, mediaName = mediaNames.medias, isExpandedScreen }: MediaDetailProps, ref) => {
		const { t } = useTranslation();
		const dispatch = useDispatch() as any;

		const { defaultAccountId } = useSelector((state) => (state as any).session);
		const { isInspectMediaModified, shouldSaveChange } = useSelector((state: any) => state.inspectScreenReducer);

		const [mediaTitle, setMediaTitle] = useState<string>(mediaDetails?.metadata?.title ?? '');
		const [mediaDescription, setMediaDescription] = useState<string>(mediaDetails?.description ?? '');

		const onSaveChanges = useCallback(
			(callback?: () => void) => {
				if (!mediaDetails) {
					return;
				}

				let body = {
					id: mediaDetails.id,
					asset: {
						id: mediaDetails.asset.id,
					},
					metadata: {
						title: mediaTitle || '',
						description: mediaDescription || '',
					},
					tags: mediaDetails.tags || [],
					custom: mediaDetails?.custom ?? {},
				};
				body.custom = mediaDetails?.custom ?? {};
				dispatch(getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias)).then(
					(media: any) => {
						if (!media || media.message) {
							dispatch(
								showMessage(
									t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
									messageTypes.error
								)
							);
							return;
						}
						let mediaDetails = getDetails(media, mediaName === mediaNames.playlists);
						if (mediaDetails && mediaDetails.custom && mediaDetails.custom.livemanager) {
							if (mediaDetails.custom.livemanager.start) {
								body.custom['livemanager'] = {
									start: mediaDetails.custom.livemanager.start,
								};
							}
							if (body.custom.livemanager && mediaDetails.custom.livemanager.stop) {
								body.custom.livemanager.stop = mediaDetails.custom.livemanager.stop;
							}
						}
						mediaDetails &&
							dispatch(saveInspectScreenDetails(defaultAccountId, mediaDetails.id, mediaName, body)).then(
								(data: any) => {
									if (!data || data.message) {
										dispatch(
											showMessage(
												t('MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_FAILED_TO_SAVE_MEDIA_DETAILS'),
												messageTypes.error
											)
										);
										return;
									}

									callback?.();
									dispatch(
										showMessage(
											t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_RESTRICTION_SAVED_DETAILS_SUCCESSFULLY'
											),
											messageTypes.success
										)
									);
								}
							);
					}
				);
			},
			[defaultAccountId, dispatch, mediaDescription, mediaDetails, mediaName, mediaTitle, t]
		);

		const handleUpdatingData = useCallback(() => {
			onUpdatingData?.();
			dispatch(setInspectMediaModified(true));
		}, [dispatch, onUpdatingData]);

		useImperativeHandle(ref, () => ({
			onSaveChanges: onSaveChanges,
		}));

		useEffect(() => {
			setMediaTitle(mediaDetails?.metadata?.title);
			setMediaDescription(mediaDetails?.description ?? '');
		}, [mediaDetails]);

		useEffect(() => {
			if (isInspectMediaModified && shouldSaveChange) {
				onSaveChanges(() => {
					dispatch(setShouldSaveChange(false));
					dispatch(setInspectMediaModified(false));
				});
			}
		}, [isInspectMediaModified, shouldSaveChange, onSaveChanges, dispatch]);

		return (
			<>
				{mediaDetails && (
					<div className={'inspect-media__general-info-container'}>
						<Box>
							<FormControlLabel
								className={'inspect-media__input--full'}
								style={
									isExpandedScreen ? { display: 'flex', alignItems: 'baseline' } : { display: 'flex' }
								}
								control={
									<TextField
										fullWidth
										variant="outlined"
										value={mediaTitle ?? t('COMMON_NO_TITLE')}
										placeholder={t('MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_PLAYLIST_ENTER_TITLE')}
									/>
								}
								label={<span className={'inspect-media__input-label'}>{t('COMMON_TITLE')}</span>}
								onChange={(e) => {
									handleUpdatingData();
									setMediaTitle((e.target as any).value);
									dispatch(updateScreenName((e.target as any).value));
								}}
								labelPlacement={isExpandedScreen ? 'start' : 'top'}
							/>
						</Box>
						<Box>
							<FormControlLabel
								className={'inspect-media__input--full'}
								style={
									isExpandedScreen ? { display: 'flex', alignItems: 'baseline' } : { display: 'flex' }
								}
								control={
									<TextField
										variant="outlined"
										fullWidth
										value={mediaDescription}
										multiline
										maxRows={10}
										minRows={isExpandedScreen ? 6 : 3}
										placeholder={t('COMMON_ENTER_DESCRIPTION')}
									/>
								}
								label={<span className={'inspect-media__input-label'}>{t('COMMON_DESCRIPTION')}</span>}
								onChange={(e) => {
									handleUpdatingData();
									setMediaDescription((e.target as any).value);
								}}
								labelPlacement={isExpandedScreen ? 'start' : 'top'}
							/>
						</Box>
					</div>
				)}

				<MediaInformationSection mediaDetails={mediaDetails} useFlatInformationArea={isExpandedScreen} />
			</>
		);
	}
);

export default MediaDetail;
