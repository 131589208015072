import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as H from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import VideoLibraryOutlined from '@material-ui/icons/VideoLibraryOutlined';
import PlaylistPlayOutlined from '@material-ui/icons/PlaylistPlayOutlined';
import TvIcon from '@material-ui/icons/Tv';
import { ReactComponent as ContentCut } from '../../../assets/svg/ContentCut.svg';
import { ReactComponent as LiveEvent } from '../../../assets/svg/LiveEvent.svg';

import { mediaLibraryKeys } from '../../../constants/mediaConstants';
import { checkForAccessToChannels, checkPermissionGrantedFor } from '../../../services/componentReusableService';
import CatalogTree from '../../../reusable/CatalogTree/CatalogTree';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../../constants/routesPath';
import { ReactComponent as Interact } from '../../../assets/svg/InteractiveProject.svg';
import { ReactComponent as ChevronDown } from '../../../assets/svg/ChevronDown.svg';
import ColorHub, { ColorTypes } from '../../../reusable/ColorHub/ColorHub';
import { joinClassNames } from '../../../services/elementHelperService';
import { useListCustomStyle } from '../../../reusable/CustomStyle/useCustomStyle';
import { clearBreadCrumbLocationArray, updateBreadCrumbLocationArray } from '../../../actions/breadCrumbActions';
import { KEYS } from '../../../layout/header/constants';

import { HandlerType } from '../MediaLibrary/utils';
import { useGetMediaCount } from './useGetMediaCount';
import SmartFilters from './SmartFilters';

import './MediaLibrarySidebar.scss';
import RecycleBinSideBar from './RecycleBinSideBar';
import { defaultPaginationRoutePath } from '../../../constants/paginationConstant';
import { buildRoutePath } from '../../../utils/commonUtil';

const listItems = [
	{
		id: mediaLibraryKeys.allMedia,
		text: 'MEDIA_LIBRARY_SIDEBAR_CATEGORIES_ALL_MEDIA',
		icon: <VideoLibraryOutlined />,
		activeIcon: <VideoLibraryOutlined color="primary" />,
	},
	{
		id: mediaLibraryKeys.playlists,
		text: 'MEDIA_LIBRARY_SIDEBAR_CATEGORIES_ALL_PLAYLISTS',
		icon: <PlaylistPlayOutlined />,
		activeIcon: <PlaylistPlayOutlined color="primary" />,
	},
	{
		id: mediaLibraryKeys.live,
		text: 'HEADER_MENU_LABEL_LIVE',
		icon: <ColorHub component={<LiveEvent width={'18px'} height={'18px'} />} />,
		activeIcon: <ColorHub component={<LiveEvent width={'18px'} height={'18px'} />} color={'info' as ColorTypes} />,
	},
	{
		id: mediaLibraryKeys.edit,
		text: 'MEDIA_LIBRARY_SIDEBAR_CATEGORIES_ALL_EXPRESS',
		icon: <ColorHub component={<ContentCut width={'18px'} height={'18px'} />} />,
		activeIcon: <ColorHub component={<ContentCut width={'18px'} height={'18px'} />} color={'info' as ColorTypes} />,
	},
	{
		id: mediaLibraryKeys.scenarios,
		text: 'MEDIA_LIBRARY_SIDEBAR_CATEGORIES_ALL_INTERACTS',
		icon: <ColorHub component={<Interact />} />,
		activeIcon: <ColorHub component={<Interact />} color={'info' as ColorTypes} />,
	},
	{
		id: mediaLibraryKeys.portal,
		text: 'COMMON_CHANNELS',
		icon: <TvIcon />,
		activeIcon: <TvIcon color="primary" />,
	},
];

interface MediaLibrarySideBarProps {
	mediaType?: string;
	showCreateCatalogDialogBox?: (_event: React.MouseEvent<HTMLElement>) => void;
	displaySmartFilter?: () => React.ReactNode;
	clearSearch?: HandlerType | null;
	history: H.History;
	getShowCreateCatalogDialogHandler?: (_handler: () => void) => void;
	onLibraryViewChange?: (_mediaType: string, _mediaId?: string) => void;
}

const MediaLibrarySideBar: React.FC<MediaLibrarySideBarProps> = ({
	history,
	mediaType,
	clearSearch,
	getShowCreateCatalogDialogHandler,
	onLibraryViewChange: onLibraryViewChangeProps,
}) => {
	const classes = useListCustomStyle();
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { userPermissions, defaultAccountId } = useSelector((state) => (state as any).session);
	const { apps } = useSelector((state) => (state as any).apps);

	const resetSelectedCatalogHandler = useRef<HandlerType | null>(null);
	const resetSelectedSmartFilterHandler = useRef<HandlerType | null>(null);
	const [selectedMediaType, setSelectedMediaType] = useState(
		!mediaType || mediaType === 'medias' ? mediaLibraryKeys.allMedia : mediaType
	);
	const [expandedList, setExpandedList] = useState(['catalog', 'filter', 'deletedMedias']);

	const getResetSelectedCatalogHandler = (handler: HandlerType) => {
		resetSelectedCatalogHandler.current = handler;
	};

	const getResetSelectedSmartFilterHandler = (handler: HandlerType) => {
		resetSelectedSmartFilterHandler.current = handler;
	};

	const { numberOfInteract, numberOfMedias, numberOfPlaylist, numberOfEditedMedia, numberOfLiveMedia } =
		useGetMediaCount();

	const onLibraryViewChange = useCallback(
		(id: string, subId?: string) => {
			let noView = false;
			if (
				id !== selectedMediaType &&
				!(
					(id === mediaLibraryKeys.catalogs && selectedMediaType === mediaLibraryKeys.library) ||
					(id === mediaLibraryKeys.library && selectedMediaType === mediaLibraryKeys.catalogs)
				)
			) {
				clearSearch?.();
			}

			let newRoute = screenRoutes.PUBLISH_MEDIA_LIBRARY;
			switch (id) {
				case mediaLibraryKeys.library:
					newRoute = screenRoutes.PUBLISH_MEDIA_LIBRARY;
					break;
				case mediaLibraryKeys.playlists:
					newRoute = screenRoutes.PUBLISH_PLAYLISTS;
					break;
				case mediaLibraryKeys.live:
					newRoute = screenRoutes.PUBLISH_LIVE_MEDIA;
					break;
				case mediaLibraryKeys.allMedia:
					newRoute = screenRoutes.PUBLISH_ALL_MEDIA;
					break;
				case mediaLibraryKeys.scenarios:
					newRoute = screenRoutes.PUBLISH_SCENARIOS;
					break;
				case mediaLibraryKeys.catalogs:
					newRoute = subId ? screenRoutes.PUBLISH_CATALOGS + '/' + subId : screenRoutes.PUBLISH_CATALOGS;
					break;
				case mediaLibraryKeys.smartFilter:
					if (subId) {
						newRoute = screenRoutes.PUBLISH_SMART_FILTER + '/' + subId;
					}
					break;
				case mediaLibraryKeys.unsortedMedia:
					newRoute = screenRoutes.PUBLISH_UNSORTED_MEDIA;
					break;
				case mediaLibraryKeys.edit:
					newRoute = screenRoutes.PUBLISH_EDITED_MEDIA;
					break;
				case mediaLibraryKeys.recycleBin:
					newRoute = screenRoutes.PUBLISH_RECYCLE_BIN;
					break;
				case mediaLibraryKeys.portal:
					newRoute = screenRoutes.PUBLISH_PORTALS;
					break;
				default:
					newRoute = screenRoutes.PUBLISH_MEDIA_LIBRARY;
					break;
			}
			newRoute = buildRoutePath(
				`${newRoute}${defaultPaginationRoutePath}`,
				ACCOUNT_ID_ROUTE_PLACEHOLDER,
				defaultAccountId
			);
			history?.push(newRoute);
			onLibraryViewChangeProps?.(noView || id, subId);

			if (id === mediaLibraryKeys.catalogs && !subId) {
				dispatch(clearBreadCrumbLocationArray());
			}
			if (id !== mediaLibraryKeys.catalogs) {
				resetSelectedCatalogHandler.current?.();
				dispatch(updateBreadCrumbLocationArray(KEYS.publish, []));
			}
			if (id !== mediaLibraryKeys.smartFilter) {
				resetSelectedSmartFilterHandler.current?.();
			}
			setSelectedMediaType(noView || id);
		},
		[selectedMediaType, onLibraryViewChangeProps, history, clearSearch, dispatch, defaultAccountId]
	);

	const handleCatalogsChange = useCallback(
		(catalogId: string) => {
			onLibraryViewChange(mediaLibraryKeys.catalogs, catalogId);
		},
		[onLibraryViewChange]
	);

	const handleFilterChange = useCallback(
		(filterId: string) => {
			onLibraryViewChange(mediaLibraryKeys.smartFilter, filterId);
		},
		[onLibraryViewChange]
	);

	const toggleList = useCallback(
		(listName: string) => {
			setExpandedList(
				expandedList.includes(listName)
					? expandedList.filter((l) => l !== listName)
					: [...expandedList, listName]
			);
		},
		[expandedList]
	);

	const getMediaCount = useCallback(
		(id: string) => {
			const count =
				id === mediaLibraryKeys.allMedia
					? numberOfMedias
					: id === mediaLibraryKeys.playlists
					? numberOfPlaylist
					: id === mediaLibraryKeys.edit
					? numberOfEditedMedia
					: id === mediaLibraryKeys.live
					? numberOfLiveMedia
					: numberOfInteract;

			return `(${count})`;
		},
		[numberOfMedias, numberOfPlaylist, numberOfEditedMedia, numberOfLiveMedia, numberOfInteract]
	);

	const hasPermission = useCallback(
		(itemId: string) => {
			if (itemId === mediaLibraryKeys.scenarios && !checkPermissionGrantedFor(userPermissions, 'scenarios')) {
				return false;
			}
			if (itemId === mediaLibraryKeys.edit && !checkPermissionGrantedFor(userPermissions, 'adobeExpress')) {
				return false;
			}
			if (
				itemId === mediaLibraryKeys.live &&
				!(checkPermissionGrantedFor(userPermissions, 'legacyLive') || checkForAccessToChannels(userPermissions))
			) {
				return false;
			}

			if (
				itemId === mediaLibraryKeys.portal &&
				!(apps ?? []).find((app: any) => {
					return app.id === mediaLibraryKeys.portal;
				})
			) {
				return false;
			}

			return true;
		},
		[userPermissions, apps]
	);

	useEffect(() => {
		let newMediaType = '';
		const currentLocation = window.location?.href
			.replace(localStorage.getItem('defaultAccountId') ?? '', ACCOUNT_ID_ROUTE_PLACEHOLDER)
			.replace(/\/page.*/, '');
		if (
			currentLocation.includes(screenRoutes.PUBLISH_SCENARIOS) &&
			selectedMediaType !== mediaLibraryKeys.scenarios
		) {
			newMediaType = mediaLibraryKeys.scenarios;
		} else if (
			currentLocation.includes(screenRoutes.PUBLISH_ALL_MEDIA) &&
			selectedMediaType !== mediaLibraryKeys.allMedia
		) {
			newMediaType = mediaLibraryKeys.allMedia;
		} else if (
			currentLocation.includes(screenRoutes.PUBLISH_LIVE_MEDIA) &&
			selectedMediaType !== mediaLibraryKeys.live
		) {
			newMediaType = mediaLibraryKeys.live;
		} else if (
			currentLocation.includes(screenRoutes.PUBLISH_EDITED_MEDIA) &&
			selectedMediaType !== mediaLibraryKeys.edit
		) {
			newMediaType = mediaLibraryKeys.edit;
		} else if (
			window.location?.href.includes(screenRoutes.PUBLISH_PORTALS) &&
			selectedMediaType !== mediaLibraryKeys.portal
		) {
			newMediaType = mediaLibraryKeys.portal;
		}

		if (newMediaType && newMediaType !== selectedMediaType) {
			setSelectedMediaType(newMediaType);
		}
	});

	return (
		<div className="library-sidebar">
			<div className="library-sidebar__header">
				<div className="library-sidebar__sub-header">
					<span>{t('MEDIA_LIBRARY_SIDEBAR_CATEGORIES_LABEL')}</span>
				</div>
				<List>
					{listItems.map((item: any, i: number) => {
						if (!hasPermission(item.id)) {
							return null;
						}

						return (
							<ListItem
								key={i}
								button
								selected={selectedMediaType === item.id}
								classes={{ selected: classes.selected }}
								style={{
									borderRadius: '4px',
									marginBottom: '5px',
									paddingLeft: '12px',
									paddingRight: '12px',
								}}
								onClick={() => onLibraryViewChange(item.id)}
							>
								<ListItemIcon style={{ minWidth: '30px' }}>
									{selectedMediaType === item.id ? item.activeIcon : item.icon}
								</ListItemIcon>
								<ListItemText primary={t(item.text)} />
								{item.id !== mediaLibraryKeys.portal && (
									<ListItemText style={{ textAlign: 'right' }} secondary={getMediaCount(item.id)} />
								)}
							</ListItem>
						);
					})}
				</List>
				<Divider />
			</div>

			<div className="library-sidebar__trees">
				<div
					className={joinClassNames(
						'library-sidebar__sub-header',
						expandedList.includes('catalog') ? '' : 'library-sidebar__sub-header--collapsed'
					)}
				>
					<span>{t('MEDIA_LIBRARY_SIDEBAR_CATALOGS_LABEL')}</span>
					<IconButton
						title={
							expandedList.includes('catalog')
								? t('MEDIA_LIBRARY_SIDEBAR_CATALOGS_BUTTON_HIDE_TITLE')
								: t('MEDIA_LIBRARY_SIDEBAR_CATALOGS_BUTTON_SHOW_TITLE')
						}
						onClick={() => toggleList('catalog')}
						className={expandedList.includes('catalog') ? undefined : 'icon--collapsed'}
					>
						<ColorHub component={<ChevronDown />} />
					</IconButton>
				</div>
				{expandedList.includes('catalog') && (
					<CatalogTree
						handleCatalogsChange={handleCatalogsChange}
						history={history}
						isSideBar
						getShowCreateCatalogDialogHandler={getShowCreateCatalogDialogHandler}
						getResetSelectedCatalogHandler={getResetSelectedCatalogHandler}
					/>
				)}

				<Divider style={{ marginRight: '12px' }} />
				<div
					className={joinClassNames(
						'library-sidebar__sub-header',
						expandedList.includes('filter') ? '' : 'library-sidebar__sub-header--collapsed'
					)}
				>
					<span>{t('MEDIA_LIBRARY_SIDEBAR_SEARCH_FILTERS_LABEL')}</span>
					<IconButton
						title={
							expandedList.includes('filter')
								? t('MEDIA_LIBRARY_SIDEBAR_SEARCH_FILTERS_BUTTON_HIDE_TITLE')
								: t('MEDIA_LIBRARY_SIDEBAR_SEARCH_FILTERS_BUTTON_SHOW_TITLE')
						}
						onClick={() => toggleList('filter')}
						className={expandedList.includes('filter') ? undefined : 'icon--collapsed'}
					>
						<ColorHub component={<ChevronDown />} />
					</IconButton>
				</div>
				{expandedList.includes('filter') && (
					<SmartFilters
						handleFilterChange={handleFilterChange}
						history={history}
						getResetSelectedSmartFilterHandler={getResetSelectedSmartFilterHandler}
					/>
				)}

				<Divider style={{ marginRight: '12px' }} />
				<div
					className={joinClassNames(
						'library-sidebar__sub-header',
						expandedList.includes('deletedMedias') ? '' : 'library-sidebar__sub-header--collapsed'
					)}
				>
					<span>{t('MEDIA_LIBRARY_SIDEBAR_RECYCLE_BIN_LABEL')}</span>
					<IconButton
						title={
							expandedList.includes('deletedMedias')
								? t('MEDIA_LIBRARY_SIDEBAR_RECYCLE_BIN_BUTTON_HIDE_TITLE')
								: t('MEDIA_LIBRARY_SIDEBAR_RECYCLE_BIN_BUTTON_Show_TITLE')
						}
						onClick={() => toggleList('deletedMedias')}
						className={expandedList.includes('deletedMedias') ? undefined : 'icon--collapsed'}
					>
						<ColorHub component={<ChevronDown />} />
					</IconButton>
				</div>
				{expandedList.includes('deletedMedias') && (
					<RecycleBinSideBar
						handleLibraryViewChange={() => {
							onLibraryViewChange(mediaLibraryKeys.recycleBin);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default MediaLibrarySideBar;
