import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Add from '@material-ui/icons/Add';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { EditOutlined } from '@material-ui/icons';
import { getLastIndexCatalogId } from '../../services/locationPathServices';
import { joinClassNames } from '../../services/elementHelperService';
import { ReactComponent as CatalogIcon } from '../../assets/svg/Catalog.svg';
import { ReactComponent as RestrictedCatalogIcon } from '../../assets/svg/Restricted_catalog.svg';
import { ReactComponent as ChevronDown } from '../../assets/svg/ChevronDown.svg';
import { ReactComponent as AllCatalogIcon } from '../../assets/svg/All_catalog.svg';
import ColorHub, { ColorTypes } from '../ColorHub/ColorHub';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../constants/routesPath';
import { NOCATALOG, mediaNames, messageTypes } from '../../constants/mediaConstants';
import { deleteSelectedMediaItem, refreshCatalogs, setSelectedCatalogId } from '../../actions/publishActions';
import { sendMediaDeleted } from '../../actions/inspectScreenActions';
import { showMessage } from '../../actions/globalActions';
import { clearBreadCrumbLocationArray, updateBreadCrumbLocationArray } from '../../actions/breadCrumbActions';
import { KEYS } from '../../layout/nav/navigationItems';
import { buildRoutePath, searchTree, searchTreePath } from '../../utils/commonUtil';
import { useListCustomStyle } from '../CustomStyle/useCustomStyle';
import ConfirmationDialog from '../ConfirmationDialog';

import { useFetchCatalogData } from './useFetchCatalogData';
import CreateNewCatalogDialog from './CreateNewCatalogDialog';
import './CatalogTree.scss';
import EditCatalogDialog from '../../screens/PublishScreen/InspectScreen/InspectSections/EditCatalogDialog';
import {
	buildHashedCatalogAccessMap,
	findChildCatalog,
	findParentsCatalog,
	notAllowToShowEditCatalogAccess,
} from '../MediaLibrary/utils';
import { Button, Checkbox, Tooltip, Typography } from '@material-ui/core';
import { ACCESS_LEVEL } from './CatalogLimitedAccess';
import { defaultPaginationRoutePath } from '../../constants/paginationConstant';

const CatalogActions = [
	{
		id: 'inspect-catalog',
		text: 'CATALOG_TREE_ACTION_INSPECT_CATALOG_LABEL',
		icon: <VisibilityOutlined />,
	},
	{
		id: 'edit-access-catalog',
		text: 'CATALOG_TREE_ACTION_EDIT_ACCESS_CATALOG_LABEL',
		icon: <EditOutlined />,
	},
	{
		id: 'delete-catalog',
		text: 'CATALOG_TREE_ACTION_DELETE_CATALOG_LABEL',
		icon: <DeleteOutlineOutlined />,
	},
];

interface CatalogTreeProps {
	triggerCreateCatalogDialog?: boolean;
	preselectedCatalog?: any;
	showNoCatalogOption?: boolean;
	isSideBar?: boolean;
	history?: any;
	hiddenAllCatalogsOpt?: boolean;
	showCheckbox?: boolean;
	showDropdown?: boolean;
	dropdownMenuItem?: any;
	editingUser?: any;
	handleCatalogsChange?: (_: string) => void;
	getShowCreateCatalogDialogHandler?: (_: () => void) => void;
	getResetSelectedCatalogHandler?: (_: () => void) => void;
	setInfoMessage?: (_: any) => void;
}

const CatalogTree: React.FC<CatalogTreeProps> = forwardRef(
	(
		{
			handleCatalogsChange,
			history,
			showNoCatalogOption,
			preselectedCatalog,
			isSideBar,
			hiddenAllCatalogsOpt,
			showCheckbox,
			showDropdown,
			dropdownMenuItem,
			editingUser,
			getShowCreateCatalogDialogHandler,
			getResetSelectedCatalogHandler,
			setInfoMessage,
		},
		ref
	) => {
		const classes = useListCustomStyle();
		const { t } = useTranslation();
		const dispatch = useDispatch() as any;
		const { defaultAccountId, userId, userPermissions } = useSelector((state) => (state as any).session);
		const { selectedCatalogId } = useSelector((state) => (state as any).publish);

		const catalogWrapperRef = useRef<HTMLElement | null>(null);
		const componentFirstMounted = useRef(true);

		const [expandedNodes, setExpandedNodes] = useState<any[]>([]);
		const [selectedCatalog, setSelectedCatalog] = useState(
			preselectedCatalog ? preselectedCatalog : getLastIndexCatalogId()
		);
		const [processingCatalog, setProcessingCatalog] = useState<any | undefined>(undefined);
		const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);
		const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
		const [showCreateCatalogDialog, setShowCreateCatalogDialog] = useState(false);
		const [showEditAccessCatalogDialog, setShowEditAccessCatalogDialog] = useState(false);
		const [dropdownMenuAnchor, setDropdownMenuAnchor] = useState(null);

		const [flattenCatalogsEditing, setFlattenCatalogsEditing] = useState<any>([]);
		const [catalogHashed, setCatalogHashed] = useState<any>({});
		const [catalogIdsNeedToUpdate, setCatalogIdsNeedToUpdate] = useState<any>(new Set());
		const [catalogExposeToEveryone, setCatalogExposeToEveryone] = useState<any[]>([]);

		const { catalogs, flattenCatalogs } = useFetchCatalogData({ showNoCatalogOption });

		useImperativeHandle(ref, () => {
			return {
				getCatalogsAcessNeedToUpdate: () => {
					const result = Array.from(catalogIdsNeedToUpdate).reduce((all: any, cur: any) => {
						const catalog = flattenCatalogsEditing.find(({ id }: any) => {
							return id === cur;
						});

						if (!catalog) {
							return all;
						}

						const { access } = catalog;

						return [...all, { id: cur, access }];
					}, []);
					return result;
				},
				resetCatalogUpdateList: () => {
					setCatalogIdsNeedToUpdate(new Set());
				},
			};
		});

		const handleSetSelectedCatalog = useCallback(
			(catalogId: string | undefined) => {
				if (isSideBar) {
					dispatch(setSelectedCatalogId(catalogId));
				} else {
					setSelectedCatalog(catalogId);
				}
			},
			[dispatch, isSideBar]
		);

		const toggleCatalog = useCallback(
			(e: React.MouseEvent<HTMLElement>, nodeId: string) => {
				e.stopPropagation();
				e.preventDefault();
				if (expandedNodes.includes(nodeId)) {
					setExpandedNodes(expandedNodes.filter((n) => n !== nodeId));
				} else {
					setExpandedNodes([...expandedNodes, nodeId]);
				}
			},
			[expandedNodes]
		);

		const onContextMenu = useCallback(
			(e: React.MouseEvent<HTMLElement>, catalog: any) => {
				e.preventDefault();
				e.stopPropagation();

				if (notAllowToShowEditCatalogAccess(catalog, flattenCatalogs, userId, userPermissions)) {
					return;
				}
				setContextMenuAnchor(e.currentTarget);
				setProcessingCatalog(catalog);
			},
			[userId, userPermissions, flattenCatalogs]
		);

		const openInspectPage = useCallback(() => {
			if (!processingCatalog || !processingCatalog.id) {
				return;
			}
			if (history) {
				history.push(
					buildRoutePath(
						screenRoutes.PUBLISH_MEDIA_LIBRARY +
							'/' +
							mediaNames.catalogs +
							'/' +
							processingCatalog.id +
							'/detail',
						ACCOUNT_ID_ROUTE_PLACEHOLDER,
						defaultAccountId
					)
				);
			}
		}, [history, processingCatalog]);

		const onContextMenuClick = useCallback(
			(e: React.MouseEvent<HTMLElement>, action: string) => {
				e.stopPropagation();
				if (action === 'delete-catalog') {
					if (processingCatalog.includedChildren > 0) {
						dispatch(
							showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_CATALOG_WITH_MEDIAS_CANNOT_DELETED'),
								messageTypes.error
							)
						);
					} else if (processingCatalog.children.length > 0) {
						dispatch(
							showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_CATALOG_WITH_SUBCATALOG_CANNOT_DELETED'),
								messageTypes.error
							)
						);
					} else {
						setShowDeleteConfirmationDialog(true);
					}
				}
				if (action === 'inspect-catalog') {
					openInspectPage();
				}

				if (action === 'edit-access-catalog') {
					setShowEditAccessCatalogDialog(true);
				}
				setContextMenuAnchor(null);
			},
			[dispatch, openInspectPage, processingCatalog?.children?.length, processingCatalog?.includedChildren, t]
		);

		const handleDeleteCatalog = useCallback(
			(result: { ok: boolean }) => {
				const deletingCatalog = processingCatalog?.id;

				setShowDeleteConfirmationDialog(false);
				setProcessingCatalog(undefined);

				if (result.ok && deletingCatalog) {
					dispatch(deleteSelectedMediaItem(deletingCatalog, defaultAccountId, mediaNames.catalogs, ''))
						.then((data: any) => {
							dispatch(refreshCatalogs());
							if (!data || data.message) {
								dispatch(showMessage(t('COMMON_DELETION_FAILED'), messageTypes.error));
								return;
							}

							setTimeout(() => {
								dispatch(refreshCatalogs());

								dispatch(showMessage(t('COMMON_DELETED_SUCCESSFULLY'), messageTypes.info));
								dispatch(sendMediaDeleted(deletingCatalog));

								if (deletingCatalog === selectedCatalog) {
									handleCatalogsChange?.('');
									setSelectedCatalog('allCatalog');
								} else {
									handleCatalogsChange?.('');
									handleCatalogsChange?.(selectedCatalog);
								}
							}, 3000);
						})
						.catch((error: any) => {
							dispatch(showMessage(error?.message ?? error, messageTypes.error));
						});
				}
			},
			[processingCatalog?.id, dispatch, defaultAccountId, t, selectedCatalog, handleCatalogsChange]
		);

		const onCreateNewCatalog = useCallback(
			(catalogId: string) => {
				setTimeout(() => {
					handleSetSelectedCatalog(catalogId);
					handleCatalogsChange?.('');
					handleCatalogsChange?.(catalogId);
					dispatch(refreshCatalogs());
				}, 3000);
			},
			[dispatch, handleCatalogsChange, handleSetSelectedCatalog]
		);

		const updateBreadCrumb = useCallback(
			(catalog: any) => {
				if (!catalog) {
					return;
				}

				if (catalog.id === 'allCatalog') {
					localStorage.setItem('locationArray', '');
					dispatch(clearBreadCrumbLocationArray());
					return;
				}

				const newLocationArray: { text: string; href: string; level: number }[] = [];
				const currentNodeHref = `${screenRoutes.PUBLISH_CATALOGS}/${selectedCatalog}`;
				newLocationArray.push({
					text: catalog?.metadata?.title,
					href: `${currentNodeHref}${defaultPaginationRoutePath}`,
					level: 1000,
				});

				const newExpandedNodes: string[] = [];
				if (catalog.parentId) {
					let paths: any[] = [];
					catalogs.forEach((catalog) => {
						paths = searchTreePath(catalog, selectedCatalog);
						if (paths && paths.length > 0) {
							paths.forEach((path: any[]) => {
								path.forEach((node: any, index: number) => {
									newExpandedNodes.push(node.id);
									const parentHref = `${screenRoutes.PUBLISH_CATALOGS}/${node.id}`;
									newLocationArray.push({
										text: node?.metadata?.title ?? '',
										href: `${parentHref}${defaultPaginationRoutePath}`,
										level: index,
									});
								});
							});
							return;
						}
					});
				}

				if (newExpandedNodes.join(',') !== expandedNodes.join(',') && componentFirstMounted.current) {
					setExpandedNodes(newExpandedNodes);
				}

				componentFirstMounted.current = false;

				if (isSideBar) {
					dispatch(
						updateBreadCrumbLocationArray(
							KEYS.publish,
							newLocationArray.sort((a: any, b: any) => (a.level >= b.level ? 1 : -1))
						)
					);
				}
			},
			[selectedCatalog, expandedNodes, dispatch, catalogs, isSideBar]
		);

		const onSelectCatalog = useCallback(
			(catalog: any) => {
				const { id } = catalog;

				if (!expandedNodes.includes(id)) {
					setExpandedNodes([...expandedNodes, id]);
				}

				updateBreadCrumb(catalog);

				handleSetSelectedCatalog(id);
				handleCatalogsChange?.(id !== 'allCatalog' ? id : '');
			},
			[expandedNodes, updateBreadCrumb, handleCatalogsChange, handleSetSelectedCatalog]
		);

		const cleanUpAccessAfterUpdate = (updatedCatalogs: any) => {
			//verify and remove override for catalog that has access same as parent
			const hashedCatalogAfterUpdate = updatedCatalogs.reduce((all: any, catalog: any) => {
				return {
					...all,
					[catalog.id]: catalog,
				};
			}, {});

			let catalogIdUpdated: any[] = [];

			const cleanedCatalog = updatedCatalogs.map((catalog: any) => {
				const parentCatalog = hashedCatalogAfterUpdate[catalog.parentId ?? ''];

				if (!parentCatalog) {
					return catalog;
				}

				const { access: parentAccess } = parentCatalog;
				const { access: childAccess } = catalog;

				// //If similar then let the sub-catalog inheritance from parent
				if (_.isEqual(parentAccess, childAccess) && Object.keys(childAccess).length !== 0) {
					catalogIdUpdated.push(catalog.id);
					return {
						...catalog,
						access: {},
					};
				}

				return catalog;
			});

			return { updatedId: catalogIdUpdated, catalogs: cleanedCatalog };
		};

		const getNewCatalogAccess = ({
			access,
			editingUserId,
			flattenCatalogsEditing,
			accessLevel,
			catalogId,
		}: any) => {
			const newAccessLevel =
				accessLevel === undefined ? accessLevel : accessLevel === ACCESS_LEVEL.FULL.type ? 'admin' : 'user';

			let newAccessObj = { ...access, [editingUserId]: newAccessLevel, [userId]: 'admin' };
			if (!newAccessLevel) {
				delete newAccessObj[editingUserId];
			}

			if (!newAccessLevel) {
				// case remove access -> just remove access itself
				return {
					catalogNeedToUpdate: [catalogId],
					catalogAccesses: flattenCatalogsEditing.map((catalog: any) => {
						if (catalog.id !== catalogId) {
							return catalog;
						}

						return {
							...catalog,
							access: newAccessObj,
						};
					}),
				};
			} else {
				// case add access
				const parentCatalogs = findParentsCatalog(flattenCatalogsEditing, catalogId);
				// check parent access
				const catalogAccessNeedToBeUpdated = parentCatalogs.reduce((all, id) => {
					const catalog = catalogHashed[id];
					const { access } = catalog;

					if (!catalog || !access || Object.keys(access).length === 0) {
						return all;
					}

					return {
						...all,
						[id]: { ...access, ...newAccessObj },
					};
				}, {});
				//update parent access
				const updatedCatalogs = flattenCatalogsEditing.map((catalog: any) => {
					const catalogToCheck = catalogAccessNeedToBeUpdated[catalog.id];

					if (!catalogToCheck) {
						return catalog;
					}

					return {
						...catalog,
						access: catalogToCheck,
					};
				});

				const { updatedId, catalogs } = cleanUpAccessAfterUpdate(updatedCatalogs);

				return {
					catalogNeedToUpdate: Array.from(
						new Set([...Object.keys(catalogAccessNeedToBeUpdated), ...updatedId])
					),
					catalogAccesses: catalogs,
				};
			}
		};

		const updateEditingCatalogAccess = ({
			catalogId,
			accessLevel,
			isFromCheckBox = false,
			indeterminate = false,
		}: {
			catalogId: any;
			accessLevel?: any;
			isFromCheckBox?: boolean;
			indeterminate?: boolean;
		}) => {
			const { userId: editingUserId } = editingUser;

			if (editingUserId === userId) {
				return;
			}

			let newCatalogsAccess = flattenCatalogsEditing;
			let catalogIdNeedToUpdate: any[] = [];

			if (isFromCheckBox) {
				const editingCatalog = catalogHashed[catalogId];

				if (!editingCatalog) {
					return;
				}
				const { access, isInheritedCatalogAccess } = editingCatalog;

				//case restore all child
				if (indeterminate) {
					const childCatalogIds = (findChildCatalog(flattenCatalogsEditing, catalogId) ?? []).flat(Infinity);
					newCatalogsAccess = flattenCatalogsEditing.map((catalog: any) => {
						const { id, access, parentId } = catalog;

						if (!childCatalogIds.includes(id) || Object.keys(access).length === 0) {
							return catalog;
						}

						let newAccessObj = {
							...access,
							[editingUserId]: access[editingUserId] || 'user',
						};

						const parentAccess = catalogHashed[parentId]?.access;

						newAccessObj = parentAccess
							? _.isEqual(parentAccess, newAccessObj)
								? {}
								: { ...newAccessObj, [userId]: 'admin' }
							: { ...newAccessObj, [userId]: 'admin' };

						catalogIdNeedToUpdate.push(id);
						return { ...catalog, access: newAccessObj };
					});
				}

				//normal case

				//override -> just update access of editing catalog
				else if (!isInheritedCatalogAccess) {
					const { catalogAccesses, catalogNeedToUpdate } = getNewCatalogAccess({
						access,
						editingUserId,
						flattenCatalogsEditing,
						accessLevel,
						catalogId,
					});
					newCatalogsAccess = catalogAccesses;
					catalogIdNeedToUpdate = catalogNeedToUpdate;
				} else {
					//inherit
					// find the parent catalog

					const parentCatalogWidthValidAccessId = catalogHashed[isInheritedCatalogAccess];

					// cover for case missing parentCatalogWidthValidAccessId not exist for some reason (bug?) -> update on the editing catalog
					if (!parentCatalogWidthValidAccessId) {
						const { catalogAccesses, catalogNeedToUpdate } = getNewCatalogAccess({
							access,
							editingUserId,
							flattenCatalogsEditing,
							accessLevel,
							catalogId,
						});
						newCatalogsAccess = catalogAccesses;
						catalogIdNeedToUpdate = catalogNeedToUpdate;
					} else {
						//got parent catalog,
						const newAccessLevel =
							accessLevel === undefined
								? accessLevel
								: accessLevel === ACCESS_LEVEL.FULL.type
								? 'admin'
								: 'user';

						//newAccessLevel === undefine -> remove action
						if (!newAccessLevel) {
							const { access, title } = parentCatalogWidthValidAccessId;

							//in case parent access only have one user, and that user is the one to be removed
							if (Object.keys(access).length === 1 && access[editingUserId]) {
								// remove the user from parent catalog
								newCatalogsAccess = flattenCatalogsEditing.map((catalog: any) => {
									const { id } = catalog;

									if (id !== isInheritedCatalogAccess) {
										return catalog;
									}

									return { ...catalog, access: {} };
								});
								catalogIdNeedToUpdate = [isInheritedCatalogAccess];
								const exposedCatalog = [...catalogExposeToEveryone, title];
								setCatalogExposeToEveryone(exposedCatalog);
								setInfoMessage?.((exposedCatalog ?? []).slice(0, 5).join(', '));
							} else {
								// override the current editing catalog
								newCatalogsAccess = flattenCatalogsEditing.map((catalog: any) => {
									const { id } = catalog;

									if (id !== catalogId) {
										return catalog;
									}

									const newAccessObj = { ...parentCatalogWidthValidAccessId.access };
									delete newAccessObj[editingUserId];

									return { ...catalog, access: newAccessObj };
								});
								catalogIdNeedToUpdate = [catalogId];
							}
						} else {
							//newAccessLevel !== undefine -> add -> find the parent and add user to it
							newCatalogsAccess = flattenCatalogsEditing.map((catalog: any) => {
								const { id } = catalog;

								if (id !== isInheritedCatalogAccess) {
									return catalog;
								}

								const newAccessObj = {
									...parentCatalogWidthValidAccessId.access,
									[editingUserId]: newAccessLevel,
									[userId]: 'admin',
								};
								return { ...catalog, access: newAccessObj };
							});
							catalogIdNeedToUpdate = [isInheritedCatalogAccess];
						}
					}
				}

				setFlattenCatalogsEditing(newCatalogsAccess);
				setCatalogIdsNeedToUpdate(new Set([...Array.from(catalogIdsNeedToUpdate), ...catalogIdNeedToUpdate]));
				return;
			}

			newCatalogsAccess = flattenCatalogsEditing.map((catalog: any) => {
				const { id, access } = catalog;

				if (id !== catalogId) {
					return catalog;
				}

				const newAccessLevel = accessLevel === 'FULL' ? 'admin' : 'user';

				const newAccess = { ...access, [editingUserId]: newAccessLevel };

				return { ...catalog, access: newAccess };
			});
			setCatalogIdsNeedToUpdate(new Set([...Array.from(catalogIdsNeedToUpdate), catalogId]));
			setFlattenCatalogsEditing(newCatalogsAccess);
		};

		useEffect(() => {
			if (preselectedCatalog === undefined) {
				return;
			}

			if (preselectedCatalog === '' && showNoCatalogOption) {
				setSelectedCatalog(NOCATALOG);
			}
		}, [preselectedCatalog, showNoCatalogOption]);

		useEffect(() => {
			const selectedId = preselectedCatalog ? preselectedCatalog : getLastIndexCatalogId();
			if (
				isSideBar &&
				(selectedId === 'allCatalog' || selectedCatalogId === '' || !window.location.hash.includes('/catalogs'))
			) {
				localStorage.setItem('locationArray', '');
				dispatch(updateBreadCrumbLocationArray(KEYS.publish, []));
				return;
			}

			let catalog: any | undefined = undefined;
			catalogs.forEach((c) => {
				if (catalog) {
					return;
				}
				catalog = searchTree(c, selectedId);
			});

			if (!catalog) {
				return;
			}
			updateBreadCrumb(catalog);
		}, [catalogs, dispatch, isSideBar, preselectedCatalog, selectedCatalogId, updateBreadCrumb]);

		useEffect(() => {
			setFlattenCatalogsEditing(flattenCatalogs);
		}, [flattenCatalogs]);

		useEffect(() => {
			const { userId: editingUserId } = editingUser ?? {};
			const hashedCatalog = buildHashedCatalogAccessMap(flattenCatalogsEditing, editingUserId);
			setCatalogHashed(hashedCatalog);
		}, [flattenCatalogsEditing, editingUser]);

		const renderCatalogItem = (catalog: any, nestedLvl?: number): React.ReactNode => {
			const { userId: editingUserId } = editingUser ?? {};

			const hasChildren = catalog.children && catalog.children.length > 0;
			const isExpanded = expandedNodes.includes(catalog.id);

			const userCatalogAccessObject = catalogHashed[catalog.id]?.access;
			const userAccessLevel = userCatalogAccessObject && userCatalogAccessObject[editingUserId];

			const isAllowAllAccess = Object.keys(userCatalogAccessObject ?? {}).length === 0;
			const isInheritedCatalogAccess = catalogHashed[catalog.id]?.isInheritedCatalogAccess;
			const isNotAllowAccessAllChild = catalogHashed[catalog.id]?.isNotAllowAccessAllChild;

			const isAbleToCreateNewCatalog = isAllowAllAccess || userCatalogAccessObject?.[userId] === 'admin';

			return (
				<React.Fragment key={catalog.id}>
					<ListItem
						id={'item-' + catalog.id}
						key={'item-' + catalog.id}
						button
						selected={selectedCatalog === catalog.id}
						classes={{ selected: classes.selected }}
						className={joinClassNames(
							nestedLvl ? (classes as any)[`nested${nestedLvl >= 5 ? 5 : nestedLvl}`] : undefined,
							processingCatalog?.id === catalog.id ? classes.activeItem : '',
							classes.customItem
						)}
						onClick={() => onSelectCatalog(catalog)}
						onContextMenu={isSideBar ? (e) => onContextMenu(e, catalog) : undefined}
					>
						{showCheckbox && (
							<Tooltip
								placement="top"
								title={
									isAllowAllAccess ? (
										<Typography variant="body1" color="inherit">
											{t('CATALOG_TREE_LIMIT_ACCESS_CATALOG_RESTRICT_FROM_MEDIA_LIBRARY')}
										</Typography>
									) : (
										''
									)
								}
							>
								<span>
									<Checkbox
										color="primary"
										onClick={(e) => {
											e.stopPropagation();
											updateEditingCatalogAccess({
												catalogId: catalog.id,
												accessLevel: !!userAccessLevel ? undefined : ACCESS_LEVEL.LIMITED.type,
												isFromCheckBox: true,
												indeterminate: Boolean(
													!isAllowAllAccess && isNotAllowAccessAllChild && userAccessLevel
												),
											});
										}}
										size="small"
										disabled={isAllowAllAccess}
										checked={userAccessLevel || isAllowAllAccess}
										indeterminate={Boolean(
											!isAllowAllAccess && isNotAllowAccessAllChild && userAccessLevel
										)}
									/>
								</span>
							</Tooltip>
						)}
						<ListItemIcon style={{ minWidth: '30px' }}>
							<ColorHub
								component={
									isAllowAllAccess || isInheritedCatalogAccess ? (
										<CatalogIcon />
									) : (
										<RestrictedCatalogIcon />
									)
								}
								color={selectedCatalog === catalog.id ? ('info' as ColorTypes) : undefined}
							/>
						</ListItemIcon>
						<span className={classes.ellipsis} title={t(catalog.metadata?.title)}>
							{t(catalog.metadata?.title)}
						</span>
						{showDropdown && userAccessLevel && (
							<div className={`catalog-dropdown-options ${!hasChildren ? 'no-children' : ''}`}>
								<Button
									size="small"
									variant="outlined"
									data-catalogId={catalog.id}
									fullWidth
									onClick={(e: any) => {
										e.stopPropagation();
										setDropdownMenuAnchor(e.currentTarget);
									}}
								>
									{userAccessLevel === 'admin'
										? t(ACCESS_LEVEL.FULL.label)
										: t(ACCESS_LEVEL.LIMITED.label)}
									{<ExpandMoreIcon />}
								</Button>
							</div>
						)}
						{hasChildren && (
							<IconButton
								onClick={(e) => toggleCatalog(e, catalog.id)}
								className={isExpanded ? undefined : 'icon--collapsed'}
								title={isExpanded ? t('COMMON_COLLAPSE') : t('COMMON_EXPAND')}
							>
								<ColorHub component={<ChevronDown />} />
							</IconButton>
						)}
					</ListItem>
					{hasChildren && (
						<Collapse in={isExpanded} timeout="auto" unmountOnExit>
							{nestedLvl === undefined && (
								<div className={joinClassNames('catalogTree--indicator', classes.indicator)} />
							)}
							{_.sortBy(catalog.children, 'title').map((node: any) =>
								renderCatalogItem(node, (nestedLvl === undefined ? 0 : nestedLvl) + 1)
							)}
						</Collapse>
					)}
					{selectedCatalog === catalog.id &&
						isAbleToCreateNewCatalog &&
						renderNewCatalogBtn((nestedLvl === undefined ? 0 : nestedLvl) + 1)}
				</React.Fragment>
			);
		};

		const renderNewCatalogBtn = (nestedLvl?: number) =>
			isSideBar && (
				<ListItem
					button
					key={'newCatalog'}
					className={joinClassNames(
						nestedLvl ? (classes as any)[`nested${nestedLvl >= 5 ? 5 : nestedLvl}`] : undefined,
						classes.customItem,
						nestedLvl === undefined ? classes.firstLevelItem : ''
					)}
					onClick={() => {
						setShowCreateCatalogDialog(true);
						if (!nestedLvl) {
							setProcessingCatalog({ id: 'allCatalog' });
						}
					}}
				>
					<ListItemIcon style={{ minWidth: '30px' }}>
						<Add color="primary" />
					</ListItemIcon>
					<span
						title={t('CATALOG_TREE_NEW_CATALOG_LABEL')}
						className={classes.ellipsis}
						style={{ color: '#126cfc' }}
					>
						{t('CATALOG_TREE_NEW_CATALOG_LABEL')}
					</span>
				</ListItem>
			);

		useEffect(() => {
			getShowCreateCatalogDialogHandler?.(() => setShowCreateCatalogDialog(true));
			getResetSelectedCatalogHandler?.(() => handleSetSelectedCatalog(undefined));
		}, [getShowCreateCatalogDialogHandler, getResetSelectedCatalogHandler, handleSetSelectedCatalog]);

		useEffect(() => {
			setSelectedCatalog(selectedCatalogId);
		}, [selectedCatalogId]);

		useLayoutEffect(() => {
			setTimeout(() => {
				if (!catalogWrapperRef || !catalogWrapperRef.current) {
					return;
				}

				let selectedCatalogRef = catalogWrapperRef.current.querySelector(`#item-${selectedCatalog}`);
				if (selectedCatalog === '' || selectedCatalog === 'allCatalog') {
					selectedCatalogRef = catalogWrapperRef.current.querySelector(`#allCatalog`);
				}
				if (selectedCatalogRef) {
					selectedCatalogRef.scrollIntoView({
						block: 'nearest',
						inline: 'nearest',
					});
				}
			}, 1000);
		}, [selectedCatalog]);

		const onload = useCallback(() => {
			if (window.location.hash.includes(`#${screenRoutes.PUBLISH_CATALOGS}`)) {
				const catalogID = getLastIndexCatalogId();
				dispatch(setSelectedCatalogId(catalogID));
			}
		}, [dispatch]);

		useLayoutEffect(() => {
			window.addEventListener('load', onload);
			return () => window.removeEventListener('load', onload);
		}, [onload]);

		return (
			<React.Fragment key={isSideBar ? 'sidebar' : 'noSidebar'}>
				<List ref={(ref) => (catalogWrapperRef.current = ref)} className="q-catalogTree">
					{isSideBar && !hiddenAllCatalogsOpt && (
						<ListItem
							id={'allCatalog'}
							key={'allCatalog'}
							button
							selected={selectedCatalog === 'allCatalog' || selectedCatalog === ''}
							classes={{ selected: classes.selected }}
							className={classes.allCatalogItem}
							onClick={() => onSelectCatalog({ id: 'allCatalog' })}
						>
							<ListItemIcon style={{ minWidth: '30px' }}>
								<ColorHub
									component={<AllCatalogIcon />}
									color={selectedCatalog === 'allCatalog' ? ('info' as ColorTypes) : undefined}
								/>
							</ListItemIcon>
							<ListItemText primary={t('CATALOG_TREE_ALL_CATALOGS_LABEL')} />
						</ListItem>
					)}
					{catalogs.length > 0 && catalogs.map((catalog: any) => renderCatalogItem(catalog, undefined))}
				</List>
				{renderNewCatalogBtn(undefined)}

				<Menu
					getContentAnchorEl={null}
					anchorEl={contextMenuAnchor}
					onClose={() => {
						setContextMenuAnchor(null);
						setProcessingCatalog(undefined);
					}}
					open={Boolean(contextMenuAnchor)}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					{CatalogActions.map((action: any) => {
						return (
							<MenuItem
								className="listMenuItem"
								key={action.id}
								value={action.id}
								onMouseDown={(e) => onContextMenuClick(e, action.id)}
							>
								<ListItemIcon style={{ minWidth: '30px' }}>{action.icon}</ListItemIcon>
								<ListItemText primary={t(action.text)} />
							</MenuItem>
						);
					})}
				</Menu>

				{showDeleteConfirmationDialog && processingCatalog && (
					<ConfirmationDialog
						open={showDeleteConfirmationDialog}
						title={t('CATALOG_TREE_DELETE_CATALOG_MODAL_SINGLE_CATALOG')}
						message={t('COMMON_DELETE_CONFIRMATION_SINGLE')}
						mediaTitle={processingCatalog?.title}
						callback={handleDeleteCatalog}
						okButtonText={t('COMMON_DELETE')}
					/>
				)}

				<CreateNewCatalogDialog
					open={showCreateCatalogDialog}
					onClose={() => {
						setShowCreateCatalogDialog(false);
						setProcessingCatalog(undefined);
					}}
					onCreateNewCatalog={onCreateNewCatalog}
					parentCatalogId={processingCatalog?.id}
				/>

				<EditCatalogDialog
					open={showEditAccessCatalogDialog}
					onClose={() => {
						setShowEditAccessCatalogDialog(false);
					}}
					editingCatalog={processingCatalog}
				/>

				{dropdownMenuItem && (
					<Menu
						anchorEl={dropdownMenuAnchor}
						open={Boolean(dropdownMenuAnchor)}
						onClose={() => {
							setDropdownMenuAnchor(null);
						}}
					>
						{Object.values(dropdownMenuItem).map(({ type, label, description }: any) => (
							<MenuItem
								key={type}
								onClick={(e) => {
									e.stopPropagation();
									if (!dropdownMenuAnchor) {
										return;
									}
									const catalogId = (dropdownMenuAnchor as any).dataset.catalogid;
									updateEditingCatalogAccess({ catalogId, accessLevel: type });
									setDropdownMenuAnchor(null);
								}}
								style={{ flexDirection: 'column', alignItems: 'start' }}
							>
								<Typography variant="body1">{t(label)}</Typography>
								<Typography variant="body2" color="textSecondary">
									{t(description)}
								</Typography>
							</MenuItem>
						))}
					</Menu>
				)}
			</React.Fragment>
		);
	}
);

export default CatalogTree;
