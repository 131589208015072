export const ACCOUNT_ID_ROUTE_PLACEHOLDER = ':accountId';

export const ACCOUNT_ID_ROUTE_PREFIX = `/accounts/${ACCOUNT_ID_ROUTE_PLACEHOLDER}`;

export const screenRoutes = {
	LOGIN: '/login',
	SETTINGS: `${ACCOUNT_ID_ROUTE_PREFIX}/settings`,
	DASHBOARD: `${ACCOUNT_ID_ROUTE_PREFIX}/dashboard`,
	ANALYTICS: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/videoperformance`,
	CREATE: `${ACCOUNT_ID_ROUTE_PREFIX}/create`,
	LIVE: `${ACCOUNT_ID_ROUTE_PREFIX}/live`,
	PUBLISH_MEDIA_LIBRARY: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary`,
	PUBLISH: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary`, //same as redirecting to media library
	PUBLISH_PLAYLISTS: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/playlists`,
	PUBLISH_SCENARIOS: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/interact`,
	PUBLISH_CATALOGS: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/catalogs`,
	PUBLISH_ALL_MEDIA: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/allmedia`,
	PUBLISH_MEDIAS: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/medias`,
	PUBLISH_LIVE_MEDIA: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/livemedia`,
	PUBLISH_SMART_FILTER: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/smartfilters`,
	PUBLISH_UNSORTED_MEDIA: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/unsortmedia`,
	PUBLISH_EDITED_MEDIA: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/editedmedia`,
	PUBLISH_RECYCLE_BIN: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/recyclebin`,
	PUBLISH_PORTALS: `${ACCOUNT_ID_ROUTE_PREFIX}/medialibrary/portals`,
	APPS: `${ACCOUNT_ID_ROUTE_PREFIX}/apps`,
	APPS_EDITOR: `${ACCOUNT_ID_ROUTE_PREFIX}/apps/editor`,
	ANALYTICS_VIDEO_PERFORMANCE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/videoperformance`,
	ANALYTICS_SOCIAL: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/social`,
	ANALYTICS_REAL_TIME: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/realtime`,
	ANALYTICS_GENERAL_PERFORMANCE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/general-performance`,
	ANALYTICS_URL_PERFORMANCE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/url-performance`,
	ANALYTICS_STORAGE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/storage`,
	ANALYTICS_OPERATING_SYSTEMS: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/operatingsystems`,
	ANALYTICS_DEVICE_TYPE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/devicetypes`,
	ANALYTICS_BROWSERS: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/browsers`,
	ANALYTICS_BROWSERS_TYPE: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/browser-type`,
	ANALYTICS_PROTOCOL: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/protocol`,
	ANALYTICS_COUNTRIES: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/countries`,
	ANALYTICS_CITIES: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/cities`,
	ANALYTICS_REGIONS: `${ACCOUNT_ID_ROUTE_PREFIX}/analytics/cdn/regions`,
};

export const PAGINATION_ROUTE_EXTENSION = '/page/:pageNumber';

export const routePathSplitter = '#';
