import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as H from 'history';

import { ReactComponent as SmartFilterIcon } from '../../../assets/svg/SmartFilter.svg';
import ColorHub from '../../../reusable/ColorHub/ColorHub';
import ConfirmationDialog from '../../../reusable/ConfirmationDialog';
import { deleteSearchFilterQueryAction } from '../../../actions/searchActions';
import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';
import { loadPredefinedFilters } from '../../../actions/publishActions';
import { getLastIndexSmartFilterId } from '../../../services/locationPathServices';
import { useListCustomStyle } from '../../../reusable/CustomStyle/useCustomStyle';
import { setPredefinedFilters } from '../../../actions/publishActions';
import { joinClassNames } from '../../../services/elementHelperService';
import { localStorageKeys } from '../../../constants/localStorageKeys';

interface SmartFiltersProps {
	history: H.History;
	getResetSelectedSmartFilterHandler?: (_: () => void) => void;
	handleFilterChange?: (_: string) => void;
}

const SmartFilters: React.FC<SmartFiltersProps> = ({ getResetSelectedSmartFilterHandler, handleFilterChange }) => {
	const classes = useListCustomStyle();
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const predefinedFilters = useSelector((state) => (state as any).publish.predefinedFilters);
	const defaultAccountId = localStorage.getItem(localStorageKeys.DEFAULT_ACCOUNT_ID);

	const [processingFilter, setProcessingFilter] = useState<any | undefined>(undefined);
	const [selectedFilter, setSelectedFilter] = useState<any | undefined>({ id: getLastIndexSmartFilterId() });
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

	const loadSmartFilters = useCallback(() => {
		dispatch(loadPredefinedFilters(defaultAccountId)).then((filterData: any) => {
			const filtersSorted = _.sortBy(filterData, 'name');
			dispatch(setPredefinedFilters(filtersSorted));
		});
	}, [defaultAccountId]);

	const clearFilter = useCallback(() => {
		setSelectedFilter(undefined);
		const clearSearchBtn = document.querySelector('img.clear_search_img') as any;
		if (clearSearchBtn) {
			clearSearchBtn.click(false);
		}

		setTimeout(() => {
			const input = document.getElementById('searchInputBox');
			if (input) {
				input.blur();
			}
		}, 300);

		handleFilterChange?.('');
	}, [handleFilterChange]);

	const handleDeleteFilter = useCallback(
		(result: { ok: boolean }) => {
			const deletingFilter = processingFilter?.id;
			setShowDeleteConfirmationDialog(false);
			setProcessingFilter(undefined);

			if (result.ok && deletingFilter) {
				dispatch(deleteSearchFilterQueryAction(defaultAccountId, deletingFilter))
					.then((data: any) => {
						if (!data || data.message) {
							dispatch(showMessage(t('COMMON_DELETION_FAILED'), messageTypes.error));
							setProcessingFilter(undefined);
							return;
						}

						if (selectedFilter?.id === deletingFilter) {
							clearFilter();
						}

						setTimeout(() => {
							dispatch(showMessage(t('SMART_FILTERS_DELETED_FILTER_NOTIFICATION'), messageTypes.info));
							loadSmartFilters();
						});
					})
					.catch((error: any) => {
						dispatch(showMessage(error?.message ?? error, messageTypes.error));
					});
			}
		},
		[processingFilter, clearFilter, defaultAccountId, loadSmartFilters]
	);

	const onSelectFilter = useCallback(
		(filter: any) => {
			setSelectedFilter(filter);
			handleFilterChange?.(filter.id);
		},
		[selectedFilter]
	);

	useEffect(loadSmartFilters, [defaultAccountId]);
	useEffect(() => {
		getResetSelectedSmartFilterHandler?.(() => setSelectedFilter(undefined));
	}, [getResetSelectedSmartFilterHandler]);

	useLayoutEffect(() => {
		setTimeout(() => {
			if (selectedFilter?.id) {
				const selectedFilterRef = document.getElementById(`${selectedFilter.id}`);
				if (selectedFilterRef) {
					selectedFilterRef.scrollIntoView({
						block: 'nearest',
						inline: 'nearest',
					});
				}
			}
		}, 1000);
	}, [selectedFilter]);

	return (
		<>
			<List>
				{predefinedFilters &&
					predefinedFilters.length > 0 &&
					predefinedFilters.map((filter: any) => (
						<ListItem
							id={filter.id}
							key={filter.id}
							button
							selected={selectedFilter?.id === filter.id}
							classes={{ selected: classes.selected }}
							className={joinClassNames(classes.customItem, 'searchFilter--item')}
							onClick={() => onSelectFilter(filter)}
						>
							<ListItemIcon style={{ minWidth: '30px' }}>
								<ColorHub component={<SmartFilterIcon />} />
							</ListItemIcon>
							<span className={classes.ellipsis}>{filter.name}</span>
							<IconButton
								title={t('SMART_FILTERS_DELETE_FILTER_BUTTON_TITLE')}
								className={'searchFilter--icon'}
								onClick={(e) => {
									e.stopPropagation();
									setShowDeleteConfirmationDialog(true);
									setProcessingFilter(filter);
								}}
							>
								<DeleteOutlineOutlined color="error" fontSize="small" />
							</IconButton>
						</ListItem>
					))}
			</List>

			{showDeleteConfirmationDialog && processingFilter && (
				<ConfirmationDialog
					open={showDeleteConfirmationDialog}
					title={t('SMART_FILTERS_DELETE_SEARCH_FILTER_CONFIRM_MODAL_TITLE')}
					message={t('COMMON_DELETE_CONFIRMATION_SINGLE')}
					mediaTitle={processingFilter?.name}
					callback={handleDeleteFilter}
					okButtonText={t('COMMON_DELETE')}
				/>
			)}
		</>
	);
};

export default SmartFilters;
