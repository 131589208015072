import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ReactRouter from 'react-router';
import { Button, ButtonGroup, CssBaseline } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HomeLogo } from '../../assets/images/qbricksvg.svg';
import { customScreenType } from '../../constants/mediaConstants';
import { ACCOUNT_ID_ROUTE_PLACEHOLDER, screenRoutes } from '../../constants/routesPath';

import UserInformation from './UserInformation';
import BreadCrumb from './breadCrumb';

import './HeaderStyle.scss';
import { setShouldSaveChange } from '../../actions/inspectScreenActions';
import { buildRoutePath } from '../../utils/commonUtil';

type MediaDetailHeaderProps = ReactRouter.RouteComponentProps & {};

const MediaDetailHeader = ({ history }: MediaDetailHeaderProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { screenType } = useSelector((state) => (state as any).publish);
	const { isInspectMediaModified } = useSelector((state: any) => state.inspectScreenReducer);
	const { defaultAccountId } = useSelector((state: any) => state.session);

	return (
		<header className="header-container">
			<CssBaseline />
			<div className="left-items-container">
				{screenType !== customScreenType.mobile && screenType !== customScreenType.small && (
					<HomeLogo
						className="home-button"
						onClick={() =>
							history.push(
								buildRoutePath(screenRoutes.DASHBOARD, ACCOUNT_ID_ROUTE_PLACEHOLDER, defaultAccountId)
							)
						}
					/>
				)}
				<BreadCrumb history={history} position="header" />
			</div>
			<div className="right-items-container">
				<ButtonGroup>
					<Button
						variant="contained"
						color="primary"
						disabled={!isInspectMediaModified}
						onClick={() => dispatch(setShouldSaveChange(true))}
					>
						{t('COMMON_SAVE_CHANGES')}
					</Button>
				</ButtonGroup>
				<UserInformation
					mobile={
						screenType === customScreenType.mobile ||
						screenType === customScreenType.small ||
						screenType === customScreenType.tablet
					}
					history={history}
				/>
			</div>
		</header>
	);
};

export default MediaDetailHeader;
