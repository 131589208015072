import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { mediaNamesQuery } from '../../../constants/mediaConstants';
import { loadAllMediaItemsCount } from '../../../actions/publishActions';

interface GetMediasCountReturn {
	numberOfMedias: number;
	numberOfInteract: number;
	numberOfPlaylist: number;
	numberOfEditedMedia: number;
	numberOfLiveMedia: number;
}

export const useGetMediaCount = (): GetMediasCountReturn => {
	const dispatch = useDispatch() as any;
	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const { refreshMedias, refreshPlaylists } = useSelector((state) => (state as any).publish);

	const [numberOfMedias, setNumberOfMedias] = useState(0);
	const [numberOfInteract, setNumberOfInteract] = useState(0);
	const [numberOfPlaylist, setNumberOfPlaylist] = useState(0);
	const [numberOfEditedMedia, setNumberOfEditedMedia] = useState(0);
	const [numberOfLiveMedia, setNumberOfLiveMedia] = useState(0);

	const getAllMediaCount = (query: string, callBackFunc: (_: any) => void) => {
		dispatch(loadAllMediaItemsCount(defaultAccountId, query, '')).then((data: any) => {
			if (data) {
				callBackFunc(data);
			} else {
				callBackFunc(0);
			}
		});
	};

	const getCountOfMediaFilter = () => {
		const medias = new Promise((resolve) => {
			let query = `${mediaNamesQuery.medias}`;
			getAllMediaCount(query, (data: any) => resolve(data));
		});

		const scenarios = new Promise((resolve) => {
			getAllMediaCount(mediaNamesQuery.scenarios, (data: any) => resolve(data));
		});

		const playlists = new Promise((resolve) => {
			getAllMediaCount(mediaNamesQuery.playlists, (data: any) => resolve(data));
		});

		const editedMedia = new Promise((resolve) => {
			let query = `${mediaNamesQuery.medias}q=${mediaNamesQuery.editedMedias}`;
			getAllMediaCount(query, (data: any) => resolve(data));
		});

		const liveMedia = new Promise((resolve) => {
			let query = `${mediaNamesQuery.medias}${mediaNamesQuery.live}`;
			getAllMediaCount(query, (data: any) => resolve(data));
		});

		Promise.all([medias, scenarios, playlists, editedMedia, liveMedia]).then((values) => {
			const [mediasCount, scenariosCount, playlistsCount, editedMediaCount, liveMediaCount] = values;
			setNumberOfMedias(Number(mediasCount));
			setNumberOfInteract(Number(scenariosCount));
			setNumberOfPlaylist(Number(playlistsCount));
			setNumberOfEditedMedia(Number(editedMediaCount));
			setNumberOfLiveMedia(Number(liveMediaCount));
		});
	};

	useEffect(() => getCountOfMediaFilter(), [defaultAccountId]);

	useEffect(() => {
		if (!refreshMedias && !refreshPlaylists) {
			return;
		}
		getCountOfMediaFilter();
	}, [refreshMedias, refreshPlaylists]);

	return { numberOfMedias, numberOfInteract, numberOfPlaylist, numberOfEditedMedia, numberOfLiveMedia };
};
