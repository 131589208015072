import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { LocalStorageService } from '../../services/localStorageService';
import { getTotalCatalogsCount, loadCatalogs } from '../../services/mediaService';
import { showNotification } from '../../actions/notificationActions';
import { createDefaultMedia, getDetails } from '../../services/mediaDisplayService';
import { NOCATALOG, mediaTypes } from '../../constants/mediaConstants';
import { saveCatalogItems } from '../../actions/publishActions';

interface FetchCatalogDataProps {
	showNoCatalogOption?: boolean;
}

export const useFetchCatalogData = ({
	showNoCatalogOption,
}: FetchCatalogDataProps): { catalogs: any[]; flattenCatalogs: any[] } => {
	const dispatch = useDispatch() as any;
	const refreshCatalogs = useSelector((state) => (state as any).publish.refreshCatalogs);
	const defaultAccountId = localStorage.getItem('defaultAccountId');

	const [catalogs, setCatalogsState] = useState<any[]>([]);
	const [flattenCatalogs, setFlattenCatalogsState] = useState<any[]>([]);

	const unflatten = (arr: any[]): any[] => {
		var tree = [],
			mappedArr = [],
			arrElem,
			mappedElem;

		// First map the nodes of the array to an object -> create a hash table.
		for (var i = 0, len = arr.length; i < len; i++) {
			arrElem = arr[i];
			mappedArr[arrElem.id] = arrElem;
			mappedArr[arrElem.id]['children'] = [];
		}

		for (var id in mappedArr) {
			if (mappedArr.hasOwnProperty(id)) {
				mappedElem = mappedArr[id];
				// If the element is not at the root level, add it to its parent array of children.
				if (mappedElem && mappedElem.parentId && mappedArr[mappedElem.parentId]) {
					mappedArr[mappedElem.parentId]['children'].push(mappedElem);
				} else {
					// If the element is at the root level, add it to first level elements array.
					tree.push(mappedElem);
				}
			}
		}
		return tree;
	};

	const setCatalogs = useCallback(
		(catalogsToSet?: any[]) => {
			if (!catalogsToSet || !catalogsToSet.map) {
				return;
			}
			let catalogsArr: any[] = [];
			catalogsToSet.map((eachCatalog) => catalogsArr.push(getDetails(eachCatalog)));
			let newCatalogs = unflatten(catalogsArr);
			newCatalogs = _.sortBy(newCatalogs, 'title');
			if (showNoCatalogOption) {
				// to chave options like no catalog
				let noCatalog = createDefaultMedia(NOCATALOG, 'No Catalog', mediaTypes.catalog); // adding no catalog option
				noCatalog = getDetails(noCatalog) as any;
				newCatalogs.unshift(noCatalog);
			}
			setCatalogsState(newCatalogs);
			setFlattenCatalogsState(catalogsArr);
			//save to store so we could reuse on EditCatalogDialog - needed in many place
			dispatch(saveCatalogItems(catalogsArr));
		},
		[showNoCatalogOption]
	);

	const fetchCatalogs = useCallback(
		(query: string, offset: number) => {
			let catalogQuery = query;
			let promise = new Promise((resolve) => {
				getTotalCatalogsCount(
					defaultAccountId || LocalStorageService.getDefaultAccountId(),
					catalogQuery,
					offset
				)
					.then((totalCatalogsCount) => {
						loadCatalogs(
							defaultAccountId || LocalStorageService.getDefaultAccountId(),
							catalogQuery,
							offset
						)
							.then((data) => {
								// unable to called using props
								if (data) {
									resolve([data, totalCatalogsCount]);
								} else {
									resolve([[], 0]);
								}
							})
							.catch((error: any) => {
								dispatch(showNotification(error ? error.message : 'Failed to fetch Catalogs'));
							});
					})
					.catch((error) => {
						dispatch(showNotification(error ? error.message : 'Failed to fetch Catalogs count'));
					});
			});
			return promise;
		},
		[defaultAccountId, dispatch]
	);

	const getCatalogs = useCallback(
		async (query: string, offset: number) => {
			let limit = 200; //equals to limit
			fetchCatalogs(query, offset).then((obj: any) => {
				let newCatalogs = obj[0];
				let totalCatalogs = obj[1];
				let count = 1;
				if (Number(obj[0].length) !== Number(totalCatalogs)) {
					let iteration = Math.ceil(totalCatalogs / limit); // so lan fetch
					if (count < iteration) {
						let newCount = count;
						for (let count = 1; count < iteration; count++) {
							offset = offset + limit;
							fetchCatalogs(query, offset).then((obj2: any) => {
								newCount = newCount + 1;
								newCatalogs = newCatalogs.concat(obj2[0]);
								if (newCount === iteration) {
									setCatalogs(newCatalogs);
								}
							});
						}
					} else {
						setCatalogs(newCatalogs);
					}
				} else {
					setCatalogs(newCatalogs);
				}
			});
		},
		[fetchCatalogs, setCatalogs]
	);

	useEffect(() => {
		getCatalogs('catalogs?', 0);
	}, [refreshCatalogs, defaultAccountId, getCatalogs]);

	return { catalogs: [...catalogs], flattenCatalogs };
};
