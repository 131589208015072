import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControlLabel, TextField } from '@material-ui/core';
import { setInspectMediaModified } from '../../../../actions/inspectScreenActions';
import { publishPortalDetailsAction, savePortalDetailsAction } from '../../../../actions/publishActions';

interface IPortalContentProps {
	baseClassName: string;
	portalDetail: any;
	handleOnDataChanges: any;
}

const PortalContent = forwardRef(({ baseClassName, portalDetail, handleOnDataChanges }: IPortalContentProps, ref) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<any>();
	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [title, setPortalTitle] = useState();
	const [logo, setPortalLogo] = useState();
	const [playerId, setPlayerId] = useState();
	const [portalBackground, setPortalBackground] = useState();

	useImperativeHandle(ref, () => {
		return { onSaveChanges };
	});

	const onSaveChanges = (callback: any) => {
		if (!portalDetail) {
			return;
		}

		const newData = {
			...portalDetail.content,
			name: title,
			favicon: logo,
			presetId: playerId,
		};

		if (newData?.pages?.main?.web?.background) {
			newData.pages.main.web.background = portalBackground;
		}

		dispatch(savePortalDetailsAction(defaultAccountId, portalDetail.id, newData))
			.then(() => {
				dispatch(publishPortalDetailsAction(defaultAccountId, portalDetail.id)).then(() => {
					callback?.();
				});
			})
			.catch(() => {
				callback?.();
			});
	};

	const handleUpdatingData = useCallback(() => {
		handleOnDataChanges?.();
		dispatch(setInspectMediaModified(true));
	}, [dispatch, handleOnDataChanges]);

	useEffect(() => {
		const {
			content: { name, favicon, presetId, pages },
		} = portalDetail;

		setPortalTitle(name);
		setPortalLogo(favicon);
		setPlayerId(presetId);
		setPortalBackground(pages?.main?.web?.background ?? '#ffffff');
	}, [portalDetail]);

	return (
		<div className={`${baseClassName}__media-content-container`}>
			<div className="inspect-media__detail-container">
				<div className={'inspect-media__general-info-container'}>
					<Box>
						<FormControlLabel
							className={'inspect-media__input--full'}
							style={{ display: 'flex' }}
							control={
								<TextField
									fullWidth
									variant="outlined"
									value={title}
									placeholder={t('MEDIA_LIBRARY_PLAYLIST_INSPECT_PAGE_PLAYLIST_ENTER_TITLE')}
								/>
							}
							label={<span className={'inspect-media__input-label'}>{t('COMMON_TITLE')}</span>}
							onChange={(e: any) => {
								handleUpdatingData();
								setPortalTitle(e.target.value);
							}}
							labelPlacement={'top'}
						/>
					</Box>
					<Box style={{ marginBottom: 0, fontWeight: 'bold' }}>
						<span style={{ fontSize: 12 }}>Styling</span>
					</Box>

					<Box>
						<FormControlLabel
							className={'inspect-media__input--full'}
							style={{ display: 'flex' }}
							control={
								<TextField
									fullWidth
									variant="outlined"
									value={portalBackground}
									placeholder={'Color'}
								/>
							}
							label={null}
							onChange={(e: any) => {
								handleUpdatingData();
								setPortalBackground(e.target.value);
							}}
							labelPlacement={'top'}
						/>
					</Box>
					<Box>
						<FormControlLabel
							className={'inspect-media__input--full'}
							style={{ display: 'flex' }}
							control={
								<TextField fullWidth variant="outlined" value={logo} placeholder={'Logotype url'} />
							}
							label={null}
							onChange={(e: any) => {
								handleUpdatingData();
								setPortalLogo(e.target.value);
							}}
							labelPlacement={'top'}
						/>
					</Box>
					<Box>
						<FormControlLabel
							className={'inspect-media__input--full'}
							style={{ display: 'flex' }}
							control={
								<TextField
									fullWidth
									variant="outlined"
									value={playerId}
									placeholder={'Player preset'}
								/>
							}
							label={null}
							onChange={(e: any) => {
								handleUpdatingData();
								setPlayerId(e.target.value);
							}}
							labelPlacement={'top'}
						/>
					</Box>
				</div>
			</div>
		</div>
	);
});

export default PortalContent;
