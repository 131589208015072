import _ from 'underscore';
import moment from 'moment';

import {
	loadTags,
	newAssets,
	newCatalog,
	newPlaylist,
	loadProfiles,
	loadPlayLists,
	uploadMediaItems,
	loadAllPlayLists,
	deleteSubtitle,
	deleteAudio,
	saveMediaDetails,
	loadPlayerEmbedConfiguration,
	loadPlayerConfigurationById,
	_getAllCurrentJobs,
	twitterAuthenticate,
	deleteTheSharedPost,
	getSharedPostsStatus,
	deleteThumbnailImage,
	deleteRestrictionRuleId,
	deleteAssignedRendition,
	loadMediaItemsFullCount,
	loadMediasPerNavigation,
	assignMediaItemToCatalog,
	deleteMediaItemPerAccount,
	loadPlaylistRelatedMedias,
	assignMediaItemToPlaylist,
	updateResourceOfMediaAsset,
	shareOnSocialMediaPlatform,
	loadSearchPredefinedFilters,
	getAssignedRestrictionRules,
	thumbImageUploadFlowStarted,
	subtitleUploadFlowStarted,
	removeMediaItemFromPlaylist,
	deletePlaylistItemPerAccount,
	loadRealativeMediaForPlaylist,
	authorizeTheSocialMediaPlatform,
	getMediaQueryDetailsOnIdAndName,
	createNewAssignedRestrictionRule,
	loadResourcesAfterTheFlowStarted,
	applyAssignedRestrictionRuleOnMedia,
	deleteAssignedRestrictionRuleOnMedia,
	getSocialMedialAuthenticationDetails,
	removeSocialMediaAccount,
	setThumbnailImageAsDefaultThumbnails,
	updateExistingAssignedRestrictionRule,
	loadResourcesAfterTheFlowStartedForMedias,
	changeTheOrderOfTheSelectedMediaItemInPlaylist,
	deleteMediaItemFromSpecificCatalog,
	fetchLiveAssetInfo,
	removeResourceAsThumbnailImage,
	updateMediaCoverOfMedia,
	createNewMedia,
	uploadMediaItemsFlow,
	thumbImageUploadPassFlow,
	uploadMediaItemsOld,
	loadMediaCountsOnCatelogsService,
	audioUploadPassFlow,
	subtitleUploadPassFlow,
	uploadAudioItems,
	deleteAssetPerAccount,
	getAssetPerAccount,
	loadMediasByQueryService,
	_getAllFinishedJobs,
	uploadImageMedia,
	uploadMediaUsingQBrickEncode,
	replaceMediaUsingQBrickEncode,
	loadTrashedMediasPerNavigation,
	loadTrashedMediasByQueryService,
	_moveMediaToBin,
	_restoreMedia,
	getTrashedMediaById,
	updateUserRestrictionToCatalog,
	_assignMediasToPlaylist,
	getPortalDetails,
	savePortalDetails,
	publishPortal,
} from '../services/mediaService';
import { twitterClientId, twitterAuthenticateUrl, socialSharingRedirectUrl } from '../utils/config';
import { FLOWS, mediaNames, mediaTypes, messageTypes, UPLOAD_FAILED } from '../constants/mediaConstants';

import { LocalStorageService } from './../services/localStorageService';
import { showNotification } from './notificationActions';
import { showAlert } from './globalActions';
import { removeMeta } from '../utils/commonUtil';
import {
	_getEncoderJobs,
	_getEncoderJobsByStatus,
	getEncoderUploadId,
	loadEncoderFlows,
	uploadMediaToEncoderService,
} from '../services/encoderServices';
import { ACCESS_LEVEL } from '../reusable/CatalogTree/CatalogLimitedAccess';

const MAX_DELETE_RETRY = 10;

export const MEDIA_UPLOAD_STOPS = 'MEDIA_UPLOAD_STOPS';
export const SET_UPLOAD_PERCENTAGE = 'SET_UPLOAD_PERCENTAGE';
export const SET_IS_AUDIO_UPLOAD_FINISHED = 'SET_IS_AUDIO_UPLOAD_FINISHED';
export const RESET_UPLOAD_PERCENTAGE = 'RESET_UPLOAD_PERCENTAGE';
export const SET_SHOW_TYPE = 'SET_SHOW_TYPE';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SET_SORT_TYPE_TEXT = 'SET_SORT_TYPE_TEXT';
export const PUBLISH_FORM_UPDATE = 'PUBLISH_FORM_UPDATE';
export const HIDE_CATALOGS_MEDIA = 'HIDE_CATALOGS_MEDIA';
export const MEDIA_UPLOAD_STARTS = 'MEDIA_UPLOAD_STARTS';
export const SAVE_SELECTED_MEDIA = 'SAVE_SELECTED_MEDIA';
export const SEND_CATALOG_REQUEST = 'SEND_CATALOG_REQUEST';
export const SAVE_CATALOG_ITEMS = 'SAVE_CATALOG_ITEMS';
export const SAVE_SELECTED_PLAYLIST = 'SAVE_SELECTED_PLAYLIST';
export const DISPLAY_CATALOGS_MEDIA = 'DISPLAY_CATALOGS_MEDIA';
export const UPDATE_SELECT_ALL_PROPS = 'UPDATE_SELECT_ALL_PROPS';
export const SEND_LOAD_MEDIA_REQUEST = 'SEND_LOAD_MEDIA_REQUEST';
export const SEND_COUNT_MEDIA_REQUEST = 'SEND_COUNT_MEDIA_REQUEST';
export const SAVE_SELECTED_MEDIA_ENDS = 'SAVE_SELECTED_MEDIA_ENDS';
export const SEND_MEDIA_SCREEN_CHANGED = 'SEND_MEDIA_SCREEN_CHANGED';
export const MEDIA_UPLOAD_CHECK_STATUS = 'MEDIA_UPLOAD_CHECK_STATUS';
export const SAVE_SELECTED_PLAYLIST_ENDS = 'SAVE_SELECTED_PLAYLIST_ENDS';
export const REMOVED_ITEM_STORE_TO_PROPS = 'REMOVED_ITEM_STORE_TO_PROPS';
export const SEND_REQUEST_LOAD_PLAYLISTS = 'SEND_REQUEST_LOAD_PLAYLISTS';
export const SET_THUMBNAIL_IMAGE_REQUEST = 'SET_THUMBNAIL_IMAGE_REQUEST';
export const SEND_MEDIA_SCREEN_UNCHANGED = 'SEND_MEDIA_SCREEN_UNCHANGED';
export const UPDATE_ITEMS_LIST_FOR_ACTIONS = 'UPDATE_ITEMS_LIST_FOR_ACTIONS';
export const SEND_ENCODING_PROFILE_REQUEST = 'SEND_ENCODING_PROFILE_REQUEST';
export const REMOVE_SUBTITLE_REQUEST = 'REMOVE_SUBTITLE_REQUEST';
export const REMOVE_AUDIO_REQUEST = 'REMOVE_AUDIO_REQUEST';
export const REMOVE_THUMBNAIL_IMAGE_REQUEST = 'REMOVE_THUMBNAIL_IMAGE_REQUEST';
export const SEND_LOAD_ALL_PLAYLIST_REQUEST = 'SEND_LOAD_ALL_PLAYLIST_REQUEST';
export const SEND_MEDIA_ITEM_UPLOAD_REQUEST = 'SEND_MEDIA_ITEM_UPLOAD_REQUEST';
export const SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST = 'SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST';
export const SEND_MEDIA_ITEM_DELETE_REQUEST = 'SEND_MEDIA_ITEM_DELETE_REQUEST';
export const GET_MEDIA_QUERY_DETAILS_REQUEST = 'GET_MEDIA_QUERY_DETAILS_REQUEST';
export const SEND_POST_SHARED_DELETE_REQUEST = 'SEND_POST_SHARED_DELETE_REQUEST';
export const SEND_LOAD_MEDIA_REQUEST_SUCCESS = 'SEND_LOAD_MEDIA_REQUEST_SUCCESS';
export const SEND_LOAD_MEDIA_REQUEST_FAILURE = 'SEND_LOAD_MEDIA_REQUEST_FAILURE';
export const SEND_REQUEST_CREATE_NEW_PLAYLIST = 'SEND_REQUEST_CREATE_NEW_PLAYLIST';
export const SEND_COUNT_MEDIA_REQUEST_SUCCESS = 'SEND_COUNT_MEDIA_REQUEST_SUCCESS';
export const SEND_COUNT_MEDIA_REQUEST_FAILURE = 'SEND_COUNT_MEDIA_REQUEST_FAILURE';
export const SEND_PLAYLIST_ITEM_DELETE_REQUEST = 'SEND_PLAYLIST_ITEM_DELETE_REQUEST';
export const SEND_RELATED_MEDIAS_FETCH_REQUEST = 'SEND_RELATED_MEDIAS_FETCH_REQUEST';
export const UPDATE_ALL_ITEMS_LIST_FOR_ACTIONS = 'UPDATE_ALL_ITEMS_LIST_FOR_ACTIONS';
export const CHANGE_ROUTE_AFTER_INSPECT_SCREEN = 'CHANGE_ROUTE_AFTER_INSPECT_SCREEN';
export const SEND_REQUEST_LOAD_PLAYLISTS_SUCCESS = 'SEND_REQUEST_LOAD_PLAYLISTS_SUCCESS';
export const SEND_REQUEST_LOAD_PLAYLISTS_FAILURE = 'SEND_REQUEST_LOAD_PLAYLISTS_FAILURE';
export const SET_THUMBNAIL_IMAGE_REQUEST_SUCCESS = 'SET_THUMBNAIL_IMAGE_REQUEST_SUCCESS';
export const SET_THUMBNAIL_IMAGE_REQUEST_FAILURE = 'SET_THUMBNAIL_IMAGE_REQUEST_FAILURE';
export const DID_FILE_UPLOAD_ACTION_HAPPENED_STATUS = 'DID_FILE_UPLOAD_ACTION_HAPPENED_STATUS';
export const REMOVE_SUBTITLE_REQUEST_SUCCESS = 'REMOVE_SUBTITLE_REQUEST_SUCCESS';
export const REMOVE_SUBTITLE_REQUEST_FAILURE = 'REMOVE_SUBTITLE_REQUEST_FAILURE';
export const REMOVE_AUDIO_REQUEST_SUCCESS = 'REMOVE_AUDIO_REQUEST_SUCCESS';
export const REMOVE_AUDIO_REQUEST_FAILURE = 'REMOVE_AUDIO_REQUEST_FAILURE';
export const REMOVE_THUMBNAIL_IMAGE_REQUEST_SUCCESS = 'REMOVE_THUMBNAIL_IMAGE_REQUEST_SUCCESS';
export const REMOVE_THUMBNAIL_IMAGE_REQUEST_FAILURE = 'REMOVE_THUMBNAIL_IMAGE_REQUEST_FAILURE';
export const SEND_LOAD_ALL_PLAYLIST_REQUEST_FAILURE = 'SEND_LOAD_ALL_PLAYLIST_REQUEST_FAILURE';
export const SEND_LOAD_ALL_PLAYLIST_REQUEST_SUCCESS = 'SEND_LOAD_ALL_PLAYLIST_REQUEST_SUCCESS';
export const SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_SUCCESS = 'SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_SUCCESS';
export const SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_FAILURE = 'SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_FAILURE';
export const SEND_MEDIA_ITEM_UPLOAD_REQUEST_SUCCESS = 'SEND_MEDIA_ITEM_UPLOAD_REQUEST_SUCCESS';
export const SEND_MEDIA_ITEM_UPLOAD_REQUEST_FAILURE = 'SEND_MEDIA_ITEM_UPLOAD_REQUEST_FAILURE';
export const SEND_MEDIA_ITEM_DELETE_REQUEST_SUCCESS = 'SEND_MEDIA_ITEM_DELETE_REQUEST_SUCCESS';
export const SEND_MEDIA_ITEM_DELETE_REQUEST_FAILURE = 'SEND_MEDIA_ITEM_DELETE_REQUEST_FAILURE';
export const SEND_ENCODING_PROFILES_REQUEST_SUCCESS = 'SEND_ENCODING_PROFILES_REQUEST_SUCCESS';
export const SEND_ENCODING_PROFILES_REQUEST_FAILURE = 'SEND_ENCODING_PROFILES_REQUEST_FAILURE';
export const SEND_POST_SHARED_DELETE_REQUEST_FAILED = 'SEND_POST_SHARED_DELETE_REQUEST_FAILED';
export const GET_MEDIA_QUERY_DETAILS_REQUEST_SUCCESS = 'GET_MEDIA_QUERY_DETAILS_REQUEST_SUCCESS';
export const SEND_POST_SHARED_DELETE_REQUEST_SUCCESS = 'SEND_POST_SHARED_DELETE_REQUEST_SUCCESS';
export const SEND_REQUEST_CREATE_NEW_CATALOG_SUCCESS = 'SEND_REQUEST_CREATE_NEW_CATALOG_SUCCESS';
export const SEND_REQUEST_CREATE_NEW_CATALOG_FAILURE = 'SEND_REQUEST_CREATE_NEW_CATALOG_FAILURE';
export const GET_MEDIA_QUERY_DETAILS_REQUEST_FAILURE = 'GET_MEDIA_QUERY_DETAILS_REQUEST_FAILURE';
export const SEND_REQUEST_CREATE_NEW_PLAYLIST_SUCCESS = 'SEND_REQUEST_CREATE_NEW_PLAYLIST_SUCCESS';
export const SEND_REQUEST_CREATE_NEW_PLAYLIST_FAILURE = 'SEND_REQUEST_CREATE_NEW_PLAYLIST_FAILURE';
export const START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST = 'START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST';
export const START_SUBTITLE_UPLOAD_PROCESS_REQUEST = 'START_SUBTITLE_UPLOAD_PROCESS_REQUEST';
export const START_AUDIO_UPLOAD_PROCESS_REQUEST = 'START_AUDIO_UPLOAD_PROCESS_REQUEST';
export const SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST = 'SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST';
export const SEND_PLAYLIST_ITEM_DELETE_REQUEST_SUCCESS = 'SEND_PLAYLIST_ITEM_DELETE_REQUEST_SUCCESS';
export const SEND_PLAYLIST_ITEM_DELETE_REQUEST_FAILURE = 'SEND_PLAYLIST_ITEM_DELETE_REQUEST_FAILURE';
export const SEND_RELATED_MEDIAS_FETCH_REQUEST_SUCCESS = 'SEND_RELATED_MEDIAS_FETCH_REQUEST_SUCCESS';
export const SEND_RELATED_MEDIAS_FETCH_REQUEST_FAILURE = 'SEND_RELATED_MEDIAS_FETCH_REQUEST_FAILURE';
export const SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST = 'SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST';
export const SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST = 'SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST';
export const SEND_DELETE_MEDIA_TO_CATALOG_REQUEST = 'SEND_DELETE_MEDIA_TO_CATALOG_REQUEST';
export const SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST = 'SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST';
export const SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_FAILED = 'SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_FAILED';
export const SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_SUCCESS = 'SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_SUCCESS';
export const START_SUBTITLE_UPLOAD_PROCESS_REQUEST_SUCCESS = 'START_SUBTITLE_UPLOAD_PROCESS_REQUEST_SUCCESS';
export const START_SUBTITLE_UPLOAD_PROCESS_REQUEST_FAILURE = 'START_SUBTITLE_UPLOAD_PROCESS_REQUEST_FAILURE';
export const START_AUDIO_UPLOAD_PROCESS_REQUEST_SUCCESS = 'START_AUDIO_UPLOAD_PROCESS_REQUEST_SUCCESS';
export const START_AUDIO_UPLOAD_PROCESS_REQUEST_FAILURE = 'START_AUDIO_UPLOAD_PROCESS_REQUEST_FAILURE';
export const START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_SUCCESS = 'START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_SUCCESS';
export const START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_FAILURE = 'START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_FAILURE';
export const SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_SUCCESS = 'SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_SUCCESS';
export const SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_FAILURE = 'SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_FAILURE';

export const SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_SUCCESS = 'SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_SUCCESS';
export const SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_FAILURE = 'SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_FAILURE';

export const SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_SUCCESS = 'SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_SUCCESS';
export const SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_FAILURE = 'SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_FAILURE';
export const SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_SUCCESS =
	'SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_SUCCESS';
export const SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_FAILURE =
	'SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_FAILURE';
export const SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST =
	'SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST';
export const SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_SUCCESS =
	'SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_SUCCESS';
export const SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_FAILURE =
	'SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_FAILURE';
export const SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST =
	'SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST';
export const SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_SUCCESS =
	'SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_SUCCESS';
export const SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_FAILURE =
	'SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_FAILURE';

export const SEND_LIVE_ASSET_REQUEST = 'SEND_LIVE_ASSET_REQUEST';
export const SEND_LIVE_ASSET_REQUEST_SUCCESS = 'SEND_LIVE_ASSET_REQUEST_SUCCESS';
export const SEND_LIVE_ASSET_REQUEST_FAILURE = 'SEND_LIVE_ASSET_REQUEST_FAILURE';

export const SEND_UPDATE_RESOURCE_OF_MEDIA_REQUEST = 'SEND_UPDATE_RESOURCE_OF_MEDIA_REQUEST';
export const SEND_UPDATE_RESOURCE_OF_MEDIA_SUCCESS = 'SEND_UPDATE_RESOURCE_OF_MEDIA_SUCCESS';
export const SEND_UPDATE_RESOURCE_OF_MEDIA_FAILURE = 'SEND_UPDATE_RESOURCE_OF_MEDIA_FAILURE';

export const SEND_UPDATE_MEDIA_COVER_REQUEST = 'SEND_UPDATE_MEDIA_COVER_REQUEST';
export const SEND_UPDATE_MEDIA_COVER_REQUEST_SUCCESS = 'SEND_UPDATE_MEDIA_COVER_REQUEST_SUCCESS';
export const SEND_UPDATE_MEDIA_COVER_REQUEST_FAILURE = 'SEND_UPDATE_MEDIA_COVER_REQUEST_FAILURE';

export const SEND_ADD_NEW_MEDIA_REQUEST = 'SEND_ADD_NEW_MEDIA_REQUEST';
export const SEND_ADD_NEW_MEDIA_REQUEST_SUCCESS = 'SEND_ADD_NEW_MEDIA_REQUEST_SUCCESS';
export const SEND_ADD_NEW_MEDIA_REQUEST_FAILURE = 'SEND_ADD_NEW_MEDIA_REQUEST_FAILURE';

export const SEND_MEDIA_FLOW_UPLOAD_REQUEST = 'SEND_MEDIA_FLOW_UPLOAD_REQUEST';
export const SEND_MEDIA_FLOW_UPLOAD_REQUEST_SUCCESS = 'SEND_MEDIA_FLOW_UPLOAD_REQUEST_SUCCESS';
export const SEND_MEDIA_FLOW_UPLOAD_REQUEST_FAILURE = 'SEND_MEDIA_FLOW_UPLOAD_REQUEST_FAILURE';

export const SET_UPLOADING_PROCESS_ACTIVE = 'SET_UPLOADING_PROCESS_ACTIVE';
export const SET_IS_ENCODING_TRUE = 'SET_IS_ENCODING_TRUE';
export const SET_IS_ENCODING_FALSE = 'SET_IS_ENCODING_FALSE';
export const SET_IS_UPLOADING = 'SET_IS_UPLOADING';
export const ADD_TO_IS_PREPARING_FOR_UPLOAD = 'ADD_TO_IS_PREPARING_FOR_UPLOAD';
export const REMOVE_FROM_IS_PREPARING_FOR_UPLOAD = 'REMOVE_FROM_IS_PREPARING_FOR_UPLOAD';
export const EMPTY_PREPARING_FOR_UPLOAD = 'EMPTY_PREPARING_FOR_UPLOAD';
export const REMOVE_FROM_UPLOAD_PERCENTAGE = 'REMOVE_FROM_UPLOAD_PERCENTAGE';
export const ADD_TO_IS_ENCODING = 'ADD_TO_IS_ENCODING';
export const REMOVE_FROM_IS_ENCODING = 'REMOVE_FROM_IS_ENCODING';
export const REPLACE_IS_ENCODING = 'REPLACE_IS_ENCODING';

export const SEND_ENCODING_PROFILE_DETAIL_REQUEST = 'SEND_ENCODING_PROFILE_DETAIL_REQUEST';
export const SEND_ENCODING_PROFILE_DETAIL_REQUEST_SUCCESS = 'SEND_ENCODING_PROFILE_DETAIL_REQUEST_SUCCESS';
export const SEND_ENCODING_PROFILE_DETAIL_REQUEST_FAILURE = 'SEND_ENCODING_PROFILE_DETAIL_REQUEST_FAILURE';
export const SEND_LOAD_MEDIA_TOTAL_COUNT_SUCCESS = 'SEND_LOAD_MEDIA_TOTAL_COUNT_SUCCESS';
export const SEND_LOAD_MEDIA_TOTAL_COUNT_FAILURE = 'SEND_LOAD_MEDIA_TOTAL_COUNT_FAILURE';

export const ADD_EMAIL = 'ADD_EMAIL';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';

export const SET_SELECTED_MEDIA_FILTER = 'SET_SELECTED_MEDIA_FILTER';
export const SET_SELECTED_PLAYLISTS_FILTER = 'SET_SELECTED_PLAYLISTS_FILTER';
export const SET_SELECTED_SCENARIOS_FILTER = 'SET_SELECTED_SCENARIOS_FILTER';
export const REFRESH_CATALOGS = 'REFRESH_CATALOGS';
export const REFRESH_MEDIAS = 'REFRESH_MEDIAS';
export const REFRESH_MEDIAS_IDS = 'REFRESH_MEDIAS_IDS';
export const REFRESH_PLAYLISTS = 'REFRESH_PLAYLISTS';
export const SET_LAST_ROUTE_OF_LIBRARY_VIEW = 'SET_LAST_ROUTE_OF_LIBRARY_VIEW';
export const SET_PREDEFINED_FILTER = 'SET_PREDEFINED_FILTER';
export const CLEAR_PREDEFINED_FILTER = 'CLEAR_PREDEFINED_FILTER';
export const SET_SHOW_UPLOAD_MEDIA_DIALOG = 'SET_SHOW_UPLOAD_MEDIA_DIALOG';
export const SET_SELECTED_CATALOGS = 'SET_SELECTED_CATALOGS';
export const SET_CC_EVERYWHERE = 'SET_CC_EVERYWHERE';
export const SET_SCREEN_TYPE = 'SET_SCREEN_TYPE';
export const SET_ACCOUNT_RESTRICTIONS = 'SET_ACCOUNT_RESTRICTIONS';

export const SET_QBRICK_STANDARD_FLOWS = 'SET_QBRICK_STANDARD_FLOWS';
export const SET_REPLACE_MEDIA_CONTENT = 'SET_REPLACE_MEDIA_CONTENT';
export const SET_SHOW_PERMISSION_INFORMATION_DIALOG = 'SET_SHOW_PERMISSION_INFORMATION_DIALOG';

export const setPermissionInformationDialog = (permissionInformationDialog) => ({
	type: SET_SHOW_PERMISSION_INFORMATION_DIALOG,
	permissionInformationDialog,
});

export const setAccountRestrictions = (accountRestrictions) => ({
	type: SET_ACCOUNT_RESTRICTIONS,
	accountRestrictions,
});

export const setPublishScreenType = (screenType) => ({
	type: SET_SCREEN_TYPE,
	screenType,
});

export const setSelectedCatalogId = (selectedCatalogId) => ({
	type: SET_SELECTED_CATALOGS,
	selectedCatalogId,
});

export const setCCEverywhere = (ccEverywhere) => ({
	type: SET_CC_EVERYWHERE,
	ccEverywhere,
});

export const setShowUploadMediaDialog = (showUploadMediaDialogBoxStatus) => ({
	type: SET_SHOW_UPLOAD_MEDIA_DIALOG,
	showUploadMediaDialogBoxStatus,
});

export const setLastRouteOfLibraryView = (location) => ({
	type: SET_LAST_ROUTE_OF_LIBRARY_VIEW,
	location,
});

export const setPredefinedFilters = (predefinedFilters) => ({
	type: SET_PREDEFINED_FILTER,
	predefinedFilters,
});

export const clearPredefinedFilters = (_) => ({
	type: CLEAR_PREDEFINED_FILTER,
});

export const setUploadingProcessActive = (value) => ({
	type: SET_UPLOADING_PROCESS_ACTIVE,
	uploadingProcessActive: value,
});

export const addToIsEncoding = (fileName) => ({
	type: ADD_TO_IS_ENCODING,
	fileName,
});

export const removeFromIsEncoding = (fileName) => ({
	type: REMOVE_FROM_IS_ENCODING,
	fileName,
});

export const addEmail = (email) => ({
	type: ADD_EMAIL,
	email,
});

export const removeEmail = (email) => ({
	type: REMOVE_EMAIL,
	email,
});

export const replaceIsEncoding = (encodingArray) => ({
	type: REPLACE_IS_ENCODING,
	encodingArray,
});

export const preventUserFromCloseWindow = (isUploading) => ({
	type: SET_IS_UPLOADING,
	payload: isUploading,
});

export const addToIsPreparingForUpload = (fileName) => ({
	type: ADD_TO_IS_PREPARING_FOR_UPLOAD,
	fileName,
});

export const removeFromPreparingForUpload = (fileName) => ({
	type: REMOVE_FROM_IS_PREPARING_FOR_UPLOAD,
	fileName,
});

export const emptyPreparingForUpload = () => ({
	type: EMPTY_PREPARING_FOR_UPLOAD,
});

export const sendAddNewMediaRequest = (_) => ({
	type: SEND_ADD_NEW_MEDIA_REQUEST,
});

export const sendAddNewMediaRequestSuccess = (_) => ({
	type: SEND_ADD_NEW_MEDIA_REQUEST_SUCCESS,
});
export const sendAddNewMediaRequestFailure = (_) => ({
	type: SEND_ADD_NEW_MEDIA_REQUEST_FAILURE,
});

export const sendupdateMediaCoverRequest = (_) => ({
	type: SEND_UPDATE_MEDIA_COVER_REQUEST,
});
export const sendupdateMediaCoverRequestSuccess = (_) => ({
	type: SEND_UPDATE_MEDIA_COVER_REQUEST_SUCCESS,
});
export const sendupdateMediaCoverRequestFailure = (_) => ({
	type: SEND_UPDATE_MEDIA_COVER_REQUEST_FAILURE,
});

export const sendUpdateResourceOfMediaRequest = (_) => ({
	type: SEND_UPDATE_RESOURCE_OF_MEDIA_REQUEST,
});
export const sendUpdateResourceOfMediaSuccess = (_) => ({
	type: SEND_UPDATE_RESOURCE_OF_MEDIA_SUCCESS,
});

export const sendUpdateResourceOfMediaFailure = (_) => ({
	type: SEND_UPDATE_RESOURCE_OF_MEDIA_FAILURE,
});

export const sendMediaScreenChanged = (_) => ({
	type: SEND_MEDIA_SCREEN_CHANGED,
});

export const sendMediaScreenUnChanged = (_) => ({
	type: SEND_MEDIA_SCREEN_UNCHANGED,
});

export const changeRouteAfterInspectScreen = (route) => ({
	type: CHANGE_ROUTE_AFTER_INSPECT_SCREEN,
	route: route,
});

export const updateItemsListForActions = (newMedia) => ({
	type: UPDATE_ITEMS_LIST_FOR_ACTIONS,
	newMedia,
});

export const updateAllItemsListForActions = (itemsList) => ({
	type: UPDATE_ALL_ITEMS_LIST_FOR_ACTIONS,
	itemsList,
});

const sendLoadMediaRequest = (_) => ({
	type: SEND_LOAD_MEDIA_REQUEST,
});

const sendCountMediaRequest = (_) => ({
	type: SEND_COUNT_MEDIA_REQUEST,
});
const sendCountMediaRequestSuccess = (_) => ({
	type: SEND_COUNT_MEDIA_REQUEST_SUCCESS,
});
const sendCountMediaRequestFailure = (_) => ({
	type: SEND_COUNT_MEDIA_REQUEST_FAILURE,
});

const sendLoadMediaRequestSuccess = (_) => ({
	type: SEND_LOAD_MEDIA_REQUEST_SUCCESS,
});

const sendLoadUsersRequestFailure = (_) => ({
	type: SEND_LOAD_MEDIA_REQUEST_FAILURE,
});

const sendMediaItemDeleteRequest = (_) => ({
	type: SEND_MEDIA_ITEM_DELETE_REQUEST,
});

const sendMediaItemDeleteRequestSuccess = (_) => ({
	type: SEND_MEDIA_ITEM_DELETE_REQUEST_SUCCESS,
});

const sendMediaItemDeleteRequestFailure = (_) => ({
	type: SEND_MEDIA_ITEM_DELETE_REQUEST_FAILURE,
});

const sendPlaylistItemDeleteRequest = (_) => ({
	type: SEND_PLAYLIST_ITEM_DELETE_REQUEST,
});

const sendPlaylistItemDeleteRequestSuccess = (_) => ({
	type: SEND_PLAYLIST_ITEM_DELETE_REQUEST_SUCCESS,
});

const sendPlaylistItemDeleteRequestFailure = (_) => ({
	type: SEND_PLAYLIST_ITEM_DELETE_REQUEST_FAILURE,
});

const sendCatalogRequest = (_) => ({
	type: SEND_CATALOG_REQUEST,
});

export const saveCatalogItems = (catalogs) => ({
	type: SAVE_CATALOG_ITEMS,
	payload: catalogs,
});

const sendEncodingProfilesRequest = (_) => ({
	type: SEND_ENCODING_PROFILE_REQUEST,
});

const sendEncodingProfilesSuccess = (_) => ({
	type: SEND_ENCODING_PROFILES_REQUEST_SUCCESS,
});

const sendEncodingProfilesFailure = (_) => ({
	type: SEND_ENCODING_PROFILES_REQUEST_FAILURE,
});

// eslint-disable-next-line no-unused-vars
const sendEncodingProfileDetailRequest = (_) => ({
	type: SEND_ENCODING_PROFILE_DETAIL_REQUEST,
});

// eslint-disable-next-line no-unused-vars
const sendEncodingProfileDetailSuccess = (_) => ({
	type: SEND_ENCODING_PROFILE_DETAIL_REQUEST_SUCCESS,
});

// eslint-disable-next-line no-unused-vars
const sendEncodingProfileDetailFailure = (_) => ({
	type: SEND_ENCODING_PROFILE_DETAIL_REQUEST_FAILURE,
});

const sendMediaUploadOldRequest = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST,
});

const sendMediaUploadOldRequestSuccess = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_SUCCESS,
});

const sendMediaUploadOldRequestFailure = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_FAILURE,
});

const sendMediaUploadRequest = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_REQUEST,
});

const sendMediaUploadRequestSuccess = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_REQUEST_SUCCESS,
});

const sendMediaUploadRequestFailure = (_) => ({
	type: SEND_MEDIA_ITEM_UPLOAD_REQUEST_FAILURE,
});

const sendUploadMediaFlowRequest = (_) => ({
	type: SEND_MEDIA_FLOW_UPLOAD_REQUEST,
});

const sendUploadMediaFlowSuccess = (_) => ({
	type: SEND_MEDIA_FLOW_UPLOAD_REQUEST_SUCCESS,
});

const sendUploadMediaFlowFailure = (_) => ({
	type: SEND_MEDIA_FLOW_UPLOAD_REQUEST_FAILURE,
});

const sendRequestLoadPlayLists = (_) => ({
	type: SEND_REQUEST_LOAD_PLAYLISTS,
});

const sendRequestLoadPlayListSuccess = (playlists) => ({
	type: SEND_REQUEST_LOAD_PLAYLISTS_SUCCESS,
	playlists,
});

const sendRequestLoadPlayListsFailure = (_) => ({
	type: SEND_REQUEST_LOAD_PLAYLISTS_FAILURE,
});

const sendRequestCreateNewPlaylist = (_) => ({
	type: SEND_REQUEST_CREATE_NEW_PLAYLIST,
});

const sendRequestCreateNewPlaylistSuccess = (_) => ({
	type: SEND_REQUEST_CREATE_NEW_PLAYLIST_SUCCESS,
});

const sendRequestCreateNewPlaylistFailure = (_) => ({
	type: SEND_REQUEST_CREATE_NEW_PLAYLIST_FAILURE,
});

const sendRequestCreateNewCatalogSuccess = (_) => ({
	type: SEND_REQUEST_CREATE_NEW_CATALOG_SUCCESS,
});

const sendRequestCreateNewCatalogFailure = (_) => ({
	type: SEND_REQUEST_CREATE_NEW_CATALOG_FAILURE,
});

const startSubtitleUploadProcessRequest = (_) => ({
	type: START_SUBTITLE_UPLOAD_PROCESS_REQUEST,
});

const startSubtitleUploadProcessSuccess = (_) => ({
	type: START_SUBTITLE_UPLOAD_PROCESS_REQUEST_SUCCESS,
});

const startSubtitleUploadProcessFailure = (_) => ({
	type: START_SUBTITLE_UPLOAD_PROCESS_REQUEST_FAILURE,
});

const startAudioUploadProcessRequest = (_) => ({
	type: START_AUDIO_UPLOAD_PROCESS_REQUEST,
});

// eslint-disable-next-line no-unused-vars
const startAudioUploadProcessSuccess = (_) => ({
	type: START_AUDIO_UPLOAD_PROCESS_REQUEST_SUCCESS,
});

const startAudioUploadProcessFailure = (_) => ({
	type: START_AUDIO_UPLOAD_PROCESS_REQUEST_FAILURE,
});

const removeSubtitleRequest = (_) => ({
	type: REMOVE_SUBTITLE_REQUEST,
});

const removeSubtitleRequestSuccess = (_) => ({
	type: REMOVE_SUBTITLE_REQUEST_SUCCESS,
});

const removeSubtitleRequestFailure = (_) => ({
	type: REMOVE_SUBTITLE_REQUEST_FAILURE,
});

const removeAudioRequest = (_) => ({
	type: REMOVE_AUDIO_REQUEST,
});

const removeAudioRequestSuccess = (_) => ({
	type: REMOVE_AUDIO_REQUEST_SUCCESS,
});

const removeAudioRequestFailure = (_) => ({
	type: REMOVE_AUDIO_REQUEST_FAILURE,
});

const startThumbImageUploadProcessRequest = (_) => ({
	type: START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST,
});

const startThumbImageUploadProcessSuccess = (_) => ({
	type: START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_SUCCESS,
});

const startThumbImageUploadProcessFailure = (_) => ({
	type: START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_FAILURE,
});

const removeThumbnailImageRequest = (_) => ({
	type: REMOVE_THUMBNAIL_IMAGE_REQUEST,
});

const removeThumbnailImageRequestSuccess = (_) => ({
	type: REMOVE_THUMBNAIL_IMAGE_REQUEST_SUCCESS,
});

const removeThumbnailImageRequestFailure = (_) => ({
	type: REMOVE_THUMBNAIL_IMAGE_REQUEST_FAILURE,
});

const setThumbnailImageRequest = (_) => ({
	type: SET_THUMBNAIL_IMAGE_REQUEST,
});

const setThumbnailImageRequestSuccess = (_) => ({
	type: SET_THUMBNAIL_IMAGE_REQUEST_SUCCESS,
});

const setThumbnailImageRequestFailure = (_) => ({
	type: SET_THUMBNAIL_IMAGE_REQUEST_FAILURE,
});

const assignMediaItemToPlaylistRequest = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST,
});

const assignMediaItemToPlaylistRequestSuccess = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_SUCCESS,
});

const assignMediaItemToPlaylistRequestFailure = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_FAILURE,
});

const sendRelatedMediasFetchRequest = (_) => ({
	type: SEND_RELATED_MEDIAS_FETCH_REQUEST,
});

const sendRelatedMediasFetchRequestSuccess = (_) => ({
	type: SEND_RELATED_MEDIAS_FETCH_REQUEST_SUCCESS,
});

const sendRelatedMediasFetchRequestFailure = (_) => ({
	type: SEND_RELATED_MEDIAS_FETCH_REQUEST_FAILURE,
});

const sendRemoveSelectedMediaItemFromThePlaylistRequest = (_) => ({
	type: SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST,
});

const sendRemoveSelectedMediaItemFromThePlaylistRequestSuccess = (_) => ({
	type: SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_SUCCESS,
});

const sendRemoveSelectedMediaItemFromThePlaylistRequestFailure = (_) => ({
	type: SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_FAILURE,
});

const assignMediaItemToCatalogRequest = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST,
});

const assignMediaItemToCatalogRequestSuccess = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_SUCCESS,
});

const assignMediaItemToCatalogRequestFailure = (_) => ({
	type: SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_FAILURE,
});

const deleteMediaFromCatalogRequest = (_) => ({
	type: SEND_DELETE_MEDIA_TO_CATALOG_REQUEST,
});

const deleteMediaFromCatalogRequestSuccess = (_) => ({
	type: SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_SUCCESS,
});

const deleteMediaFromCatalogRequestFailure = (_) => ({
	type: SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_FAILURE,
});

const sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequest = (_) => ({
	type: SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST,
});

const sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequestSuccess = (_) => ({
	type: SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_SUCCESS,
});

const sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequestFailure = (_) => ({
	type: SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_FAILURE,
});

const shareOnSocialMediaPlatformRequest = (_) => ({
	type: SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST,
});

const shareOnSocialMediaPlatformRequestSuccess = (_) => ({
	type: SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_SUCCESS,
});

const shareOnSocialMediaPlatformRequestFailure = (_) => ({
	type: SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_FAILURE,
});

const sendPostSharedDeleteRequest = (_) => ({
	type: SEND_POST_SHARED_DELETE_REQUEST,
});

const sendPostSharedDeleteRequestSuccess = (_) => ({
	type: SEND_POST_SHARED_DELETE_REQUEST_SUCCESS,
});

const sendPostSharedDeleteRequestFailure = (_) => ({
	type: SEND_POST_SHARED_DELETE_REQUEST_FAILED,
});

const sendSocialMediaAuthenticationRequest = (_) => ({
	type: SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST,
});

const sendSocialMediaAuthenticationRequestSuccess = (_) => ({
	type: SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_SUCCESS,
});

const sendSocialMediaAuthenticationRequestFailure = (_) => ({
	type: SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_FAILED,
});

// eslint-disable-next-line no-unused-vars
const getMediaQueryDetailsRequest = (_) => ({
	type: GET_MEDIA_QUERY_DETAILS_REQUEST,
});

// eslint-disable-next-line no-unused-vars
const getMediaQueryDetailsRequestSuccess = (_) => ({
	type: GET_MEDIA_QUERY_DETAILS_REQUEST_SUCCESS,
});

// eslint-disable-next-line no-unused-vars
const getMediaQueryDetailsRequestFailure = (_) => ({
	type: GET_MEDIA_QUERY_DETAILS_REQUEST_FAILURE,
});

const sendLiveAssetRequest = (_) => ({
	type: SEND_LIVE_ASSET_REQUEST,
});
const sendLiveAssetRequestSuccess = (_) => ({
	type: SEND_LIVE_ASSET_REQUEST_SUCCESS,
});
const sendLiveAssetRequestFailure = (_) => ({
	type: SEND_LIVE_ASSET_REQUEST_FAILURE,
});

export const refreshCatalogs = () => ({
	type: REFRESH_CATALOGS,
});

export const refreshMedias = () => ({
	type: REFRESH_MEDIAS,
});

export const refreshPlaylists = () => ({
	type: REFRESH_PLAYLISTS,
});

export const setRefreshMediasIds = (refreshMediasIds) => ({
	type: REFRESH_MEDIAS_IDS,
	refreshMediasIds,
});

export const updateSelectAllProps = (selectAllItemsStatus) => ({
	type: UPDATE_SELECT_ALL_PROPS,
	selectAllItemsStatus,
});

export const didFileUploadActionHappenedStatus = (filesUploaded) => ({
	type: DID_FILE_UPLOAD_ACTION_HAPPENED_STATUS,
	filesUploaded,
});

export const startMediaUploadProcess = (selectedFiles, uploadingMediaItems) => ({
	type: MEDIA_UPLOAD_STARTS,
	selectedFiles,
	uploadingMediaItems,
});

export const setShowType = (showType) => ({
	type: SET_SHOW_TYPE,
	showType,
});

export const setSortingType = (sortType) => ({
	type: SET_SORT_TYPE,
	sortType,
});

export const setMediaFilterChange = (change) => ({
	type: SET_SELECTED_MEDIA_FILTER,
	change,
});

export const setPlaylistsFilterChange = (change) => ({
	type: SET_SELECTED_PLAYLISTS_FILTER,
	change,
});

export const setScenariosFilterChange = (change) => ({
	type: SET_SELECTED_SCENARIOS_FILTER,
	change,
});

export const setSortingTypeText = (sortTypeText) => ({
	type: SET_SORT_TYPE_TEXT,
	sortTypeText,
});

export const removedItemInAnyAction = (removedItemInEveryAction) => ({
	type: REMOVED_ITEM_STORE_TO_PROPS,
	removedItemInEveryAction,
});

export const checkMediaUploadProcess = (selectedFiles) => ({
	type: MEDIA_UPLOAD_CHECK_STATUS,
	selectedFiles,
});

export const stopMediaUploadProcess = (_) => ({
	type: MEDIA_UPLOAD_STOPS,
});

export const displayCatalogs = (_) => ({
	type: DISPLAY_CATALOGS_MEDIA,
});

export const hideCatalogs = (_) => ({
	type: HIDE_CATALOGS_MEDIA,
});

export const PublishPlaylistsFormUpdate = ({ field, value }) => ({
	type: PUBLISH_FORM_UPDATE,
	field,
	value,
});

const sendLoadMediaCountsOnCatalogsSuccess = (data) => ({
	data,
	type: SEND_LOAD_MEDIA_TOTAL_COUNT_SUCCESS,
});

const sendLoadMediaCountsOnCatalogsFailure = (_) => ({
	type: SEND_LOAD_MEDIA_TOTAL_COUNT_FAILURE,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const setUploadPercentage = (fileName, uploadPercentage, uploadType) => ({
	type: SET_UPLOAD_PERCENTAGE,
	fileName,
	uploadPercentage,
	uploadType,
});

export const setIsAudioUploadFinished = (value) => ({
	type: SET_IS_AUDIO_UPLOAD_FINISHED,
	value,
});

export const removeFromUploadPercentage = (fileName) => ({
	type: REMOVE_FROM_UPLOAD_PERCENTAGE,
	fileName,
});

export const resetUploadPercentage = (_) => ({
	type: RESET_UPLOAD_PERCENTAGE,
});

export const getMediaQueryDetails = (accId, mediaId, mediaName, mediaType) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	if (mediaName === 'medias' && mediaType === mediaTypes.trashedMedias) {
		return getTrashedMediaById({ accountId: defaultAccountId, mediaId })
			.then((data) => {
				return removeMeta(data, '$type');
			})
			.catch((error) => {
				return error;
			});
	}

	return getMediaQueryDetailsOnIdAndName(defaultAccountId, mediaId, mediaName)
		.then((data) => {
			return removeMeta(data, '$type');
		})
		.catch((error) => {
			return error;
		});
};

export const loadAllMediaItemsCount = (accId, property, query) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	dispatch(sendCountMediaRequest());
	return loadMediaItemsFullCount(defaultAccountId, property, query)
		.then((data) => {
			dispatch(sendCountMediaRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCountMediaRequestFailure());
			return error;
		});
};

export const loadMediasByQuery = (accId, mediaName, query) => (dispatch, getState) => {
	const accountId = accId || getAccountId(getState);
	dispatch(sendCountMediaRequest());

	let loadMediaFunc = loadMediasByQueryService;
	if (mediaName === mediaNames.trashedMedias) {
		loadMediaFunc = loadTrashedMediasByQueryService;
	}
	return loadMediaFunc({ accountId, mediaName, query })
		.then((data) => {
			dispatch(sendCountMediaRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCountMediaRequestFailure());
			return error;
		});
};

export const loadMediasForPagination =
	(accId, mediaPerPage, offset, mediaName, sortType, query) => (dispatch, getState) => {
		const accountId = accId || getAccountId(getState);

		let loadMediaFunc = loadMediasPerNavigation;
		if (mediaName === mediaNames.trashedMedias) {
			loadMediaFunc = loadTrashedMediasPerNavigation;
		}

		dispatch(sendLoadMediaRequest());
		return loadMediaFunc({ accountId, mediaPerPage, offset, mediaName, sortType, query })
			.then((data) => {
				dispatch(sendLoadMediaRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendLoadUsersRequestFailure());
				return error;
			});
	};

export const retryAssetDelete = (accountId, assetId, count) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	deleteAssetPerAccount(defaultAccountId, assetId).then((r) => {
		// trying to delete once again
		if (r) {
			getAssetPerAccount(accountId, assetId).then((d) => {
				count = count + 1;
				if (d && d.id && d.id === assetId && count < MAX_DELETE_RETRY) {
					retryAssetDelete(defaultAccountId, assetId, count);
				} else if (d && count >= MAX_DELETE_RETRY) {
					dispatch(showNotification('Failed to delete asset with Id ' + assetId));
				}
			});
		}
	});
};

export const deleteAsset = (accountId, assetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	deleteAssetPerAccount(defaultAccountId, assetId).then((res) => {
		if (res) {
			getAssetPerAccount(accountId, assetId).then((data) => {
				if (data && data.id && data.id === assetId) {
					let retryCount = 0;
					dispatch(retryAssetDelete(accountId, assetId, retryCount));
				}
			});
		} else {
			dispatch(showNotification('Failed to delete asset with Id ' + assetId));
		}
	});
};

export const deleteSelectedMediaItem = (id, accountId, mediaName, assetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendMediaItemDeleteRequest());
	return deleteMediaItemPerAccount(id, defaultAccountId, mediaName)
		.then((data) => {
			dispatch(sendMediaItemDeleteRequestSuccess());
			if (data && assetId) {
				dispatch(deleteAsset(defaultAccountId, assetId));
			}
			return data;
		})
		.catch((error) => {
			dispatch(sendMediaItemDeleteRequestFailure());
			return error;
		});
};

export const moveMediaToBin =
	({ accountId, mediaId }) =>
	(_dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return _moveMediaToBin({ accountId: defaultAccountId, mediaId })
			.then((data) => data)
			.catch((error) => error);
	};

export const restoreMedia =
	({ accountId, mediaId }) =>
	(_dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return _restoreMedia({ accountId: defaultAccountId, mediaId })
			.then((data) => data)
			.catch((error) => error);
	};

export const loadEncodingProfiles = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendEncodingProfilesRequest());
	return loadProfiles(defaultAccountId)
		.then((data) => {
			dispatch(sendEncodingProfilesSuccess());
			if (data.length > 0) {
				data = _.filter(data, (item) => {
					return item.value && item.value.toLowerCase() !== 'multipleaudio';
				});
			}
			return data;
		})
		.catch((error) => {
			dispatch(sendEncodingProfilesFailure());
			return error;
		});
};

export const loadUploadingFlows = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadEncoderFlows(defaultAccountId)
		.then((data) => data)
		.catch((error) => error);
};

export const uploadMediaFlow = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendUploadMediaFlowRequest());
	return uploadMediaItemsFlow(defaultAccountId)
		.then((data) => {
			dispatch(sendUploadMediaFlowSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendUploadMediaFlowFailure());
			return error;
		});
};

export const uploadMediaBeginsOldWay = (accountId, profile, catalog, subCatalog, file) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendMediaUploadOldRequest());
	return uploadMediaItemsOld(defaultAccountId, profile, catalog, subCatalog, file)
		.then((data) => {
			dispatch(sendMediaUploadOldRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendMediaUploadOldRequestFailure());
			return error;
		});
};

export const uploadMediaBegins =
	(accountId, profile, catalog, subCatalog, file, emails, fileName, replaceMediaContent) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);

		dispatch(sendMediaUploadRequest());

		let workflowFunc = uploadMediaItemsFlow;
		if (profile === FLOWS.qbrickStandard) {
			workflowFunc = uploadMediaUsingQBrickEncode;
			if (replaceMediaContent) {
				workflowFunc = replaceMediaUsingQBrickEncode;
			}
		}

		if (file.type.includes('image')) {
			workflowFunc = uploadImageMedia;
		}

		const processUploadMedia = async () => {
			const workflowData = await workflowFunc(
				defaultAccountId,
				profile,
				file,
				catalog,
				emails,
				fileName,
				replaceMediaContent
			);
			if (!isWorkflowValid(workflowData)) {
				dispatch(removeFromPreparingForUpload(fileName));
				return UPLOAD_FAILED;
			}

			const resultData = await uploadMediaItems(
				workflowData?.upload?.url,
				file,
				catalog,
				workflowData?.upload?.headers
			);
			return resultData;
		};

		return processUploadMedia()
			.then((data) => {
				if (!data || data === UPLOAD_FAILED) {
					dispatch(sendMediaUploadRequestFailure());
					return data;
				}
				dispatch(sendMediaUploadRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendMediaUploadRequestFailure());
				showFailedUploadAlertMessage(dispatch, error.message);
				return error;
			});
	};

export const getEncoderJobs = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);

	return _getEncoderJobs(defaultAccountId)
		.then((data) => data)
		.catch((error) => error);
};

export const getEncoderJobsByStatus = (accountId, status) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);

	return _getEncoderJobsByStatus(defaultAccountId, status)
		.then((data) => data)
		.catch((error) => error);
};

export const getAllCurrentJobs = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const to = moment().add(1, 'days').format('YYYY-MM-DD');
	const from = moment().subtract(2, 'days').format('YYYY-MM-DD');

	return _getAllCurrentJobs(defaultAccountId, from, to)
		.then((data) => data)
		.catch((error) => error);
};

export const getAllFinishedJobs = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const to = moment().add(1, 'days').format('YYYY-MM-DD');
	const from = moment().subtract(2, 'days').format('YYYY-MM-DD');

	return _getAllFinishedJobs(defaultAccountId, from, to)
		.then((data) => data)
		.catch((error) => error);
};

export const loadMediaPlayLists = (accountId, dataPerPage, currentOffset, sortType) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendRequestLoadPlayLists());
	return loadPlayLists(defaultAccountId, dataPerPage, currentOffset, sortType)
		.then((data) => {
			dispatch(sendRequestLoadPlayListSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(sendRequestLoadPlayListsFailure());
			return error;
		});
};

export const createNewPlaylist = (accountId, assetsId, uuidForPlaylist, playlistTitle) => (dispatch, getState) => {
	const { newPlaylistTitle } = getState().publish;
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendRequestCreateNewPlaylist());
	return newAssets(defaultAccountId, assetsId)
		.then(() => {
			return newPlaylist(defaultAccountId, assetsId, playlistTitle ?? newPlaylistTitle, uuidForPlaylist).then(
				(_) => {
					dispatch(sendRequestCreateNewPlaylistSuccess());
					return uuidForPlaylist;
				}
			);
		})
		.catch((error) => {
			dispatch(sendRequestCreateNewPlaylistFailure());
			return error;
		});
};

export const createNewCatalog =
	(accountId, assetsId, uuidForCatalog, catalogName, parentId, restrictionUsers) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(sendCatalogRequest());
		return newAssets(defaultAccountId, assetsId)
			.then((data) => {
				return newCatalog(defaultAccountId, assetsId, catalogName, uuidForCatalog, parentId).then((_) => {
					if (Object.keys(restrictionUsers).length !== 0) {
						const { admins, users } = Object.values(restrictionUsers).reduce(
							(alls, curr) => {
								const { id, accessLevel } = curr;
								const { admins, users } = alls;
								if (accessLevel === ACCESS_LEVEL.FULL.type) {
									return { ...alls, admins: [...admins, id] };
								}

								if (accessLevel == ACCESS_LEVEL.LIMITED.type) {
									return { ...alls, users: [...users, id] };
								}

								return alls;
							},
							{
								admins: [],
								users: [],
							}
						);
						updateUserRestrictionToCatalog(defaultAccountId, uuidForCatalog, admins, users).then((_) => {
							dispatch(sendRequestCreateNewCatalogSuccess());
						});
					} else {
						dispatch(sendRequestCreateNewCatalogSuccess());
					}
					return data;
				});
			})
			.catch((error) => {
				dispatch(sendRequestCreateNewCatalogFailure());
				return error;
			});
	};

export const updateCatalogAccessLimit = (defaultAccountId, uuidForCatalog, restrictionUsers) => () => {
	const { admins, users } = Object.values(restrictionUsers).reduce(
		(alls, curr) => {
			const { id, accessLevel } = curr;
			const { admins, users } = alls;
			if (accessLevel === ACCESS_LEVEL.FULL.type) {
				return { ...alls, admins: [...admins, id] };
			}

			if (accessLevel == ACCESS_LEVEL.LIMITED.type) {
				return { ...alls, users: [...users, id] };
			}

			return alls;
		},
		{
			admins: [],
			users: [],
		}
	);
	return updateUserRestrictionToCatalog(defaultAccountId, uuidForCatalog, admins, users);
};

export const deleteSelectedPlaylistItem = (id, accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendPlaylistItemDeleteRequest());
	return deletePlaylistItemPerAccount(id, defaultAccountId)
		.then((data) => {
			dispatch(sendPlaylistItemDeleteRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendPlaylistItemDeleteRequestFailure());
			return error;
		});
};

export const startThumbImageUploadProcess = (accountId, selectedItems, assetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);

	dispatch(startThumbImageUploadProcessRequest());
	return thumbImageUploadPassFlow(defaultAccountId, selectedItems, assetId)
		.then((workflowData) => {
			if (!isWorkflowValid(workflowData)) {
				dispatch(startThumbImageUploadProcessFailure());
				return UPLOAD_FAILED;
			}

			return thumbImageUploadFlowStarted(workflowData.upload.url, selectedItems, workflowData.upload.headers)
				.then((data) => {
					if (!data) {
						dispatch(startThumbImageUploadProcessFailure());
						return UPLOAD_FAILED;
					}

					dispatch(startThumbImageUploadProcessSuccess());
					return data;
				})
				.catch((error) => {
					dispatch(startThumbImageUploadProcessFailure());
					showFailedUploadAlertMessage(dispatch, error.message);
					return error;
				});
		})
		.catch((error) => {
			dispatch(startThumbImageUploadProcessFailure());
			showFailedUploadAlertMessage(dispatch, error.message);
			return error;
		});
};

export const startAudioUploadProcess =
	(accountId, selectedItems, language, assetId, mediaId, resourceId, audioDescription) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);

		dispatch(startAudioUploadProcessRequest());
		return audioUploadPassFlow(defaultAccountId, assetId, language, resourceId, audioDescription)
			.then((workflowData) => {
				if (!isWorkflowValid(workflowData)) {
					dispatch(startAudioUploadProcessFailure());
					showFailedUploadAlertMessage(dispatch);
					return UPLOAD_FAILED;
				}

				return uploadAudioItems(workflowData.upload.url, selectedItems, workflowData.upload.headers);
			})
			.catch((error) => {
				dispatch(startAudioUploadProcessFailure());
				showFailedUploadAlertMessage(dispatch, error.message);
				return error;
			});
	};

export const startSubtitleUploadProcess =
	(accountId, selectedItems, language, assetId, mediaId, resourceId) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);

		dispatch(startSubtitleUploadProcessRequest());
		return subtitleUploadPassFlow(defaultAccountId, assetId, language, resourceId)
			.then((workflowData) => {
				if (!isWorkflowValid(workflowData)) {
					dispatch(startSubtitleUploadProcessFailure());
					showFailedUploadAlertMessage(dispatch, `${language}`);
					return UPLOAD_FAILED;
				}

				return subtitleUploadFlowStarted(workflowData.upload.url, selectedItems, workflowData.upload.headers)
					.then((data) => {
						if (!data) {
							dispatch(startSubtitleUploadProcessFailure());
							showFailedUploadAlertMessage(dispatch);
							return UPLOAD_FAILED;
						}
						dispatch(startSubtitleUploadProcessSuccess());
						return data;
					})
					.catch((error) => {
						dispatch(startSubtitleUploadProcessFailure());
						showFailedUploadAlertMessage(dispatch, `${language}, ${error.message}`);
						return error;
					});
			})
			.catch((error) => {
				dispatch(startSubtitleUploadProcessFailure());
				showFailedUploadAlertMessage(dispatch, `${language}, ${error.message}`);
				return error;
			});
	};

export const removeSubtitle = (accountId, assetId, resourceId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(removeSubtitleRequest());
	return deleteSubtitle(defaultAccountId, assetId, resourceId)
		.then((data) => {
			dispatch(removeSubtitleRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(removeSubtitleRequestFailure());
			return error;
		});
};

export const removeAudio = (accountId, assetId, resourceId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(removeAudioRequest());
	return deleteAudio(defaultAccountId, assetId, resourceId)
		.then((data) => {
			dispatch(removeAudioRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(removeAudioRequestFailure());
			return error;
		});
};

export const keepLoadResourcesAfterTheFlowStarted = (accountId, assetId, playlistResources) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	if (playlistResources) {
		return loadResourcesAfterTheFlowStarted(defaultAccountId, assetId).then((data) => data);
	} else {
		return loadResourcesAfterTheFlowStartedForMedias(defaultAccountId, assetId).then((data) => data);
	}
};

export const removeThumbnailImage = (accountId, assetId, resourceId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(removeThumbnailImageRequest());
	return deleteThumbnailImage(defaultAccountId, assetId, resourceId)
		.then((data) => {
			dispatch(removeThumbnailImageRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(removeThumbnailImageRequestFailure());
			return error;
		});
};

export const removeResourceAsThumbnail = (accountId, assetId, resourceId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(removeThumbnailImageRequest());
	return removeResourceAsThumbnailImage(defaultAccountId, assetId, resourceId)
		.then((data) => {
			dispatch(removeThumbnailImageRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(removeThumbnailImageRequestFailure());
			return error;
		});
};

export const setTheThumbnailImageAsDefaultAction = (accountId, mediaId, resourceId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(setThumbnailImageRequest());
	return setThumbnailImageAsDefaultThumbnails(defaultAccountId, mediaId, resourceId)
		.then((data) => {
			dispatch(setThumbnailImageRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(setThumbnailImageRequestFailure());
			return error;
		});
};

export const updateResourceOfMedia = (accountId, assetId, resource) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendUpdateResourceOfMediaRequest());
	return updateResourceOfMediaAsset(defaultAccountId, assetId, resource)
		.then((data) => {
			dispatch(sendUpdateResourceOfMediaSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendUpdateResourceOfMediaFailure());
			return error;
		});
};

export const updateMediaCover = (accountId, mediaId, trackId, coverId, cover) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendupdateMediaCoverRequest());
	return updateMediaCoverOfMedia(defaultAccountId, mediaId, trackId, coverId, cover)
		.then((data) => {
			dispatch(sendupdateMediaCoverRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendupdateMediaCoverRequestFailure());
			return error;
		});
};

export const loadAllThePlaylists = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadAllPlayLists(defaultAccountId).then((data) => data);
};

export const assignMediaItemToTheSelectedPlaylist = (accountId, playListId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(assignMediaItemToPlaylistRequest());
	return assignMediaItemToPlaylist(defaultAccountId, playListId, mediaId)
		.then((data) => {
			dispatch(assignMediaItemToPlaylistRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(assignMediaItemToPlaylistRequestFailure());
			return error;
		});
};

export const assignMediasToPlaylist = (accountId, playListId, mediaIds) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(assignMediaItemToPlaylistRequest());
	return _assignMediasToPlaylist(defaultAccountId, playListId, mediaIds)
		.then((data) => {
			dispatch(assignMediaItemToPlaylistRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(assignMediaItemToPlaylistRequestFailure());
			return error;
		});
};

export const loadPlaylistRelativeMedias = (accountId, playlistId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendRelatedMediasFetchRequest());
	return loadRealativeMediaForPlaylist(defaultAccountId, playlistId)
		.then((data) => {
			dispatch(sendRelatedMediasFetchRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendRelatedMediasFetchRequestFailure());
			return error;
		});
};

export const removeSelectedMediaItemFromThePlaylist = (accountId, playlistId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendRemoveSelectedMediaItemFromThePlaylistRequest());
	return removeMediaItemFromPlaylist(defaultAccountId, playlistId, mediaId)
		.then((data) => {
			dispatch(sendRemoveSelectedMediaItemFromThePlaylistRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendRemoveSelectedMediaItemFromThePlaylistRequestFailure());
			return error;
		});
};

export const assignMediaItemToTheSelectedCatalog =
	(accountId, catalogId, mediaId, isCatalog) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(assignMediaItemToCatalogRequest());
		return assignMediaItemToCatalog(defaultAccountId, catalogId, mediaId, isCatalog)
			.then((data) => {
				dispatch(assignMediaItemToCatalogRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(assignMediaItemToCatalogRequestFailure());
				return error;
			});
	};

export const deleteMediaItemFromCatalog = (accountId, catalogId, mediaId, isCatalog) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(deleteMediaFromCatalogRequest());
	return deleteMediaItemFromSpecificCatalog(defaultAccountId, catalogId, mediaId, isCatalog)
		.then((data) => {
			dispatch(deleteMediaFromCatalogRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(deleteMediaFromCatalogRequestFailure());
			return error;
		});
};

export const changeTheOrderOfSelectedMediaItemInPlaylist =
	(accountId, playlistId, resource) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequest());
		return changeTheOrderOfTheSelectedMediaItemInPlaylist(defaultAccountId, playlistId, resource)
			.then((data) => {
				dispatch(sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendChangeTheOrderOfTheSelectedMediaItemInPlaylistRequestFailure());
				return error;
			});
	};

export const saveInspectScreenDetails = (accountId, mediaId, mediaName, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return saveMediaDetails(defaultAccountId, mediaId, mediaName, body)
		.then((data) => data)
		.catch((error) => error);
};

export const createNewRestrictionRule = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return createNewAssignedRestrictionRule(defaultAccountId, body)
		.then((data) => data)
		.catch((error) => error);
};

export const updateExistingRestrictionRule = (accountId, restrictionId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return updateExistingAssignedRestrictionRule(defaultAccountId, restrictionId, body)
		.then((data) => data)
		.catch((error) => error);
};

export const applyRestrictionRuleOnMedia = (accountId, mediaName, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return applyAssignedRestrictionRuleOnMedia(defaultAccountId, mediaName, mediaId, body)
		.then((data) => data)
		.catch((error) => error);
};

export const deleteRestrictionRuleOnMedia = (accountId, mediaName, mediaId, restrictionId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return deleteAssignedRestrictionRuleOnMedia(defaultAccountId, mediaName, mediaId, restrictionId)
		.then((data) => data)
		.catch((error) => error);
};

export const deleteRestrictionRule = (accountId, restrictionId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return deleteRestrictionRuleId(defaultAccountId, restrictionId)
		.then((data) => data)
		.catch((error) => error);
};

export const getRestrictionRules = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return getAssignedRestrictionRules(defaultAccountId)
		.then((data) => {
			dispatch(setAccountRestrictions(data));
			return data;
		})
		.catch((error) => error);
};

export const deleteRendition =
	(accountId, assetId, resourceId, renditionGroup, renditionId) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return deleteAssignedRendition(defaultAccountId, assetId, resourceId, renditionGroup, renditionId)
			.then((data) => data)
			.catch((error) => error);
	};

export const loadPredefinedFilters = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadSearchPredefinedFilters(defaultAccountId)
		.then((data) => data)
		.catch((error) => error);
};

export const fetchPlayerEmbedConfiguration = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadPlayerEmbedConfiguration(defaultAccountId)
		.then((data) => data.configurations)
		.catch((error) => error);
};

export const loadPlayerConfigById = (accountId, id) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadPlayerConfigurationById(defaultAccountId, id)
		.then((data) => data)
		.catch((error) => error);
};

export const retrieveTags = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadTags(defaultAccountId)
		.then((data) => data)
		.catch((error) => error);
};

export const loadTwitterAuthentication = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const twitterAuthenicationBody = {
		name: 'Twitter',
		url: `//video.qbrick.com/api/v1/sharing/accounts/${defaultAccountId}/platforms/Twitter01/authorizations/twitter`,
		authorizationEndpoint: twitterAuthenticateUrl,
		scope: null,
		scopePrefix: null,
		scopeDelimiter: null,
		redirectUri: socialSharingRedirectUrl,
		requiredUrlParams: null,
		defaultUrlParams: null,
		oauthType: '1.0',
		popupOptions: {
			width: 495,
			height: 645,
		},
		clientId: twitterClientId,
	};
	dispatch(sendSocialMediaAuthenticationRequest());
	return twitterAuthenticate(defaultAccountId, twitterAuthenicationBody)
		.then((data) => {
			dispatch(sendSocialMediaAuthenticationRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendSocialMediaAuthenticationRequestFailure());
			return error;
		});
};

export const loadSocialMediaPaltformDetails = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return getSocialMedialAuthenticationDetails(defaultAccountId)
		.then((data) => data)
		.catch((error) => error);
};

export const disconnectSocialMediaAccount = (accountId, platform) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return removeSocialMediaAccount(defaultAccountId, platform)
		.then((data) => data)
		.catch((error) => error);
};

export const shareThePostOnSocialMedia = (accountId, mediaId, post) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(shareOnSocialMediaPlatformRequest());
	return shareOnSocialMediaPlatform(defaultAccountId, mediaId, post)
		.then((data) => {
			dispatch(shareOnSocialMediaPlatformRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(shareOnSocialMediaPlatformRequestFailure());
			return error;
		});
};

export const retrieveSharedPostsStatus = (accountId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return getSharedPostsStatus(defaultAccountId, mediaId).then((data) => data);
};

export const handleDeleteTheSharedPost = (accountId, mediaId, shareId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendPostSharedDeleteRequest());
	return deleteTheSharedPost(defaultAccountId, mediaId, shareId)
		.then((data) => {
			dispatch(sendPostSharedDeleteRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendPostSharedDeleteRequestFailure());
			return error;
		});
};

export const handleSocialMediaAuthorization =
	(accountId, code, platformId, platform, clientId) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(sendSocialMediaAuthenticationRequest());
		return authorizeTheSocialMediaPlatform(defaultAccountId, code, platformId, platform, clientId)
			.then((data) => {
				dispatch(sendSocialMediaAuthenticationRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendSocialMediaAuthenticationRequestFailure());
				return error;
			});
	};

export const handlePlaylistMediaLoadingAction = (accountId, playlistId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return loadPlaylistRelatedMedias(defaultAccountId, playlistId)
		.then((data) => data)
		.catch((error) => error);
};

export const getLiveAssetInfo = (accountId, assetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendLiveAssetRequest());
	return fetchLiveAssetInfo(defaultAccountId, assetId)
		.then((data) => {
			dispatch(sendLiveAssetRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendLiveAssetRequestFailure());
			return error;
		});
};

export const addNewMedia =
	(accountId, body, mediaName = mediaNames.medias) =>
	(dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(sendAddNewMediaRequest());
		return createNewMedia(defaultAccountId, body, mediaName)
			.then((data) => {
				dispatch(sendAddNewMediaRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendAddNewMediaRequestFailure());
				return error;
			});
	};

export const loadMediaCountsOnCatelogs = (accId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);

	return loadMediaCountsOnCatelogsService(defaultAccountId, 200)
		.then((data) => {
			let catelog_totals2 = [];
			let catelog_total_by_id = {};

			//////////create a tree structure from array///////////////////
			function unflatten(arr) {
				let tree = [],
					mappedArr = {},
					arrElem,
					mappedElem;

				// First map the nodes of the array to an object -> create a hash table.
				for (let i = 0, len = arr.length; i < len; i++) {
					arrElem = arr[i];
					mappedArr[arrElem.Id] = arrElem;
					mappedArr[arrElem.Id]['children'] = [];
				}

				for (let Id in mappedArr) {
					if (mappedArr.hasOwnProperty(Id)) {
						mappedElem = mappedArr[Id];

						// If the element is not at the root level, add it to its parent array of children.
						if (mappedElem.Parent) {
							mappedArr[mappedElem['Parent']]['children'].push(mappedElem);
						}
						// If the element is at the root level, add it to first level elements array.
						else {
							tree.push(mappedElem);
						}
					}
				}
				return tree;
			}

			const rs = data.reduceRight((acc, o) => {
				const total = acc.filter((n) => n.parentId === o.id).reduce((x, y) => x + y.total, 0);
				return acc.concat({ ...o, total: total + o.count });
			}, []);

			data = rs;
			data.map((x) => {
				let parent_id = x.parentId ? x.parentId : x.id;
				let media_count = x.medias && x.medias.count ? x.medias.count : 0;
				if (x.parentId) {
					catelog_totals2.push({
						Id: x.id,
						Parent: parent_id,
						count_x: media_count,
						totalValue: 0,
					});
				} else {
					catelog_totals2.push({
						Id: x.id,
						count_x: media_count,
						totalValue: 0,
					});
				}

				catelog_total_by_id[x.id] = x.medias.count;
			});

			let tree_highrachy = unflatten(catelog_totals2);
			function sumUp(object) {
				object.totalValue = 0;
				for (let child of object.children) {
					object.totalValue += sumUp(child);
				}
				return object.totalValue + object.count_x;
			}

			let flattenedCollection = [];
			function dataById(object) {
				for (let child of object.children) {
					flattenedCollection.push(dataById(child));
				}
				return object;
			}

			let catelog_totals_new = {};
			tree_highrachy.map(function (object) {
				flattenedCollection = [];
				catelog_totals_new[object.Id] = sumUp(object);
				dataById(object);
				flattenedCollection.map(function (subObject) {
					catelog_totals_new[subObject.Id] = subObject.count_x + subObject.totalValue;
				});
			});

			dispatch(sendLoadMediaCountsOnCatalogsSuccess(catelog_totals_new));
			return data;
		})
		.catch((error) => {
			dispatch(sendLoadMediaCountsOnCatalogsFailure());
			return error;
		});
};

const isWorkflowValid = (workflowData) => {
	return workflowData && workflowData.upload && !!workflowData.upload.url;
};

const showFailedUploadAlertMessage = (dispatch, message = '') => {
	dispatch(showAlert(''));
	dispatch(showAlert(`${UPLOAD_FAILED} ${message}`, messageTypes.error));
};

export const loadEncoderUploading =
	({ accountId, flowId, callbackUrl, mediaId, mediaTitle, mediaDescription }) =>
	(_, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return getEncoderUploadId({
			accountId: defaultAccountId,
			flowId,
			callbackUrl,
			mediaId,
			mediaTitle,
			mediaDescription,
		})
			.then((data) => data)
			.catch((error) => error);
	};

export const uploadMediaToEncoder =
	({ accountId, mediaUrl, uploadId }) =>
	(_, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return uploadMediaToEncoderService({
			accountId: defaultAccountId,
			mediaUrl,
			uploadId,
		})
			.then((data) => data)
			.catch((error) => error);
	};

export const setQbrickStandardFlowsInReducer = (flows) => ({ type: SET_QBRICK_STANDARD_FLOWS, payload: flows });

export const setReplaceMediaContent = (isReplace) => ({ type: SET_REPLACE_MEDIA_CONTENT, payload: isReplace });

export const getPortalQueryDetails = (accId, portalId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);

	return getPortalDetails(defaultAccountId, portalId)
		.then((data) => {
			return data;
		})
		.catch((error) => {
			return error;
		});
};

export const savePortalDetailsAction = (accId, portalId, data) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);

	return savePortalDetails(defaultAccountId, portalId, data)
		.then((data) => {
			return data;
		})
		.catch((error) => {
			return error;
		});
};

export const publishPortalDetailsAction = (accId, portalId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);

	return publishPortal(defaultAccountId, portalId)
		.then((data) => {
			return data;
		})
		.catch((error) => {
			return error;
		});
};
